import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataGrid,
  GridToolbarContainer,
  useGridApiRef,
} from "@mui/x-data-grid";
import { saveFormStructure } from "../../api/settings";
import AutocompleteBuilder from "../../Components/AutocompleteBuilder";

/**
 * FieldOptionsEditor component allows editing options for a single field.
 * It renders a DataGrid to modify option rows (each with "label" and "value"),
 * and provides buttons to add a new option and to save the edits.
 *
 * Props:
 * - sectionIndex: index of the section within the form structure.
 * - fieldIndex: index of the field within the section.fields array.
 * - initialOptions: array of option objects to edit.
 * - onSave: callback that receives the updated options array when saving.
 */

function FieldOptionsEditor({
  sectionIndex,
  fieldIndex,
  initialOptions,
  onSave,
  setSnackbar,
  snackbar,
}) {
  // Initialize local rows state from initialOptions and include a temporary "id" field.
  const [rows, setRows] = useState(() =>
    initialOptions.map((option, index) => ({ id: index, ...option }))
  );
  // A counter to generate unique ids for newly added rows.
  const [rowIdCounter, setRowIdCounter] = useState(rows.length);

  // Create a grid API reference for focusing cells.
  const apiRef = useGridApiRef();

  // If the parent passes new initialOptions (for example, after a save),
  // update our local state so that unsaved edits aren't lost.
  useEffect(() => {
    setRows(initialOptions.map((option, index) => ({ id: index, ...option })));
    setRowIdCounter(initialOptions.length);
  }, [initialOptions]);

  // In the new editing API, use processRowUpdate to apply edits.
  const processRowUpdate = (newRow) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  // Function to add a new empty row, and focus the "label" cell.
  const handleAddRow = () => {
    const newRow = { id: rowIdCounter, label: "", value: "" };
    setRows((prevRows) => [...prevRows, newRow]);
    setRowIdCounter((prevCount) => prevCount + 1);

    // Wait for the new row to render, then focus the "label" cell and scroll to the new row.
    setTimeout(() => {
      if (apiRef.current) {
        apiRef.current.setCellFocus(newRow.id, "label");
        // Scroll to the last row, which is the newly added row
        apiRef.current.scrollToIndexes({ rowIndex: rows.length });
      }
    }, 100); // Adjust the timeout duration if necessary
  };

  // Function to remove a row.
  const handleDeleteRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  // Define the columns for the DataGrid.
  const columns = [
    {
      field: "label",
      headerName: "Label",
      flex: 1,
      editable: true,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button
          variant="text"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => handleDeleteRow(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  // Save button handler—remove the temporary "id" and call the parent's onSave.
  // The onSave callback now returns a Promise so we can show a Snackbar based on the result.
  const handleSave = async () => {
    const updatedOptions = rows.map(({ id, ...rest }) => rest);
    try {
      await onSave(updatedOptions);
      setSnackbar({
        open: true,
        message: "Options saved successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to save options", error);
      setSnackbar({
        open: true,
        message: "Failed to save options",
        severity: "error",
      });
    }
  };

  // CustomToolbar to display the Add Option button in the header.
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "flex-start" }}>
        <Button
          variant="text"
          color="success"
          onClick={handleAddRow}
          startIcon={<AddIcon />}
        >
          Add Option
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ height: "30rem", width: "100%" }}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          experimentalFeatures={{ newEditingApi: true }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
        />
      </Box>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="success" onClick={handleSave}>
          Save Options
        </Button>
      </Box>
    </Box>
  );
}

/**
 * FormBuilder component renders an accordion list of editable field options.
 * For each field in the form structure that is either a "select", "autocomplete",
 * or (in the case of table fields) for specific columns within a table,
 * it shows an accordion with a FieldOptionsEditor.
 *
 * Props:
 * - formStructure: the current form structure array.
 * - setFormStructure: function to update the form structure.
 */
const FormBuilder = ({ formStructure, setFormStructure }) => {
  /**
   * updateFieldOptions creates a new form structure with the updated options
   * for a specific field and saves it via the API call.
   */
  // Snackbar state for feedback messages
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const updateFieldOptions = async (
    sectionIndex,
    fieldIndex,
    updatedOptions
  ) => {
    // Deep clone the structure so that we do not mutate existing state
    const newStructure = JSON.parse(JSON.stringify(formStructure));
    newStructure[sectionIndex].fields[fieldIndex].options = updatedOptions;
    try {
      // Save the updated form structure to the backend
      await saveFormStructure(newStructure);
      // Update the parent state if the save is successful
      setFormStructure(newStructure);
      return true;
    } catch (error) {
      console.error("Failed to save form structure", error);
      throw error;
    }
  };

  /**
   * updateColumnOptions creates a new form structure with the updated options
   * for a specific column in a table field and saves it to the backend.
   */
  const updateColumnOptions = async (
    sectionIndex,
    fieldIndex,
    columnIndex,
    updatedOptions
  ) => {
    const newStructure = JSON.parse(JSON.stringify(formStructure));
    newStructure[sectionIndex].fields[fieldIndex].columns[columnIndex].options =
      updatedOptions;
    try {
      await saveFormStructure(newStructure);
      setFormStructure(newStructure);
      return true;
    } catch (error) {
      console.error("Failed to save table column options", error);
      throw error;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box>
      {formStructure && formStructure.length > 0 ? (
        formStructure.map((section, sectionIndex) => (
          <Box key={sectionIndex} sx={{ mb: 2 }}>
            {section.fields &&
              section.fields.map((field, fieldIndex) => {
                if (field.type === "select" || field.type === "autocomplete") {
                  return (
                    <Accordion
                      elevation={0}
                      variant="outlined"
                      key={`${sectionIndex}-${fieldIndex}`}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{field.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FieldOptionsEditor
                          sectionIndex={sectionIndex}
                          fieldIndex={fieldIndex}
                          initialOptions={field.options || []}
                          onSave={(updatedOptions) =>
                            updateFieldOptions(
                              sectionIndex,
                              fieldIndex,
                              updatedOptions
                            )
                          }
                          setSnackbar={setSnackbar}
                          snackbar={snackbar}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                } else if (field.type === "table") {
                  return (
                    <Accordion
                      key={`${sectionIndex}-${fieldIndex}`}
                      elevation={0}
                      variant="outlined"
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          {field.name} (Table)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          Table field: Edit options for columns below.
                        </Typography>
                        {field.columns && field.columns.length > 0 ? (
                          field.columns.map((column, columnIndex) => {
                            if (
                              column.type === "select" ||
                              column.type === "autocomplete"
                            ) {
                              return (
                                <Accordion
                                  key={`${sectionIndex}-${fieldIndex}-${columnIndex}`}
                                  sx={{
                                    border: "1px solid",
                                    borderColor: "primary.light",
                                    ml: 2,
                                    mt: 1,
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                  >
                                    <Typography variant="subtitle1">
                                      {column.name}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <FieldOptionsEditor
                                      sectionIndex={sectionIndex}
                                      fieldIndex={fieldIndex}
                                      initialOptions={column.options || []}
                                      onSave={(updatedOptions) =>
                                        updateColumnOptions(
                                          sectionIndex,
                                          fieldIndex,
                                          columnIndex,
                                          updatedOptions
                                        )
                                      }
                                      setSnackbar={setSnackbar}
                                      snackbar={snackbar}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <Typography>
                            No columns with editable options.
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                } else if (field.type === "autocomplete-endpoint") {
                  return (
                    <AutocompleteBuilder
                      key={`${sectionIndex}-${fieldIndex}`}
                      field={field}
                      setSnackbar={setSnackbar}
                      snackbar={snackbar}
                    />
                  );
                }
                return null;
              })}
          </Box>
        ))
      ) : (
        <Typography>No form structure available</Typography>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FormBuilder;
