import React, { useState } from "react";
import Papa from "papaparse";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { updateFieldOptions, getFieldOptions } from "../api/settings";

// Create a visually hidden input using MUI's styled API.
const VisuallyHiddenInput = styled("input")({
  position: "absolute",
  width: "1px",
  height: "1px",
  margin: "-1px",
  padding: "0",
  overflow: "hidden",
  clip: "rect(0,0,0,0)",
  whiteSpace: "nowrap",
  border: "0",
});

const AutocompleteBuilder = ({ field, setSnackbar, snackbar }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validateCSV = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const headers = results.meta.fields;
          if (!headers || headers.length !== 2) {
            reject(new Error("CSV must have exactly 2 columns"));
            return;
          }

          const requiredColumns = ["label", "value"];
          const hasRequiredColumns = requiredColumns.every((col) =>
            headers.includes(col)
          );

          if (!hasRequiredColumns) {
            reject(new Error("CSV must have 'label' and 'value' columns"));
            return;
          }

          resolve(results.data);
        },
        error: (error) => {
          reject(new Error("Error parsing CSV file"));
        },
      });
    });
  };

  const downloadFieldOptions = async () => {
    setError(null);
    try {
      // Convert options to CSV format
      const options = (await getFieldOptions(field.id)) || [];
      const csvContent = [
        ["label", "value"], // Header row
        ...options.map((option) => [option.label, option.value]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      // Create a blob and download link
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      // Set the download attributes
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `${field.name.replace(" ", "_")}_options.csv`
      );
      link.style.visibility = "hidden";

      // Append to body, click, and cleanup
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSnackbar({
        open: true,
        message: "Field options downloaded successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error downloading field options:", error);
      setError("Failed to download field options");
      setSnackbar({
        open: true,
        message: "Failed to download field options",
        severity: "error",
      });
    }
  };

  const uploadFieldOptions = async (options) => {
    console.log("Uploading field options", options, field);
    try {
      const response = await updateFieldOptions(field.id, options);
      console.log("Field options updated", response);
    } catch (error) {
      console.error("Error updating field options:", error);
      setError("Failed to update field options");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    // Clear the file input so that the same file can be uploaded again
    event.target.value = null;

    if (!file) return;

    // Check file extension
    if (!file.name.endsWith(".csv")) {
      setError("Please upload a CSV file");
      setSnackbar({
        open: true,
        message: "Please upload a CSV file",
        severity: "error",
      });
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const validatedData = await validateCSV(file);
      await uploadFieldOptions(validatedData);
      setSnackbar({
        open: true,
        message: "Field options uploaded successfully",
        severity: "success",
      });
    } catch (err) {
      setError(err.message);
      setSnackbar({
        open: true,
        message: err.message,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Accordion elevation={0} variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{field.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper
          elevation={0}
          sx={{
            px: 2,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">
              You can download existing field options or upload a new CSV file
              to update the field options.
              <br />
              <strong>
                The CSV must have two columns: "label" and "value".
              </strong>
            </Typography>
          </Box>

          <Box
            sx={{
              gap: 1,
              mt: 1,
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={downloadFieldOptions}
              sx={{ mr: 1 }}
            >
              Download Current Options
            </Button>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              color="info"
              startIcon={<CloudUploadIcon />}
            >
              {isLoading ? "Validating..." : "Upload CSV"}
              <VisuallyHiddenInput
                type="file"
                accept=".csv"
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default AutocompleteBuilder;
