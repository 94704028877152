import React from "react";
import {
  Box,
  Typography,
  Grid,
  Pagination,
  Paper,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import FormDisplay from "../../../Components/Drawer/FormDisplay";

const IntakeForm = ({ forms }) => {
  const theme = useTheme();
  const [selectedVersion, setSelectedVersion] = useState(
    forms.length ? forms.length : 1
  );

  const handleVersionChange = (event, value) => {
    setSelectedVersion(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Paper elevation={0}>
        {forms.length > 0 ? (
          <>
            {forms.length > 1 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 1,
                  px: 2,
                  flexShrink: 0,
                }}
              >
                <Typography variant="subtitle1" sx={{ mr: 2 }}>
                  Version:
                </Typography>
                <Pagination
                  count={forms.length ? forms.length : 1}
                  page={selectedVersion}
                  onChange={handleVersionChange}
                  color="primary"
                  siblingCount={1}
                  boundaryCount={1}
                  size="small"
                />
              </Box>
            )}

            {/* <Divider orientation="horizontal" variant="middle" flexItem /> */}
            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                height: "100%",
                mt: 2,
              }}
            >
              <FormDisplay
                formConfig={forms[selectedVersion - 1].form}
                setFormConfig={() => {}}
                handleFormUpdate={() => {}}
                chatbotLoading={false}
                conversationOver={false}
                disabled={true}
                selectedVersion={selectedVersion}
                setIsModified={() => {}}
                inverseBackground={false}
                isSmall={true}
              />
            </Box>
          </>
        ) : (
          <Typography variant="body1" sx={{ my: 2 }}>
            No forms found!
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default IntakeForm;
