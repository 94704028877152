import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const notionColors = {
  default: {
    primary: "#D4D4D4",
    secondary: "#191919",
  },
  gray: {
    primary: "#9B9B9B",
    secondary: "#252525",
  },
  brown: {
    primary: "#A27763",
    secondary: "#2E2724",
  },
  orange: {
    primary: "#CB7B37",
    secondary: "#36291F",
  },
  yellow: {
    primary: "#C19138",
    secondary: "#372E20",
  },
  green: {
    primary: "#4F9768",
    secondary: "#242B26",
  },
  blue: {
    primary: "#447ACB",
    secondary: "#1F282D",
  },
  purple: {
    primary: "#865DBB",
    secondary: "#2A2430",
  },
  pink: {
    primary: "#BA4A78",
    secondary: "#2E2328",
  },
  red: {
    primary: "#BE524B",
    secondary: "#332523",
  },
};

const theme = createTheme({
  typography: {
    fontFamily: "Inter, Arial",
    h1: {
      fontFamily: "Inter, Arial",
      fontSize: "3.815rem", // 1.250^6 * 1rem
      lineHeight: 1.6,
    },
    h2: {
      fontFamily: "Inter, Arial",
      fontSize: "3.052rem", // 1.250^5 * 1rem
      lineHeight: 1.6,
    },
    h3: {
      fontFamily: "Inter, Arial",
      fontSize: "2.441rem", // 1.250^4 * 1rem
      lineHeight: 1.6,
    },
    h4: {
      fontFamily: "Inter, Arial",
      fontSize: "1.953rem", // 1.250^3 * 1rem
      lineHeight: 1.6,
    },
    h5: {
      fontFamily: "Inter, Arial",
      fontSize: "1.563rem", // 1.250^2 * 1rem
      lineHeight: 1.6,
    },
    h6: {
      fontFamily: "Inter, Arial",
      fontSize: "1.25rem", // 1.250^1 * 1rem
      lineHeight: 1.6,
    },
    body: {
      fontFamily: "Inter, Arial",
      fontSize: "1rem", // Adjusted base font size
      lineHeight: 1.6, // Increased line spacing
    },
    body2: {
      fontFamily: "Inter, Arial",
      fontSize: "1rem", // Adjusted base font size
      lineHeight: 1.6, // Increased line spacing
    },
    subtitle: {
      fontFamily: "Inter, Arial",
      fontSize: "0.6rem", // Adjusted base font size
      lineHeight: 1.6, // Increased line spacing
    },
  },
  palette: {
    text: {
      main: "#18181B",
      disabled: "#18181B80",
    },
    notion: notionColors,
    primary: {
      main: "#274754",
    },
    success: {
      main: "#2A9D90",
    },
    error: {
      main: "#E76E50",
    },
    warning: {
      main: "#E8C468",
    },
    info: {
      main: "#0097D5",
    },
    background: {
      default: "#FFFFFF",
      dark: "#F4F4F4",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Disable elevation
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          "& a": {
            color: "#ADD8E6", // Light blue color for links
          },
        },
      },
    },
  },
});

export default theme;
