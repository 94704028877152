const toCamelCase = (str) => {
  // return str
  //   .split("_")
  //   .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //   .join(" ");

  return str
    ? str.replace(/[_-]/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
    : "";
};

export { toCamelCase };

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import FindInPageRoundedIcon from "@mui/icons-material/FindInPageRounded";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import QuizIcon from "@mui/icons-material/Quiz";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import LibraryAddCheckRoundedIcon from "@mui/icons-material/LibraryAddCheckRounded";

import theme from "../theme";

export const translateIcon = (icon, size = 20) => {
  let iconComponent;
  switch (icon) {
    case "ShoppingCartIcon":
      iconComponent = (
        <ShoppingCartIcon
          color={icon?.color || "error"}
          sx={{ fontSize: size }}
        />
      );
      break;
    case "QuizIcon":
      iconComponent = (
        <QuizIcon color={icon?.color || "warning"} sx={{ fontSize: size }} />
      );
      break;
    case "StoreIcon":
      iconComponent = (
        <StoreIcon color={icon?.color || "success"} sx={{ fontSize: size }} />
      );
      break;
    case "PeopleRoundedIcon":
      iconComponent = (
        <PeopleRoundedIcon
          color={icon?.color || "success"}
          sx={{ fontSize: size }}
        />
      );
      break;
    case "FindInPageRoundedIcon":
      iconComponent = (
        <FindInPageRoundedIcon
          color={icon?.color || "warning"}
          sx={{ fontSize: size }}
        />
      );
      break;
    case "ContactPageRoundedIcon":
      iconComponent = (
        <ContactPageRoundedIcon
          color={icon?.color || "warning"}
          sx={{ fontSize: size }}
        />
      );
      break;
    case "AttachFileRoundedIcon":
      iconComponent = (
        <AttachFileRoundedIcon
          color={icon?.color || "info"}
          sx={{ fontSize: size }}
        />
      );
      break;
    case "ReceiptLongRoundedIcon":
      iconComponent = (
        <ReceiptLongRoundedIcon
          color={icon?.color || "error"}
          sx={{ fontSize: size }}
        />
      );
      break;
    case "MailOutlineRoundedIcon":
      iconComponent = (
        <MailOutlineRoundedIcon
          color={icon?.color || "info"}
          sx={{ fontSize: size }}
        />
      );
      break;
    case "BusinessCenterRoundedIcon":
      iconComponent = (
        <BusinessCenterRoundedIcon
          color={icon?.color || "error"}
          sx={{ fontSize: size }}
        />
      );
      break;
    case "LibraryAddCheckRoundedIcon":
      iconComponent = (
        <LibraryAddCheckRoundedIcon
          color={icon?.color || "success"}
          sx={{ fontSize: size }}
        />
      );
      break;
    default:
      iconComponent = null;
  }
  return iconComponent;
};

export const translateOptions = (options) => {
  return options.map((option) => {
    let iconComponent;
    iconComponent = translateIcon(option);
    return { ...option, icon: iconComponent };
  });
};

export const mapColor = (color) => {
  switch (color) {
    case 1:
      return theme.palette.primary.main;
    case 2:
      return theme.palette.secondary.main;
    case 3:
      return theme.palette.warning.main;
    case 4:
      return theme.palette.info.main;
    case 5:
      return theme.palette.success.main;
    case 6:
      return theme.palette.error.main;
    default:
      return theme.palette.primary.main;
  }
};
