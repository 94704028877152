import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  ListItemButton,
  Autocomplete,
  InputAdornment,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
  LinearProgress,
  Fade,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { getIcon } from "../../../../Components/Diagram/Utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse, isValid } from "date-fns";
import { translateIcon, toCamelCase } from "../../../../utils/formatting";
import CustomDatePicker from "../../../../Components/CustomDatePicker";
import DOMPurify from "dompurify";

function NodeDetails({
  nodeId,
  allUsers,
  getRequestDetails,
  sendMessage,
  request,
  handleStatusChange,
  handleDateChange,
  handleApproverChange,
  handleRoleChange,
  getRoleValue,
  handleStepNotesChange,
}) {
  let data = null;
  for (let group of request.steps) {
    for (let step of group) {
      if (step.id === nodeId) {
        data = step;
      }
    }
  }
  if (!data) {
    console.error("Node not found");
    return null;
  }

  const theme = useTheme();

  const [error, setError] = useState(null);
  const [approvers, setApprovers] = useState(
    data.approvers ? data.approvers.map((a) => a.email) : []
  );

  const [stepNotes, setStepNotes] = useState(data.notes || "");
  const [saveSuccess, setSaveSuccess] = useState(false);

  // State for status loading indicator
  const [statusLoading, setStatusLoading] = useState(false);

  // Update local state when data.approvers changes
  useEffect(() => {
    setApprovers(data.approvers ? data.approvers.map((a) => a.email) : []);
  }, [data.approvers]);

  useEffect(() => {
    setStepNotes(data.notes || "");
  }, [data.notes]);

  // NEW STATES FOR MODIFIER CONFIRMATION
  const [modifierConfirmOpen, setModifierConfirmOpen] = useState(false);
  const [pendingModifier, setPendingModifier] = useState(null);
  const [pendingModifierValue, setPendingModifierValue] = useState(null);
  const [modifierLoading, setModifierLoading] = useState(false);
  const [modifierSuccessMessage, setModifierSuccessMessage] = useState("");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.default",
      }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {data.name ?? "Untitled Step"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <ToggleButtonGroup
              value={data.status || ""}
              disabled={statusLoading}
              exclusive
              onChange={async (e, newStatus) => {
                if (!newStatus || newStatus === data.status) return;
                setStatusLoading(true);
                await handleStatusChange(nodeId, newStatus);
                setStatusLoading(false);
              }}
              size="small"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                pointerEvents: statusLoading ? "none" : "auto",
              }}
            >
              <ToggleButton
                sx={{ width: "20%", flexDirection: "column" }}
                value="new"
              >
                {getIcon("new", 20)}
                <Typography variant="subtitle">Not Started</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "20%", flexDirection: "column" }}
                value="in_progress"
              >
                {getIcon("in_progress", 20)}
                <Typography variant="subtitle">In Progress</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "20%", flexDirection: "column" }}
                value="complete"
              >
                {getIcon("complete", 20)}
                <Typography variant="subtitle">Complete</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "20%", flexDirection: "column" }}
                value="rejected"
              >
                {getIcon("rejected", 20)}
                <Typography variant="subtitle">Rejected</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "20%", flexDirection: "column" }}
                value="not_applicable"
              >
                {getIcon("not_applicable", 20)}
                <Typography variant="subtitle">N/A</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ width: "100%", height: 3, mt: 1 }}>
              <Fade in={statusLoading} timeout={{ enter: 500, exit: 500 }}>
                <LinearProgress sx={{ height: 2 }} />
              </Fade>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                width: "50%",
              }}
            >
              <TextField
                label={saveSuccess ? "Saved!" : "Details"}
                placeholder="Add details..."
                size="small"
                multiline
                fullWidth
                value={stepNotes}
                onChange={(e) => {
                  console.log(
                    `changing notes for ${nodeId} to ${e.target.value}`
                  );
                  setStepNotes(e.target.value);
                  setSaveSuccess(false);
                }}
                onBlur={async () => {
                  try {
                    const result = await handleStepNotesChange(
                      nodeId,
                      stepNotes
                    );
                    if (result) {
                      setSaveSuccess(true);
                      setTimeout(() => setSaveSuccess(false), 2000);
                    }
                  } catch (error) {}
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: saveSuccess ? "#e8f5e9" : "",
                    transition: "background-color 0.3s",
                  },
                  "& .MuiInputLabel-root": {
                    color: saveSuccess ? "#2e7d32" : "",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                width: "50%",
              }}
            >
              <CustomDatePicker
                value={data.date}
                label="Approved Date"
                size="small"
                slotProps={{ textField: { size: "small" } }}
                onChange={(newDateFormatted) => {
                  handleDateChange(nodeId, data.date, {
                    target: { value: newDateFormatted },
                  });
                }}
              />
            </Box>
          </Box>
          {allUsers && !data.role && (
            <Autocomplete
              multiple
              freeSolo={
                !data.approver_options || data.approver_options.length === 0
              }
              slotProps={{
                popper: { style: { width: "fit-content" } },
              }}
              options={
                !data.approver_options || data.approver_options.length === 0
                  ? []
                  : data.approver_options
                  ? allUsers.filter((user) =>
                      user.roles?.some((role) =>
                        data.approver_options.includes(role.name)
                      )
                    )
                  : allUsers
              }
              getOptionLabel={(option) => option.name || option.email}
              value={approvers.map((email) => {
                const user = allUsers.find((u) => u.email === email);
                return user ? user : { email, name: email };
              })}
              onChange={(event, newValue, reason) => {
                console.log("reason", reason);
                const newApproversEmails = newValue.map(
                  (user) => user.email || user
                );
                handleApproverChange(nodeId, newApproversEmails);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Approvers"
                  size="small"
                  placeholder={
                    data.approvers?.length > 0 ? " " : " Not assigned to anyone"
                  }
                  sx={{ width: "100%" }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option.email}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.name} ({option.email})
                </MenuItem>
              )}
              disableCloseOnSelect
              sx={{
                borderRadius: "4px",
                width: "100%",
              }}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        {data.tasks && (
          <Paper
            elevation={0}
            onWheelCapture={(evt) => {
              evt.stopPropagation();
              evt.nativeEvent.stopImmediatePropagation();
            }}
            sx={{
              flex: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", ml: 1, mb: 1 }}
            >
              Tasks
            </Typography>
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                width: "100%",
              }}
            >
              <List
                dense
                sx={{
                  width: "100%",
                  height: "100%",
                  py: 0,
                }}
              >
                {data.tasks.map((task, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      task.action && (
                        <Tooltip title={task.action.prompt} placement="bottom">
                          <Button
                            onClick={() => {
                              console.log(
                                "task.action.prompt",
                                task.action.prompt
                              );
                              sendMessage(task.action.prompt, []);
                            }}
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{
                              width: "fit-content",
                            }}
                          >
                            {task.action.icon ? (
                              translateIcon(task.action.icon, 20)
                            ) : (
                              <AutoFixHighOutlinedIcon sx={{ width: 20 }} />
                            )}
                            <Typography variant="caption" sx={{ ml: 1 }}>
                              {task.action.title ?? "AI Help"}
                            </Typography>
                          </Button>
                        </Tooltip>
                      )
                    }
                    disablePadding
                    sx={{
                      my: 0,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        py: 1,

                        px: 2,
                      }}
                    >
                      <ListItemText
                        primary={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(`• ${task.name}`, {
                                ADD_ATTR: ["target"],
                              }),
                            }}
                          />
                        }
                      />
                      <Box
                        sx={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      ></Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Paper>
        )}
      </Box>

      {data.modifiers?.map((modifier) => (
        <Box sx={{ display: "flex", gap: 2, mt: 4, mb: 1 }} key={modifier}>
          <FormControl
            sx={{
              borderRadius: "4px",
              width: "100%",
              maxWidth: "100%",
            }}
            fullWidth
            size="small"
          >
            <InputLabel id={`modifier-select-label-${modifier}`}>
              {toCamelCase(modifier)}
            </InputLabel>
            <Select
              labelId={`modifier-select-label-${modifier}`}
              id={`modifier-select-${modifier}`}
              value={
                request?.roles[modifier] && request.roles[modifier].length > 0
                  ? request.roles[modifier][0].email
                  : ""
              }
              label={toCamelCase(modifier)}
              onChange={(event) => {
                const selectedEmail = event.target.value;
                // Instead of calling handleRoleChange immediately,
                // we save the pending modifier change and open a confirmation
                setPendingModifier(modifier);
                setPendingModifierValue(selectedEmail ? [selectedEmail] : []);
                setModifierConfirmOpen(true);
              }}
              renderValue={(selected) => {
                const user = allUsers.find((u) => u.email === selected);
                return user ? `${user.name}` : "Not assigned";
              }}
            >
              <MenuItem value="">
                <em>Not assigned to anyone</em>
              </MenuItem>
              {[...allUsers]
                .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
                .map((user) => (
                  <MenuItem
                    key={user.email || user.name}
                    value={user.email || user.name}
                  >
                    {user.name || "Unknown"} ({user.email || "No Email"})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      ))}

      {/* Confirmation Dialog for Modifier Change */}
      <Dialog
        maxWidth="lg"
        open={modifierConfirmOpen}
        onClose={() => !modifierLoading && setModifierConfirmOpen(false)}
      >
        <DialogTitle>Confirm Modifier Change</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to change {toCamelCase(pendingModifier)} to{" "}
            <strong>
              {pendingModifierValue && pendingModifierValue.length > 0
                ? pendingModifierValue[0]
                : "Not assigned"}
            </strong>
            ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModifierConfirmOpen(false)}
            disabled={modifierLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setModifierLoading(true);
              try {
                const res = await handleRoleChange(
                  pendingModifier,
                  pendingModifierValue
                );
                if (res.success) {
                  setModifierSuccessMessage("Modifier updated successfully!");
                }
              } catch (error) {}
              setModifierLoading(false);
              setModifierConfirmOpen(false);
              setPendingModifier(null);
              setPendingModifierValue(null);
            }}
            color="primary"
            disabled={modifierLoading}
          >
            {modifierLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Yes, change it"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default NodeDetails;
