import React from "react";
import {
  Box,
  Fade,
  Typography,
  CircularProgress,
  Button,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { marked } from "marked";
import FileAttachment from "./Files/FileAttachment";
import Artifact from "./Artifacts/Artifact";
import DocumentScannerRoundedIcon from "@mui/icons-material/DocumentScannerRounded";
import ScatterPlotRoundedIcon from "@mui/icons-material/ScatterPlotRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import HdrStrongRoundedIcon from "@mui/icons-material/HdrStrongRounded";
import WidgetsRoundedIcon from "@mui/icons-material/WidgetsRounded";
import WorkspacesRoundedIcon from "@mui/icons-material/WorkspacesRounded";
import ShortTextRoundedIcon from "@mui/icons-material/ShortTextRounded";
import NotesRoundedIcon from "@mui/icons-material/NotesRounded";
import EjectRoundedIcon from "@mui/icons-material/EjectRounded";

const MessageList = ({
  user,
  messages,
  toolRunningMessages,
  theme,
  messagesEndRef,
  chatbotLoading,
  sendMessage,
  submittedSuccessfully,
  options,
  setOptions,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        overflow: "hidden",
        mb: 0,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        {messages.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
              overflow: "hidden",
            }}
          ></Box>
        ) : (
          messages.map(
            (msg, index) =>
              msg.flag !== "hidden" && (
                <Fade in={true} timeout={300} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        msg.role === "assistant"
                          ? "flex-start"
                          : msg.role === "user"
                          ? "flex-end"
                          : "center",
                    }}
                  >
                    {(msg.role === "assistant" || msg.role === "user") &&
                      msg.content &&
                      msg.content.length > 0 &&
                      !msg.content
                        .toLowerCase()
                        .startsWith("calling tools") && (
                        <Paper
                          elevation={0}
                          sx={{
                            backgroundColor:
                              msg.role === "assistant"
                                ? theme.palette.primary.main
                                : theme.palette.background.dark,
                            color:
                              msg.role === "assistant"
                                ? theme.palette.background.default
                                : theme.palette.text.primary,
                            py: 0,
                            px: 2,
                            my: 1,
                            borderRadius: "12px",
                            maxWidth: "95%",
                          }}
                        >
                          <Typography variant="body">
                            <div
                              className="markdown-content"
                              dangerouslySetInnerHTML={{
                                __html: marked(
                                  msg.content
                                    .replace(
                                      /Calling tools:\s*\[[^\]]*\]/gi,
                                      ""
                                    )
                                    .trim()
                                ),
                              }}
                            />
                          </Typography>
                        </Paper>
                      )}
                    {msg.tool_calls &&
                      Array.isArray(msg.tool_calls) &&
                      msg.tool_calls.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            color: theme.palette.text.disabled,
                            my: 0,
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          {(() => {
                            // Aggregate tool calls by name
                            const aggregatedTools = msg.tool_calls.reduce(
                              (acc, tool) => {
                                if (tool?.name in toolRunningMessages) {
                                  const message =
                                    toolRunningMessages[tool.name];
                                  if (message) {
                                    acc[message] = (acc[message] || 0) + 1;
                                  }
                                }
                                return acc;
                              },
                              {}
                            );

                            // Render aggregated messages vertically
                            return Object.entries(aggregatedTools).map(
                              ([message, count], idx) => (
                                <Box
                                  key={idx}
                                  sx={{
                                    textAlign: "center",
                                    my: 1,
                                    width: "100%", // Ensure full width for vertical stacking
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    width="100%"
                                    gap={1}
                                  >
                                    {message.startsWith("Generating") && (
                                      <WidgetsRoundedIcon
                                        sx={{ fontSize: 16 }}
                                      />
                                    )}
                                    {message.startsWith("Searching") && (
                                      <ScatterPlotRoundedIcon
                                        sx={{ fontSize: 16 }}
                                      />
                                    )}

                                    {message.startsWith("Retrieving") && (
                                      <ScatterPlotRoundedIcon
                                        sx={{ fontSize: 16 }}
                                      />
                                    )}

                                    {message.startsWith("Updating") && (
                                      <HdrStrongRoundedIcon
                                        sx={{ fontSize: 16 }}
                                      />
                                    )}

                                    {message.startsWith("Drafting") && (
                                      <NotesRoundedIcon sx={{ fontSize: 16 }} />
                                    )}

                                    <Typography variant="body2" fontWeight="">
                                      {message}
                                    </Typography>
                                    {count > 1 ? ` (x${count})` : ""}
                                  </Box>
                                </Box>
                              )
                            );
                          })()}
                        </Box>
                      )}
                    {msg.flag === "notification" ? (
                      <Box
                        sx={{
                          display: "flex",
                          color: theme.palette.text.disabled,
                          width: "100%",
                          justifyContent: "center",
                          my: 2,
                        }}
                      >
                        <Typography variant="body2">{msg.content}</Typography>
                      </Box>
                    ) : null}
                    {msg.role === "tool" &&
                      toolRunningMessages[msg.tool_name] && (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            my: 1,
                          }}
                        >
                          {msg.tool_name === "get_relevant_vendors" ? (
                            JSON.parse(msg.content.replace(/'/g, '"')).map(
                              (vendor, idx) => (
                                <Button
                                  key={idx}
                                  onClick={() => {
                                    if (!chatbotLoading) {
                                      const formattedVendor = vendor
                                        .replace(/_/g, " ")
                                        .replace(/\b\w/g, (c) =>
                                          c.toUpperCase()
                                        );
                                      sendMessage(
                                        `I prefer ${formattedVendor}`,
                                        []
                                      );
                                    }
                                  }}
                                  disabled={
                                    chatbotLoading || submittedSuccessfully
                                  }
                                  sx={{ p: 1, m: 1 }}
                                >
                                  <img
                                    src={`/vendors/${vendor}.svg`}
                                    alt={vendor}
                                    onError={(e) => {
                                      console.log(
                                        "Error loading vendor image: ",
                                        vendor
                                      );
                                      e.target.onerror = null;
                                      e.target.src = "/vendors/Default.svg";
                                    }}
                                    style={{ width: 96, height: 96 }}
                                  />
                                </Button>
                              )
                            )
                          ) : msg.tool_name === "get_document_docx" ? (
                            <Box sx={{ mt: 2, width: "100%" }}>
                              <Chip
                                icon={getFileIcon(msg.artifact.document_name)}
                                label={msg.artifact.document_name}
                                component="a"
                                href={`${chatDownloadFileUrl}/${msg.artifact.file_id}/${msg.artifact.document_name}`}
                                target="_blank"
                                clickable
                                color="warning"
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "40%",
                                  p: 1,
                                }}
                              />
                            </Box>
                          ) : null}
                        </Box>
                      )}
                    {msg.artifact && (
                      <Artifact
                        artifact={msg.artifact}
                        sendMessage={sendMessage}
                        chatbotLoading={chatbotLoading}
                        embedInModal={msg.artifact.type !== "email"}
                      />
                    )}
                    {msg.role === "user" &&
                    msg.files &&
                    msg.files.length > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          mt: 0,
                        }}
                      >
                        {msg.files.map((file, idx) => (
                          <FileAttachment
                            key={idx}
                            file={file}
                            deletable={false}
                            theme={theme}
                          />
                        ))}
                      </Box>
                    ) : null}
                  </Box>
                </Fade>
              )
          )
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
            height: 48,
          }}
        >
          {chatbotLoading && (
            <LinearProgress sx={{ width: "50%", height: 2 }} />
          )}
        </Box>
        <div ref={messagesEndRef} />
      </Box>
      {options && options.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mt: 2,
          }}
        >
          {options.map((option, index) => (
            <Button
              key={index}
              onClick={() => sendMessage(option.message, [])}
              disabled={chatbotLoading || submittedSuccessfully}
              variant="outlined"
              size="small"
              startIcon={option.icon}
              sx={{
                m: 0.5,
                textTransform: "none",
                minWidth: "auto",
                padding: theme.spacing(0.5, 1),
              }}
            >
              <Typography variant="body2">{option.description}</Typography>
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MessageList;
