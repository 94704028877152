import { useState, useRef, useEffect } from "react";

const useFormConfig = () => {
  const [formConfig, setFormConfig] = useState([]);
  const formEndRef = useRef(null);

  const [latestUpdateId, setLatestUpdateId] = useState(null);

  // const [initialFetched, setInitialFetched] = useState(false);

  const handleFormUpdate = async (newFormConfig) => {
    // Add sizes to fields and reorder them
    const updatedFormConfig = newFormConfig.map((section) => {
      const fieldsWithSizes = section.fields.map((field) => ({
        ...field,
        size: field.type === "textarea" ? 12 : field.type === "table" ? 12 : 6,
      }));

      // Sort the fields based on their type
      const reorderedFields = fieldsWithSizes.sort((a, b) => {
        if (a.type === "textarea" && b.type !== "textarea") return 1;
        if (a.type !== "textarea" && b.type === "textarea") return -1;
        return 0; // Preserve the current order
      });

      // Adjust size if the number of fields with size 6 is odd
      const nonTextareaFields = reorderedFields.filter(
        (field) => field.size === 6
      );
      if (nonTextareaFields.length % 2 !== 0) {
        if (nonTextareaFields.length > 0) {
          nonTextareaFields[0].size = 12;
        }
      }

      return {
        ...section,
        fields: reorderedFields,
      };
    });

    // Scroll if new sections are added
    if (updatedFormConfig.length > formConfig.length) {
      formEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    setFormConfig(updatedFormConfig);
  };

  return {
    formConfig,
    setFormConfig,
    handleFormUpdate,
    formEndRef,
    latestUpdateId,
    setLatestUpdateId,
  };
};

export default useFormConfig;
