import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import Badge from "@mui/material/Badge";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import InputRoundedIcon from "@mui/icons-material/InputRounded";
import { getUserNotifications, markNotificationAsRead } from "../api/settings";

import { useNavigate, useLocation } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "& .MuiListItemIcon-root": {
    color: theme.palette.primary.contrastText,
  },
  "& .MuiIconButton-root": {
    color: theme.palette.primary.contrastText,
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "& .MuiListItemIcon-root": {
    color: theme.palette.primary.contrastText,
  },
  "& .MuiIconButton-root": {
    color: theme.palette.primary.contrastText,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Notifications() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState(null);

  const openNotifications = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNotifications = () => {
    setAnchorEl(null);
  };

  const notificationsOpen = Boolean(anchorEl);

  const handleUnreadNotification = async (notificationId) => {
    const response = await markNotificationAsRead(notificationId);
    setNotifications((prevNotifications) => ({
      unread_count: response.unread_count,
      notifications: prevNotifications.notifications.filter(
        (notification) => notification.id !== notificationId
      ),
    }));
  };

  const getNotifications = async () => {
    const notifications = await getUserNotifications();
    setNotifications(notifications);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Box sx={{ mr: 6 }}>
      <Badge
        color="error"
        badgeContent={notifications?.unread_count}
        onClick={openNotifications}
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <IconButton>
          <NotificationsActiveRoundedIcon />
        </IconButton>
      </Badge>
      <Popover
        id="notifications-popover"
        open={notificationsOpen}
        anchorEl={anchorEl}
        onClose={closeNotifications}
        anchorOrigin={{
          vertical: "bottom",
        }}
      >
        <Box sx={{ p: 2, maxWidth: 400, maxHeight: "80vh", overflowY: "auto" }}>
          <List>
            {notifications?.notifications?.length > 0 ? (
              notifications.notifications.map((notification) => (
                <ListItem key={notification.id}>
                  <Alert
                    severity={notification?.severity || "error"}
                    onClose={() => handleUnreadNotification(notification.id)}
                  >
                    <div
                      style={{
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          notification.message?.length > 300
                            ? notification.message.substring(0, 300) + "..."
                            : notification.message,
                      }}
                    />
                  </Alert>
                </ListItem>
              ))
            ) : (
              <Typography sx={{ color: "text.disabled" }}>
                You have no notifications.
              </Typography>
            )}
          </List>
        </Box>
      </Popover>
    </Box>
  );
}

/**
 * MiniDrawer renders a permanent side drawer (without a top bar) that highlights the current tab.
 *
 * Props:
 * - user: user object (used to conditionally display the Settings item)
 * - handleLogout: function to call when the "Logout" item is clicked
 * - headerTitle: header title to display at the top of the main content area
 * - children: content to render to the right of the drawer
 */
export default function MiniDrawer({
  user,
  handleLogout,
  children,
  headerTitle,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [logoVisible, setLogoVisible] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (!open) {
      setTimeout(() => setLogoVisible(true), 200);
    } else {
      setLogoVisible(false);
    }
  };

  // Define drawer items with a "path" for route-based items.
  const drawerItems = [
    {
      text: "My Requests",
      icon: <HomeIcon />,
      path: "/dashboard/home",
      onClick: () => navigate("/dashboard/home"),
    },
    {
      text: "All Requests",
      icon: <DashboardIcon />,
      path: "/dashboard/all_requests",
      onClick: () => navigate("/dashboard/all_requests"),
    },
    {
      text: "Helpdesk",
      icon: <InputRoundedIcon />,
      onClick: () => window.open("/helpdesk", "_blank"),
    },
  ];

  if (user?.roles?.some((r) => r.name === "admin")) {
    drawerItems.push({
      text: "Settings",
      icon: <SettingsIcon />,
      path: "/dashboard/settings",
      onClick: () => navigate("/dashboard/settings"),
    });
  }

  // For logout, no "path" is necessary.
  drawerItems.push({
    text: "Logout",
    icon: <LogoutIcon />,
    onClick: handleLogout,
  });

  return (
    // Parent container takes full height and width.
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box>
            <DrawerHeader
              sx={{
                display: "flex",
                justifyContent: open ? "flex-end" : "center",
              }}
            >
              <IconButton
                onClick={handleDrawerToggle}
                sx={{ color: "inherit" }}
              >
                {open ? (
                  theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )
                ) : (
                  <MenuIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <List sx={{ pt: 0 }}>
              {drawerItems.map((item) => (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <Tooltip
                    title={item.text}
                    placement="right"
                    arrow
                    disableHoverListener={open}
                  >
                    <ListItemButton
                      selected={
                        item.path
                          ? location.pathname.startsWith(item.path)
                          : false
                      }
                      onClick={item.onClick}
                      sx={{
                        minHeight: 48,
                        px: 2.5,
                        justifyContent: open ? "initial" : "center",
                        // Highlight the selected item with a custom background and a left border.
                        "&.Mui-selected": {
                          backgroundColor: theme.palette.action.selected,
                          borderLeft: `4px solid ${theme.palette.primary.contrastText}`,
                          color: theme.palette.primary.contrastText,
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: theme.palette.action.selected,
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0, color: "inherit" }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Drawer>
      {/* Main content area with header title */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          minHeight: 0,
          backgroundColor: "background.dark",
        }}
      >
        {headerTitle && (
          <Box
            sx={{
              // py: 4,
              py: 2,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <Typography variant="h6">{headerTitle}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Notifications />
              {user?.organization_id && (
                <img
                  src={`/clients/${user?.organization_id}.png`}
                  alt={`${user?.organization_id}.png`}
                  onError={(e) => {
                    console.log(
                      "Error loading vendor image: ",
                      `/clients/${user?.organization_id}.png`
                    );
                  }}
                  style={{
                    height: 48,
                    width: "auto",
                  }}
                />
              )}
            </Box>
          </Box>
        )}
        <Paper
          elevation={0}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            minHeight: 0,
            backgroundColor: "background.dark",

            px: 2,

            py: 2,
          }}
        >
          {children}
        </Paper>
      </Box>
    </Box>
  );
}
