import React from "react";
import { Box } from "@mui/material";
import FileAttachment from "./FileAttachment";

const FileAttachments = ({ selectedFiles, setSelectedFiles }) => {
  const handleFileDelete = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <>
      {selectedFiles?.length > 0 && (
        <Box
          sx={{
            height: "4rem",
            overflowY: "none",
            pb: 1,
            px: 0,
            mx: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            gap: 1,
          }}
        >
          {selectedFiles?.map((file, index) => (
            <FileAttachment
              key={index}
              file={file}
              onDelete={() => handleFileDelete(index)}
              deletable={true}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default FileAttachments;
