import { axiosInstance } from "./general";

export const fetchRequestDetails = async (requestId) => {
  try {
    const response = await axiosInstance.post(
      `/requests/details`,
      {
        request_id: requestId,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching request details:", error);
    throw error;
  }
};

export const fetchContractDetails = async (requestId) => {
  try {
    const response = await axiosInstance.post(
      `/requests/contract-details`,
      {
        request_id: requestId,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching contract details:", error);
    throw error;
  }
};

// New: Fetch all requests with pagination, sorting, search, and status filter
export const fetchRequests = async (
  page,
  perPage,
  sortField = "submitted_at",
  sortOrder = "desc",
  searchQuery = "",
  statusFilter = "",
  submittedFrom = "",
  submittedTo = "",
  assignedOnly = false,
  assignedToFilter = [] // New parameter: expected to be an array of user objects
) => {
  try {
    const params = {
      page,
      per_page: perPage,
      sort_order: sortOrder,
      sort_field: sortField,
    };

    if (searchQuery) {
      params.search = searchQuery;
    }

    if (statusFilter) {
      params.status = statusFilter;
    }

    if (submittedFrom) {
      params.submitted_from = submittedFrom;
    }

    if (submittedTo) {
      params.submitted_to = submittedTo;
    }

    if (assignedOnly) {
      params.assigned_only = assignedOnly;
    }

    // New: if assignedToFilter is a non-empty array, convert to comma-separated IDs.
    if (assignedToFilter && assignedToFilter.length > 0) {
      params.assigned_to_filter = assignedToFilter.map((u) => u.id).join(",");
    }

    const response = await axiosInstance.get(`/requests`, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching requests:", error);
    throw error;
  }
};

// Optionally, fetch a single request
export const fetchRequestById = async (id) => {
  const response = await axiosInstance.get(`/requests/${id}`);
  return response.data;
};

// export const updateAgendaItemStatus = async (
//   requestId,
//   agendaItemId,
//   newStatus
// ) => {
//   const response = await axiosInstance.post(`/requests/update-agenda`, {
//     request_id: requestId,
//     agenda_item_id: agendaItemId,
//     new_status: newStatus,
//   });
//   return response.data;
// };

export const updateAgendaItem = async ({
  requestId,
  agendaItemId,
  newAssignedUsersEmails = null,
  newStatus = null,
  newDate = null,
  newTasks = null,
  newNotes = null,
}) => {
  const response = await axiosInstance.post(`/requests/update-agenda-item`, {
    request_id: requestId,
    agenda_item_id: agendaItemId,
    new_status: newStatus,
    new_assigned_users_emails: newAssignedUsersEmails,
    new_date: newDate,
    new_tasks: newTasks,
    new_notes: newNotes,
  });
  return response.data;
};

export const updateNotes = async ({ requestId, newNotes = null }) => {
  const response = await axiosInstance.post(`/requests/update-notes`, {
    request_id: requestId,
    new_notes: newNotes,
  });
  return response.data;
};

// export const getItemArtifacts = async (requestId, stepId) => {
//   const response = await axiosInstance.post(`/requests/get-item-artifacts`, {
//     request_id: requestId,
//     step_id: stepId,
//   });
//   return response.data;
// };

// export const updateAgendaItemDate = async (
//   requestId,
//   agendaItemId,
//   newDate
// ) => {
//   const response = await axiosInstance.post(`/requests/update-agenda`, {
//     request_id: requestId,
//     agenda_item_id: agendaItemId,
//     new_date: newDate,
//   });
//   return response.data;
// };

export const downloadRequestFile = async (requestId, filename) => {
  try {
    const response = await axiosInstance.get(
      `/requests/${requestId}/download/${filename}`,
      {
        withCredentials: true,
      }
    );
    return response.data.download_url;
  } catch (error) {
    console.error("Error fetching download URL:", error);
    throw error;
  }
};

export const deleteRequestFile = async (requestId, filename) => {
  const response = await axiosInstance.delete(
    `/requests/${requestId}/delete-file/${filename}`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

// New function for viewing files
export const viewRequestFile = async (requestId, filename) => {
  try {
    const response = await axiosInstance.get(
      `/requests/${requestId}/view/${filename}`,
      {
        withCredentials: true,
      }
    );
    return response.data.view_url;
  } catch (error) {
    console.error("Error fetching view URL:", error);
    throw error;
  }
};

export const sendComment = async (requestId, comment, mentions = []) => {
  const response = await axiosInstance.post(`/requests/send-comment`, {
    request_id: requestId,
    comment: comment,
    mentions: mentions,
  });
  return response.data;
};

export const sendFormRequest = async (endpointUrl, content, formConfig) => {
  try {
    let url = `${endpointUrl}`;
    const response = await axiosInstance.post(url, {
      content: content,
      form_config: formConfig,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending request:", error);
    throw error;
  }
};

export const uploadRequestFiles = async (requestId, files, type) => {
  const data = new FormData();
  data.append("request_id", requestId);
  data.append("type", type);
  files.forEach((file) => {
    data.append(file.name, file);
  });
  const response = await axiosInstance.post(
    `/requests/upload-request-files`,
    data
  );
  return response.data;
};

export const deleteRequest = async (requestId) => {
  const response = await axiosInstance.delete(
    `/requests/delete-request/${requestId}`,
    {
      withCredentials: true,
    }
  );
  return response;
};
