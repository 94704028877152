import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Typography,
  Link,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { deleteRequest } from "../../../api/request";
import { useNavigate } from "react-router-dom";

const Settings = ({ request }) => {
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleClickDelete = () => {
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmOpen(false);
    try {
      setDeleting(true);
      await deleteRequest(request.id);
      navigate("/dashboard/all_requests");
    } catch (error) {
      console.error("Error deleting request:", error);
    } finally {
      setDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          my: 2,
        }}
        elevation={0}
      >
        <Box sx={{ mt: 0 }}>
          <Button
            variant="contained"
            color="error"
            disabled={deleting}
            onClick={handleClickDelete}
          >
            {deleting ? "Deleting..." : "Delete request"}
          </Button>
        </Box>
      </Paper>

      <Dialog open={confirmOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this request? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} disabled={deleting}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Settings;
