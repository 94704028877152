import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Fade,
  Modal,
  Typography,
  IconButton,
  Drawer,
} from "@mui/material";
import TableArtifact from "./TableArtifact";
import FormArtifact from "./FormArtifact";

import BackupTableRoundedIcon from "@mui/icons-material/BackupTableRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import CloseIcon from "@mui/icons-material/Close";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import EmailArtifact from "./EmailArtifact";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxHeight: "95%",
  bgcolor: "background.default",
  boxShadow: 24,
  p: 2,
  overflow: "none",
};

const Artifact = ({
  artifact,
  sendMessage,
  chatbotLoading,
  embedInModal = false,
  embedInDrawer = false,
}) => {
  // Check if artifact is null, an empty list, or an empty dictionary
  if (
    !artifact ||
    (Array.isArray(artifact) && artifact.length === 0) ||
    (typeof artifact === "object" && Object.keys(artifact).length === 0) ||
    !artifact?.type
  ) {
    return null;
  }

  // Separate open states for Modal and Drawer
  const [modalOpen, setModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const artifactContent = useMemo(() => {
    if (artifact?.type === "table") {
      return (
        <TableArtifact
          artifact={artifact}
          sendMessage={sendMessage}
          chatbotLoading={chatbotLoading}
          setModalOpen={setModalOpen}
        />
      );
    }
    if (artifact?.type === "form") {
      return (
        <FormArtifact
          artifact={artifact}
          sendMessage={sendMessage}
          chatbotLoading={chatbotLoading}
          embedInModal={embedInModal}
        />
      );
    }
    if (artifact?.type === "email") {
      return <EmailArtifact artifact={artifact} />;
    }

    return null;
  }, [artifact, sendMessage, chatbotLoading, embedInModal]);

  const handleOpen = () => {
    if (embedInDrawer) {
      setDrawerOpen(true);
    } else if (embedInModal) {
      setModalOpen(true);
    }
  };

  const handleClose = () => {
    if (embedInDrawer) {
      setDrawerOpen(false);
    } else if (embedInModal) {
      setModalOpen(false);
    }
  };

  // If not embedding in a modal or drawer, just render the artifact content inline
  if (!embedInModal && !embedInDrawer) {
    return artifactContent;
  }

  return (
    <>
      {artifact?.button_text ? (
        <Button onClick={handleOpen} color="primary" variant="contained">
          <Typography>{artifact?.button_text}</Typography>
        </Button>
      ) : (
        <Button
          onClick={handleOpen}
          color="error"
          sx={{
            width: 128,
            height: 64,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            my: 1,
          }}
        >
          {artifact?.type === "table" ? (
            <BackupTableRoundedIcon />
          ) : artifact?.type === "form" ? (
            <ChecklistRoundedIcon />
          ) : artifact?.type === "email" ? (
            <EmailRoundedIcon />
          ) : null}
          <Typography variant="caption">
            {artifact?.type === "table"
              ? "View Table"
              : artifact?.type === "form"
              ? "View Form"
              : artifact?.type === "email"
              ? "View Email"
              : null}
          </Typography>
        </Button>
      )}

      {embedInModal && (
        <Modal open={modalOpen} onClose={handleClose} closeAfterTransition>
          <Box sx={modalStyle}>
            <IconButton
              onClick={handleClose}
              variant="contained"
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
            {artifactContent}
          </Box>
        </Modal>
      )}

      {embedInDrawer && (
        <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {artifactContent}
        </Drawer>
      )}
    </>
  );
};

export default Artifact;
