import React, { useState } from "react";
import { Box, Paper, Button, Typography, Link, Divider } from "@mui/material";
import Artifact from "../../../Components/Chatbot/Artifacts/Artifact";

const Contract = ({ request, contract }) => {
  console.log("0000000000 Contract Sections", contract.sections);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper
        // variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
        }}
        elevation={0}
      >
        {contract === null ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              minHeight: "20rem",
            }}
          >
            <Typography variant="h6" color="text.disabled" sx={{ mb: 4 }}>
              No contract is created for this request yet
            </Typography>

            <Artifact
              artifact={{
                ...contract,
                title: "Draft Contract",
              }}
              sendMessage={() => {}}
              chatbotLoading={false}
              embedInModal={true}
            />
          </Box>
        ) : (
          <Box
            sx={{
              py: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="h6" color="text.disabled">
                Contract #:{" "}
                <Link
                  href={contract.internal_link}
                  underline="hover"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {contract.display_id}
                </Link>
              </Typography>
            </Box>
            {/* <Divider sx={{ mb: 1 }} /> */}
            <Artifact
              artifact={contract}
              sendMessage={() => {}}
              chatbotLoading={false}
              embedInModal={false}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default Contract;
