import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  CircularProgress,
  LinearProgress,
  Dialog,
  Alert,
  AlertTitle,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import EditableTable from "./EditableTable";

import { getVisibleSections, formatCurrency } from "../../utils/formUtils";
import debounce from "lodash.debounce";
import { searchField } from "../../api/chat";

const InputField = ({
  field,
  sectionId,
  fieldId,
  handleChange,
  handleTableRowsChange,
  chatbotLoading,
  conversationOver,
  disabled,
  hasError,
  inverseBackground = false,
  isSmall = false,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchingEndpoint, setSearchingEndpoint] = useState(false);

  // Track the latest search term
  const latestSearchTermRef = useRef("");

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce(async (value) => {
        latestSearchTermRef.current = value;
        try {
          const results = await searchField(field.id, value);
          if (latestSearchTermRef.current === value) {
            setOptions([...new Set(results.map((item) => item))]);
          }
        } catch (error) {
          console.error("Debounced search error:", error);
          setOptions([]);
        }
        setSearchingEndpoint(false);
      }, 500),
    [field.id]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Handler for currency formatting
  const handleCurrencyChange = (e) => {
    let { value } = e.target;
    // Remove all non-digit characters except for the decimal point
    value = value.replace(/[^\d.]/g, "");

    // Update the parent with the unformatted value
    handleChange({ target: { value } }, sectionId, fieldId);

    // Optionally, you can format the displayed value here or let it be handled in the rendering
  };

  switch (field.type) {
    case "table":
      return (
        <EditableTable
          rows={field.value}
          columns={field.columns}
          onRowsChange={(updatedRows) =>
            handleTableRowsChange(sectionId, fieldId, updatedRows)
          }
          disabled={chatbotLoading || conversationOver || disabled}
        />
      );

    case "select":
      return (
        <FormControl
          fullWidth
          error={hasError}
          size={isSmall ? "small" : "medium"}
          variant="filled"
          sx={{
            backgroundColor: inverseBackground
              ? "background.dark"
              : "background.default",
          }}
        >
          <InputLabel>{field.name + (field.required ? "*" : "")}</InputLabel>
          <Select
            label={field.name + (field.required ? "*" : "")}
            name={String(fieldId)}
            value={field.value?.value || ""}
            onChange={(e) => {
              const selectedOption = field.options.find(
                (option) => option.value === e.target.value
              );
              handleChange(
                { target: { value: selectedOption } },
                sectionId,
                fieldId
              );
            }}
            displayEmpty
            disabled={chatbotLoading || conversationOver || disabled}
          >
            {field.options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Typography
            variant="caption"
            sx={{
              whiteSpace: "pre-line",
              "& a": {
                color: "info.main",
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            dangerouslySetInnerHTML={{ __html: field.helper_text }}
          />
        </FormControl>
      );

    case "textarea":
      return (
        <>
          <TextField
            variant="filled"
            fullWidth
            label={field.name + (field.required ? "*" : "")}
            name={String(fieldId)}
            type="text"
            value={field.value || ""}
            size={isSmall ? "small" : "medium"}
            onChange={(e) => handleChange(e, sectionId, fieldId)}
            multiline
            minRows={4}
            maxRows={20}
            InputLabelProps={{
              shrink: Boolean(field.value),
            }}
            disabled={chatbotLoading || conversationOver || disabled}
            error={hasError}
            helperText={hasError ? "" : ""}
            sx={{
              backgroundColor: inverseBackground
                ? "background.dark"
                : "background.default",
            }}
          />
          <Typography
            variant="caption"
            sx={{
              whiteSpace: "pre-line",
              "& a": {
                color: "info.main",
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            dangerouslySetInnerHTML={{ __html: field.helper_text }}
          />
        </>
      );

    case "text":
    case "number":
    case "date":
      return (
        <>
          <TextField
            variant="filled"
            fullWidth
            label={field.name + (field.required ? "*" : "")}
            name={String(fieldId)}
            type={field.format === "currency" ? "text" : field.type}
            size={isSmall ? "small" : "medium"}
            value={
              field.format === "currency"
                ? formatCurrency(field.value)
                : field.value || ""
            }
            onChange={
              field.format === "currency"
                ? handleCurrencyChange
                : (e) => handleChange(e, sectionId, fieldId)
            }
            slotProps={{
              inputLabel: {
                shrink:
                  field.type === "date" ||
                  field.type === "datetime-local" ||
                  (field.format == "currency" && field.value !== "") ||
                  Boolean(field.value),
              },
            }}
            disabled={chatbotLoading || conversationOver || disabled}
            error={hasError}
            helperText={hasError ? "" : ""}
            sx={{
              backgroundColor: inverseBackground
                ? "background.dark"
                : "background.default",
            }}
          />
          <Typography
            variant="caption"
            sx={{
              whiteSpace: "pre-line",
              "& a": {
                color: "info.main",
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            dangerouslySetInnerHTML={{ __html: field.helper_text }}
          />
        </>
      );

    case "autocomplete":
      return (
        <Autocomplete
          variant="filled"
          freeSolo={field?.free_solo === true}
          options={field.options}
          value={field.value}
          disabled={chatbotLoading || conversationOver || disabled}
          onInputChange={(event, newInputValue) => {
            if (field?.free_solo === true) {
              if (
                newInputValue !== field.value?.value &&
                newInputValue !== field.value?.label
              ) {
                handleChange(
                  {
                    target: {
                      value: newInputValue,
                    },
                  },
                  sectionId,
                  fieldId
                );
              }
            }
          }}
          onChange={(event, newValue) => {
            handleChange(
              {
                target: {
                  value: newValue,
                },
              },
              sectionId,
              fieldId
            );
          }}
          renderInput={(params) => (
            <TextField
              variant="filled"
              {...params}
              label={field.name + (field.required ? "*" : "")}
              error={hasError}
              disabled={chatbotLoading || conversationOver || disabled}
              size={isSmall ? "small" : "medium"}
              sx={{
                backgroundColor: inverseBackground
                  ? "background.dark"
                  : "background.default",
              }}
            />
          )}
        />
      );

    case "autocomplete-endpoint":
      return (
        <>
          <Autocomplete
            freeSolo={field?.free_solo === true}
            options={options}
            value={field.value}
            inputValue={inputValue}
            disabled={chatbotLoading || conversationOver || disabled}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              debouncedSearch(newInputValue);
              setSearchingEndpoint(true);
              if (field?.free_solo === true) {
                if (
                  newInputValue !== field.value?.value &&
                  newInputValue !== field.value?.label
                ) {
                  handleChange(
                    {
                      target: {
                        value: newInputValue,
                      },
                    },
                    sectionId,
                    fieldId
                  );
                }
              }
            }}
            filterOptions={(options) => options}
            onChange={(event, newValue) => {
              handleChange({ target: { value: newValue } }, sectionId, fieldId);
            }}
            noOptionsText={
              inputValue?.length >= 1
                ? searchingEndpoint
                  ? "Searching..."
                  : "No results"
                : "Start typing to search"
            }
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                label={field.name + (field.required ? "*" : "")}
                error={hasError}
                disabled={chatbotLoading || conversationOver || disabled}
                size={isSmall ? "small" : "medium"}
                sx={{
                  backgroundColor: inverseBackground
                    ? "background.dark"
                    : "background.default",
                }}
              />
            )}
          />
        </>
      );

    default:
      return null;
  }
};

const FormDisplay = ({
  formConfig,
  setFormConfig,
  handleFormUpdate,
  chatbotLoading,
  conversationOver,
  disabled,
  selectedVersion,
  setIsModified,
  inverseBackground,
  isSmall,
}) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (formConfig && formConfig.length > 0) {
      setFadeIn(true);
    }
  }, [formConfig]);

  const visibleSections = getVisibleSections(formConfig);

  const handleChange = (e, sectionId, fieldId) => {
    const { value } = e.target;
    const updatedFormConfig = [...formConfig];
    console.log("updatedFormConfig", updatedFormConfig);

    console.log("sectionId", sectionId);
    console.log("fieldId", fieldId);
    const section = updatedFormConfig.find((s) => s.id === sectionId);
    const field = section.fields.find((f) => f.id === fieldId);

    const sectionIndex = updatedFormConfig.findIndex((s) => s.id === sectionId);
    const fieldIndex = section.fields.findIndex((f) => f.id === fieldId);

    console.log("field", field);

    if (
      field.type === "table" &&
      field.rowIndex !== undefined &&
      field.columnIndex !== undefined
    ) {
      updatedFormConfig[sectionIndex].fields[fieldIndex].value[field.rowIndex][
        field.columnIndex
      ] = value;
    } else {
      updatedFormConfig[sectionIndex].fields[fieldIndex].value = value;
    }

    setFormConfig(updatedFormConfig);
    handleFormUpdate(updatedFormConfig);
    setIsModified(true);
    console.log("updatedFormConfig", updatedFormConfig);

    // Removed fadeIn toggling to prevent re-renders that cause focus loss
    // setFadeIn(false);
    // setTimeout(() => setFadeIn(true), 100);
  };

  const handleTableRowsChange = (sectionId, fieldId, updatedRows) => {
    const updatedFormConfig = [...formConfig];
    const sectionIndex = updatedFormConfig.findIndex((s) => s.id === sectionId);
    const fieldIndex = updatedFormConfig[sectionIndex].fields.findIndex(
      (f) => f.id === fieldId
    );
    updatedFormConfig[sectionIndex].fields[fieldIndex].value = updatedRows;
    setFormConfig(updatedFormConfig);
    handleFormUpdate(updatedFormConfig);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorFields([]);
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          // px: 2,
          py: 0,
          mb: 0,
          width: "100%",
        }}
      >
        <form>
          {visibleSections.map((section) => {
            // const visibleTitles = visibleSections.map((s) => s.title);
            const visibleSectionIds = visibleSections.map((s) => s.id);
            const isVisible = visibleSectionIds.includes(section.id);
            if (!isVisible) {
              return null;
            }

            return (
              <Box key={section.id}>
                {section.title ? (
                  <Typography variant="h6" gutterBottom sx={{ mb: 2, mt: 4 }}>
                    {section.title}
                  </Typography>
                ) : (
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 2 }}
                  ></Typography>
                )}
                <Grid container spacing={2}>
                  {section.fields.map((field, fieldIndex) => {
                    const hasError = false;

                    return (
                      <Fade in={fadeIn} timeout={0} key={fieldIndex}>
                        <Grid
                          item
                          xs={12}
                          sm={
                            field.type === "textarea" ||
                            field.type === "table" ||
                            (field.name && field.name.length > 50)
                              ? 12
                              : 6
                          }
                        >
                          {field.info ? (
                            <Tooltip
                              title={
                                <Typography
                                  variant="small"
                                  sx={{
                                    whiteSpace: "pre-line",
                                    "& a": {
                                      color: "primary.main",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    },
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: field.info,
                                  }}
                                />
                              }
                              arrow
                              placement="left-start"
                            >
                              <span>
                                <InputField
                                  field={field}
                                  sectionId={section.id}
                                  fieldId={field.id}
                                  handleChange={handleChange}
                                  handleTableRowsChange={handleTableRowsChange}
                                  chatbotLoading={chatbotLoading}
                                  conversationOver={conversationOver}
                                  disabled={disabled}
                                  hasError={hasError}
                                  inverseBackground={inverseBackground}
                                  isSmall={isSmall}
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            <>
                              <InputField
                                field={field}
                                sectionId={section.id}
                                fieldId={field.id}
                                handleChange={handleChange}
                                handleTableRowsChange={handleTableRowsChange}
                                chatbotLoading={chatbotLoading}
                                conversationOver={conversationOver}
                                disabled={disabled}
                                hasError={hasError}
                                inverseBackground={inverseBackground}
                                isSmall={isSmall}
                              />
                            </>
                          )}
                        </Grid>
                      </Fade>
                    );
                  })}
                </Grid>
              </Box>
            );
          })}
        </form>
      </Box>
    </>
  );
};

export default FormDisplay;
