import { useState, useEffect } from "react";
import {
  fetchFormStructure,
  fetchOrgApprovals,
  updateApproval,
  getApprovers,
  updateApprovers,
} from "../api/settings";
import { getAllRoles } from "../api/general";

const useSettings = () => {
  const [formStructure, setFormStructure] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [orgRoles, setOrgRoles] = useState([]);

  const fetchForm = async () => {
    const formStructure = await fetchFormStructure();
    setFormStructure(formStructure);
  };

  const fetchApprovals = async () => {
    const approvals = await fetchOrgApprovals();
    setApprovals(approvals);
  };

  const fetchOrgRoles = async () => {
    const orgRoles = await getAllRoles();
    setOrgRoles(orgRoles);
  };

  const saveApproval = async () => {
    // Remove empty step groups
    const cleanedApprovals = {
      ...approvals,
      steps: approvals.steps.filter((stepGroup) => stepGroup.length > 0),
    };
    const cleanedApprovers = approvers;
    await updateApproval(cleanedApprovals, cleanedApprovers);
  };

  const fetchApprovers = async () => {
    const approvers = await getApprovers();
    setApprovers(approvers);
  };

  const handleExportApproval = async () => {
    const jsonString = JSON.stringify(approvals, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "approvals.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleImportApproval = async (file) => {
    try {
      const text = await file.text();
      JSON.parse(text); // Validate JSON format
    } catch (error) {
      throw new Error("Invalid JSON file format");
    }
    const text = await file.text();
    const json = JSON.parse(text);
    console.log("json", json);
    setApprovals(json);
  };

  useEffect(() => {
    fetchForm();
    fetchApprovals();
    fetchOrgRoles();
    fetchApprovers();
  }, []);

  return {
    formStructure,
    setFormStructure,
    approvals,
    setApprovals,
    saveApproval,
    orgRoles,
    approvers,
    setApprovers,
    handleExportApproval,
    handleImportApproval,
  };
};

export default useSettings;
