import React from "react";
import { Box, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// The GroupPanel component renders a simple background panel.
// The data object contains:
//   - width: the width of the panel
//   - height: the height of the panel
//   - backgroundStyle: extra styles (background color, border, pointerEvents, etc.)
const GroupPanel = ({ data }) => {
  const theme = useTheme();
  const { width, height, backgroundStyle } = data;
  return (
    <Paper
      variant="outlined"
      sx={{
        width,
        height,
        borderRadius: theme.shape.borderRadius,
        ...backgroundStyle,
      }}
    />
  );
};

export default GroupPanel;
