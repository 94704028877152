import { useState, useEffect } from "react";
import { fetchRequests } from "../api/request";

const useRequestsListPage = (initialFilters = {}, assignedOnly, allUsers) => {
  console.log("assignedOnly", assignedOnly);
  console.log("allUsers", allUsers);
  // Requests and error state
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(
    initialFilters.currentPage || 1
  );
  const [perPage, setPerPage] = useState(initialFilters.perPage || 25);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRequests, setTotalRequests] = useState(0);

  // Sorting state
  const [sortField, setSortField] = useState(
    initialFilters.sortField || "submitted_at"
  );
  const [sortOrder, setSortOrder] = useState(
    initialFilters.sortOrder || "desc"
  );

  // Filtering state
  const [searchQuery, setSearchQuery] = useState(
    initialFilters.searchQuery || ""
  );
  const [statusFilter, setStatusFilter] = useState(
    initialFilters.statusFilter || ""
  );
  const [submittedFrom, setSubmittedFrom] = useState(
    initialFilters.submittedFrom || ""
  );
  const [submittedTo, setSubmittedTo] = useState(
    initialFilters.submittedTo || ""
  );

  const [assignedToFilter, setAssignedToFilter] = useState(
    initialFilters.assignedToFilter || []
  );

  // Add an initialization flag
  const [isInitialized, setIsInitialized] = useState(false);

  // Combined effect for URL sync and data fetching
  useEffect(() => {
    if (!isInitialized) {
      if (allUsers.length === 0) {
        return;
      }
      // Initial load from URL
      const params = new URLSearchParams(window.location.search);
      console.log("loading params from URL", params);

      // Set states from URL params with defaults
      setCurrentPage(params.has("page") ? parseInt(params.get("page")) : 1);
      setPerPage(params.has("perPage") ? parseInt(params.get("perPage")) : 25);
      setSortField(params.get("sortField") || "submitted_at");
      setSortOrder(params.get("sortOrder") || "desc");
      setSearchQuery(params.get("search") || "");
      setStatusFilter(params.get("status") || "");
      setSubmittedFrom(params.get("from") || "");
      setSubmittedTo(params.get("to") || "");

      // Handle assigned to filter
      const assignedToParam = params.get("assignedTo");
      if (assignedToParam) {
        const userIds = assignedToParam.split(",");
        const selectedUsers = allUsers.filter((user) =>
          userIds.includes(user.id.toString())
        );
        setAssignedToFilter(selectedUsers);
      }

      setIsInitialized(true);
      return; // Exit early on initialization
    }

    // Update URL with current filters
    const params = new URLSearchParams();
    if (currentPage > 1) params.set("page", currentPage);
    if (perPage !== 25) params.set("perPage", perPage);
    if (sortField) params.set("sortField", sortField);
    if (sortOrder) params.set("sortOrder", sortOrder);
    if (searchQuery) params.set("search", searchQuery);
    if (statusFilter) params.set("status", statusFilter);
    if (submittedFrom) params.set("from", submittedFrom);
    if (submittedTo) params.set("to", submittedTo);
    if (assignedToFilter.length > 0) {
      params.set("assignedTo", assignedToFilter.map((u) => u.id).join(","));
    }

    const newUrl = `${window.location.pathname}${
      params.toString() ? "?" + params.toString() : ""
    }`;
    window.history.replaceState({}, "", newUrl);

    // Fetch requests
    const getRequests = async () => {
      setLoading(true);
      try {
        const data = await fetchRequests(
          currentPage,
          perPage,
          sortField,
          sortOrder,
          searchQuery,
          statusFilter,
          submittedFrom,
          submittedTo,
          assignedOnly,
          assignedToFilter
        );
        setRequests(data.requests);
        setTotalPages(parseInt(data.pagination.pages, 10));
        setTotalRequests(parseInt(data.pagination.total, 10));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getRequests();
  }, [
    isInitialized,
    currentPage,
    perPage,
    sortField,
    sortOrder,
    searchQuery,
    statusFilter,
    submittedFrom,
    submittedTo,
    assignedToFilter,
    assignedOnly,
    allUsers,
  ]);

  // Pagination controls
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Sorting controller (for use with DataGrid sort model etc.)
  const changeSortModel = (sortModel) => {
    if (sortModel && sortModel.length > 0) {
      setSortField(sortModel[0].field);
      setSortOrder(sortModel[0].sort);
    } else {
      setSortField(null);
      setSortOrder(null);
    }
    setCurrentPage(1);
  };

  // Update search query (and reset page)
  const handleSearch = (query) => {
    console.log("handleSearch", query);
    setSearchQuery(query);
    setCurrentPage(1);
  };

  // Update date filters (and reset page)
  const handleDateFilter = (from, to) => {
    setSubmittedFrom(from);
    setSubmittedTo(to);
    setCurrentPage(1);
  };

  // Update assignedToFilter (and reset page)
  const handleAssignedToFilter = (selectedUsers) => {
    setAssignedToFilter(selectedUsers);
    setCurrentPage(1);
  };

  return {
    requests,
    loading,
    error,
    currentPage,
    perPage,
    totalPages,
    totalRequests,
    sortField,
    sortOrder,
    searchQuery,
    statusFilter,
    submittedFrom,
    submittedTo,
    assignedToFilter,
    setCurrentPage,
    setPerPage,
    setStatusFilter,
    changeSortModel,
    handleSearch,
    handleDateFilter,
    handleAssignedToFilter,
    goToNextPage,
    goToPrevPage,
  };
};

export default useRequestsListPage;
