import React, { useState } from "react";
import { Box, Paper, Button, Typography, Link, Divider } from "@mui/material";
import Artifact from "../../../Components/Chatbot/Artifacts/Artifact";

const PurchaseOrder = ({ request, purchaseOrder }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
        }}
        elevation={0}
        // variant="outlined"
      >
        {request.purchase_order_id === null ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              minHeight: "20rem",
            }}
          >
            <Typography variant="h6" color="text.disabled" sx={{ mt: 2 }}>
              No purchase order is created for this request yet
            </Typography>

            <Artifact
              artifact={{
                ...purchaseOrder,
                title: "Create Purchase Order",
              }}
              sendMessage={() => {}}
              chatbotLoading={false}
              embedInModal={false}
            />
          </Box>
        ) : (
          <Box
            sx={{
              py: 1,
            }}
          >
            <Typography
              variant="h6"
              color="text.disabled"
              sx={{ mb: 2, mx: 4 }}
            >
              Purchase Order #:{" "}
              <Link href={purchaseOrder.purchase_order_link} target="_blank">
                {purchaseOrder.display_id}
              </Link>
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Artifact
              artifact={{
                ...purchaseOrder,
                purchase_order_id: purchaseOrder.internal_id,
              }}
              sendMessage={() => {}}
              chatbotLoading={false}
              embedInModal={false}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default PurchaseOrder;
