import { useState } from "react";
import FormDisplay from "../../Drawer/FormDisplay";
import {
  Box,
  Button,
  Modal,
  Typography,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { sendFormRequest } from "../../../api/request";

const FormArtifact = ({
  artifact,
  sendMessage,
  chatbotLoading,
  embedInModal,
}) => {
  const [formConfig, setFormConfig] = useState(artifact.sections);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogColor, setDialogColor] = useState(null);
  const [loading, setLoading] = useState(false);

  // New state for confirmation dialog
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [proceedWithCreation, setProceedWithCreation] = useState(false);

  function setIsModified(isModified) {
    console.log(isModified);
  }

  function handleFormUpdate(formData) {
    console.log(formData);
  }

  function handleCloseDialog() {
    setOpen(false);
  }

  // Function to handle the actual contract creation
  const handleSendFormRequest = async (action) => {
    setLoading(true);
    try {
      setDialogTitle("Processing...");
      setDialogMessage(null);
      setDialogColor("info");
      const response = await sendFormRequest(
        action.endpoint_url,
        artifact,
        formConfig
      );
      console.log("RESPONSE: ", response);
      setDialogTitle("Success!");
      setDialogMessage({
        __html: response.message,
      });
      setDialogColor("success");
      // refresh page in 5 seconds
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (error) {
      console.error("Error sending request:", error);
      setDialogTitle("Error sending request");
      let errs = error.response?.data?.error || "An unexpected error occurred.";
      setDialogMessage({ __html: errs });
      setDialogColor("error");
    } finally {
      setLoading(false);
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  let buttons = null;
  if (true) {
    buttons = (
      <>
        {artifact.actions?.map((action) => (
          <Button
            key={action.name}
            variant="contained"
            color="primary"
            onClick={() => handleSendFormRequest(action)}
            disabled={loading}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ mr: 2 }} />
                Processing...
              </>
            ) : (
              action.name
            )}
          </Button>
        ))}
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        px: 4,
        pt: 2,
        pb: embedInModal ? 0 : 2,
        overflow: "auto",
        maxHeight: embedInModal ? "90vh" : "100%",
      }}
    >
      <FormDisplay
        formConfig={formConfig}
        sendMessage={sendMessage}
        chatbotLoading={chatbotLoading || loading}
        conversationOver={false}
        setFormConfig={setFormConfig}
        handleFormUpdate={handleFormUpdate}
        setIsModified={setIsModified}
        inverseBackground={false}
        isSmall={true}
        highlightBlankFields={true}
      />
      {buttons}

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        sx={{ p: 10, minWidth: 500 }}
      >
        <Alert severity={dialogColor} sx={{ p: 2 }}>
          <AlertTitle>{dialogTitle} </AlertTitle>
          <span dangerouslySetInnerHTML={dialogMessage} />
          {dialogTitle === "Success!" && (
            <span> Refreshing the page in 5 seconds ... </span>
          )}
        </Alert>
      </Dialog>
    </Box>
  );
};

export default FormArtifact;
