import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ResizableBox } from "react-resizable";
import Chatbot from "../../Components/Chatbot/Chatbot";
import RequestsListPage from "../Requests/RequestsListPage";
import useChat from "../../hooks/useChat";

const Home = ({ user, showContractInfo, showPurchaseOrderInfo, allUsers }) => {
  const navigate = useNavigate();

  const {
    messages,
    setMessages,
    activePolicy,
    setActivePolicy,
    conversationOver,
    setConversationOver,
    options,
    setOptions,
    resetChat,
    chatbotLoading,
    setChatbotLoading,
    chatbotFiles,
    setChatbotFiles,
    sendMessage,
    selectedFiles,
    setSelectedFiles,
    inputRef,
    canvas,
    setCanvas,
    updateCanvas,
  } = useChat({
    initParams: { agent_name: "dashboard_general" },
    handleNewMessages: () => {},
  });

  const initialChatbotWidth =
    parseFloat(localStorage.getItem("chatbotWidth-home")) > 100
      ? parseFloat(localStorage.getItem("chatbotWidth-home"))
      : window.innerWidth * 0.35;
  const [chatbotWidth, setChatbotWidth] = useState(initialChatbotWidth);
  const [isHandleVisible, setIsHandleVisible] = useState(false);

  return (
    <Box sx={{ display: "flex", height: "100%", overflow: "hidden" }}>
      <ResizableBox
        width={chatbotWidth}
        height={Infinity}
        axis="x"
        minConstraints={[300, Infinity]}
        maxConstraints={[window.innerWidth - 300, Infinity]}
        onResize={(e, data) => setChatbotWidth(data.size.width)}
        onResizeStop={(e, data) => {
          setChatbotWidth(data.size.width);
          localStorage.setItem("chatbotWidth-home", data.size.width);
        }}
        handle={
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: -18,
              width: "20px",
              height: "100%",
              cursor: "ew-resize",
              zIndex: 1,
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseEnter={() => setIsHandleVisible(true)}
            onMouseLeave={() => setIsHandleVisible(false)}
          >
            <Box
              sx={{
                width: isHandleVisible ? "2px" : "1px",
                height: "100%",
                borderRadius: "2px",
                transition: "background-color 0.3s",
                backgroundColor: isHandleVisible
                  ? "primary.main"
                  : "transparent",
              }}
            />
          </Box>
        }
      >
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            px: 4,
            pt: 2,
          }}
        >
          <Box sx={{ flex: 1, overflow: "auto" }}>
            <Chatbot
              user={user}
              messages={messages}
              setMessages={setMessages}
              activePolicy={activePolicy}
              setActivePolicy={setActivePolicy}
              conversationOver={conversationOver}
              setConversationOver={setConversationOver}
              options={options}
              setOptions={setOptions}
              chatbotLoading={chatbotLoading}
              setChatbotLoading={setChatbotLoading}
              chatbotFiles={chatbotFiles}
              setChatbotFiles={setChatbotFiles}
              sendMessage={sendMessage}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              inputRef={inputRef}
              resetChat={resetChat}
            />
          </Box>
        </Paper>
      </ResizableBox>

      <Box sx={{ flex: 1, overflow: "auto", pl: 2 }}>
        <RequestsListPage
          user={user}
          showContractInfo={showContractInfo}
          showPurchaseOrderInfo={showPurchaseOrderInfo}
          storageKey="homePageRequestsListGridState"
          searchAndFilterEnabled={true}
          assignedOnly={true}
          allUsers={allUsers}
          xw
        />
      </Box>
    </Box>
  );
};

export default Home;
