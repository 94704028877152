import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Button,
  Box,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { debounce } from "lodash";
import { searchField } from "../../../api/chat";

import React, { useState, useEffect, useRef, useMemo } from "react";

const ConditionModal = ({
  open,
  onClose,
  onSave,
  onDelete,
  condition,
  handleChangeCondition,
  allFields,
}) => {
  if (!condition) return null;

  const [currentField, setCurrentField] = useState(null);

  useEffect(() => {
    const field = allFields.find((field) => field.id === condition.field_id);
    setCurrentField(field);
    setOptions(field?.options || []);
  }, [condition.field_id, allFields]);

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchingEndpoint, setSearchingEndpoint] = useState(false);
  // Track the latest search term
  const latestSearchTermRef = useRef("");

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce(async (value) => {
        latestSearchTermRef.current = value;
        try {
          const results = await searchField(currentField.id, value);
          if (latestSearchTermRef.current === value) {
            setOptions([...new Set(results.map((item) => item.value))]);
          }
        } catch (error) {
          console.error("Debounced search error:", error);
          setOptions([]);
        }
        setSearchingEndpoint(false);
      }, 500),
    [currentField?.id]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {condition?.id
          ? `Edit Condition for ${currentField?.name}`.slice(0, 40)
          : "Add New Condition"}
      </DialogTitle>
      <DialogContent sx={{ width: "400px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 2 }}>
          <TextField
            label="Field"
            value={condition.field_id}
            onChange={(e) => handleChangeCondition("field_id", e.target.value)}
            fullWidth
            select
          >
            {allFields.map((field) => (
              <MenuItem key={field.id} value={field.id}>
                {field.name}
              </MenuItem>
            ))}
          </TextField>

          {currentField?.type === "autocomplete-endpoint" ? (
            <Autocomplete
              multiple
              id="autocomplete-condition"
              options={options}
              getOptionLabel={(option) => option}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                if (!event) return;
                setInputValue(newInputValue);
                debouncedSearch(newInputValue);
                setSearchingEndpoint(true);
              }}
              value={condition?.values || []}
              onChange={(event, newValue) => {
                console.log("newValue", newValue);
                handleChangeCondition("values", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={currentField.name}
                  placeholder={currentField.name}
                />
              )}
              filterOptions={(x) => x}
              noOptionsText={
                inputValue?.length >= 1
                  ? searchingEndpoint
                    ? "Searching..."
                    : "No results"
                  : "Start typing to search"
              }
            />
          ) : currentField?.type === "select" ? (
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={condition.values || []}
              onChange={(e) => handleChangeCondition("values", e.target.value)}
              input={<OutlinedInput label={currentField.name} />}
              renderValue={(selected) => selected.join(", ")}
            >
              {options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    checked={condition.values?.includes(option.label)}
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          ) : currentField?.type === "number" ? (
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                label="Min"
                value={condition?.min_value}
                onChange={(e) =>
                  handleChangeCondition("min_value", e.target.value)
                }
                fullWidth
              />
              <TextField
                label="Max"
                value={condition?.max_value}
                onChange={(e) =>
                  handleChangeCondition("max_value", e.target.value)
                }
                fullWidth
              />
            </Box>
          ) : currentField?.type === "text" ||
            currentField?.type === "textarea" ? (
            <TextField
              label={"Value"}
              value={condition?.value}
              onChange={(e) => handleChangeCondition("value", e.target.value)}
              multiline={currentField?.type === "textarea"}
              rows={currentField?.type === "textarea" ? 4 : undefined}
            />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", p: 3, pt: 0 }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button onClick={onDelete} variant="outlined" color="error">
            Delete
          </Button>
          <Button onClick={onSave} variant="contained">
            {condition?.id ? "Update Condition" : "Add Condition"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConditionModal;
