// src/Auth/Onboard.js
import React, { useEffect } from "react";
import { Container, Typography, Box, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const OrgNotRegistered = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="md"
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      sx={{
        height: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container
        component={motion.div}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.0 }}
        sx={{ width: "100%" }}
      >
        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Typography variant="h2" align="center" gutterBottom sx={{ mt: 5 }}>
            Your Organization is not Registered
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 1.5 }}
        >
          <Typography variant="h6" align="center" sx={{ mt: 5 }}>
            Contact us at{" "}
            <a href="mailto:contact@optimized.com?subject=Organization%20Registration&body=Hi%2C%0D%0A%0D%0AI%27m%20contacting%20to%20get%20more%20info%20on%20optimized.%0D%0A%0D%0AThanks%21">
              <strong>contact@optimized.com</strong>
            </a>{" "}
            or <strong>+1(650)943-3383</strong>
          </Typography>
        </motion.div>
      </Container>
    </Container>
  );
};
export default OrgNotRegistered;
