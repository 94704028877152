import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Box } from "@mui/material";
import { parse, format, isValid } from "date-fns";

/**
 * CustomDatePicker
 *
 * This component wraps the MUI DatePicker with a LocalizationProvider
 * and centralizes the logic for parsing and formatting dates into a consistent
 * format (default "yyyy-MM-dd"). It also performs a simple validation check
 * using date-fns's isValid().
 *
 * Props:
 * - value: a string with the current date value (formatted as "yyyy-MM-dd").
 * - onChange: a callback that is fired with the formatted date string when the user selects a date.
 * - label: (optional) label for the TextField.
 * - inputFormat: (optional) the format of the input and output dates. Defaults to "yyyy-MM-dd".
 * - autoFocus: (optional) boolean to auto-focus the input.
 * - ...otherProps: any additional props will be spread on the DatePicker.
 */
function CustomDatePicker({
  value,
  onChange,
  label = "",
  inputFormat = "yyyy-MM-dd",
  autoFocus = false,
  ...otherProps
}) {
  // Parse the string value to a Date object. If no value is provided, use null.
  const dateValue = value ? parse(value, inputFormat, new Date()) : null;

  const handleDateChange = (newDate) => {
    // If the date is valid, format it using the inputFormat; otherwise, return an empty string.
    const formattedDate =
      newDate && isValid(newDate) ? format(newDate, inputFormat) : "";
    if (onChange) {
      onChange(formattedDate);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={dateValue}
          onChange={handleDateChange}
          inputFormat={inputFormat}
          label={label}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} fullWidth autoFocus={autoFocus} />
          )}
          {...otherProps}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default CustomDatePicker;

export const isDate = (date) => {
  const parsedDate = parse(date, "yyyy-MM-dd", new Date());

  return isValid(parsedDate) && parsedDate.getFullYear() > 1900;
};
