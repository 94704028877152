import { axiosInstance, BACKEND_URL } from "./general";
import { v4 as uuidv4 } from "uuid";
import qs from "qs";

// Function to generate a new Conversation ID
const getNewConversationId = () => uuidv4();

// Initialize conversationId at runtime
let conversationId = getNewConversationId();

// Function to reset Conversation ID
export const resetConversationId = () => {
  conversationId = getNewConversationId();
  console.log("Conversation ID Reset:", conversationId);
};

// Call resetConversationId on module load to ensure it's fresh
resetConversationId();

export const getChat = async (initParams, resetChat = null) => {
  try {
    const formData = new FormData();

    if (resetChat) {
      formData.append("reset_chat", resetChat);
      resetConversationId();
    }
    formData.append("init_params", JSON.stringify(initParams));
    formData.append("conversation_id", conversationId);
    const response = await axiosInstance.post(`/chat/get-chat`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Conversation-ID": conversationId,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching initial message:", error);
    throw error;
  }
};

export const sendMessageToChatbot = async (formData) => {
  try {
    const response = await axiosInstance.post(`/chat/chat`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const streamChat = async (formData, onChunk) => {
  try {
    const response = await fetch(`${BACKEND_URL}/chat/stream`, {
      method: "POST",
      headers: {
        "Conversation-ID": conversationId,
      },
      body: formData,
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;

    // Buffer to accumulate partial JSON strings
    let buffer = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;

      // Decode current chunk into a string and add it to the buffer
      const chunkStr = decoder.decode(value, { stream: !done });
      buffer += chunkStr;

      // Split the buffer by newline to separate potential JSON objects
      const lines = buffer.split("\n");

      // The last element might be an incomplete JSON, so pop it off for the next iteration
      buffer = lines.pop();

      // Process each complete JSON line
      for (const line of lines) {
        if (line.trim()) {
          try {
            const data = JSON.parse(line);
            onChunk(data);
          } catch (err) {
            console.error("Error parsing streamed JSON:", err);
          }
        }
      }
    }

    // After the stream is done, check if there is any remaining data in the buffer
    if (buffer.trim()) {
      try {
        const data = JSON.parse(buffer);
        onChunk(data);
      } catch (err) {
        console.error("Error parsing last JSON chunk:", err);
      }
    }
  } catch (error) {
    console.error("Error streaming chat data:", error);
    throw error;
  }
};

export const getCanvas = async (requestId, stepId) => {
  const response = await axiosInstance.post(`/chat/get-canvas`, {
    request_id: requestId,
    step_id: stepId,
  });
  return response.data;
};

/**
 * Search fields based on field_id and search query.
 * @param {string} fieldId - The ID of the field to search.
 * @param {string} searchQuery - The search string input by the user.
 * @returns {Promise<Object>} - The search results from the backend.
 */
export const searchField = async (fieldId, searchQuery) => {
  try {
    const response = await axiosInstance.get(`/misc/search-field`, {
      params: {
        field_id: fieldId,
        search: searchQuery,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    });
    console.log("Response: ", response.data);
    return response.data.results;
  } catch (error) {
    console.error("Error searching field:", error);
    throw error;
  }
};

// Initialize conversationId at runtime
// let conversationId = getNewConversationId();

// Function to reset Conversation ID
// export const resetConversationId = () => {
//   conversationId = getNewConversationId();
//   console.log("Conversation ID Reset:", conversationId);
// };

// Call resetConversationId on module load to ensure it's fresh
// resetConversationId();

export const initializeChat = async () => {
  try {
    const response = await axiosInstance.get(`/chat/initialize-chat`, {
      headers: {
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching initial message:", error);
    throw error;
  }
};

export const updateFormConfig = async (data) => {
  try {
    const response = await axiosInstance.post(`/update-form`, data, {
      headers: {
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating form config:", error);
    throw error;
  }
};

// export const processFiles = async (formData) => {
//   try {
//     const response = await axiosInstance.post(`/process-files`, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         "Conversation-ID": conversationId,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error processing files:", error);
//     throw error;
//   }
// };

export const submitForm = async (formConfig, files) => {
  try {
    const formData = new FormData();
    formData.append("form", JSON.stringify(formConfig));

    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append(file.name, file);
      });
    }

    const response = await axiosInstance.post(
      `/requests/submit-form`,
      formData,
      {
        headers: {
          "Conversation-ID": conversationId,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};

export const updateRequest = async (requestId, formData) => {
  try {
    const response = await axiosInstance.post(
      `/requests/update-request`,
      {
        request_id: requestId,
        form: formData,
      },
      {
        headers: { "Conversation-ID": conversationId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating request:", error);
    throw error;
  }
};

export const cancelRequest = async (requestId) => {
  try {
    const response = await axiosInstance.post(
      `/requests/cancel-request`,
      {
        request_id: requestId,
      },
      {
        headers: { "Conversation-ID": conversationId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error cancelling request:", error);
    throw error;
  }
};
