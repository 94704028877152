import { Box, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import React from "react";
import { updateAgendaItem } from "../../api/request";
import DoneIcon from "@mui/icons-material/DoneRounded";
import InProgressIcon from "@mui/icons-material/HourglassEmptyRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import BypassIcon from "@mui/icons-material/AirlineStopsRounded";
import UnknownIcon from "@mui/icons-material/HelpRounded";
import RejectedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import { useTheme } from "@mui/material/styles";

export const getStepNodeData = (step, nodeWidth, nodeHeight) => {
  return {
    id: step.id,
    title: step.name,
    icon: step.status,
    status: step.status,
    approvers: step.approvers,
    date: step.date,
    tasks: step.tasks,
    display_name: step.display_name,
    display_color: step.display_color,
    display_approvers: step.display_approvers,
    role: step.role,
    modifiers: step.modifiers,
    content: (
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <PersonIcon />
          <Typography variant="body2">
            {step?.approvers?.length > 1
              ? `${step.approvers[0].name} (+${step.approvers.length - 1})`
              : step?.approvers?.[0]?.name || "None"}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <CalendarTodayIcon />
          <Typography variant="body2">
            {step.date
              ? new Intl.DateTimeFormat("en-US", {
                  timeZone: "UTC",
                  month: "short",
                  day: "numeric",
                  year:
                    new Date(step.date).getUTCFullYear() ===
                    new Date().getUTCFullYear()
                      ? undefined
                      : "numeric",
                }).format(new Date(step.date))
              : "N/A"}
          </Typography>
        </Box>
      </Box>
    ),
    nodeWidth,
    nodeHeight,
  };
};

export const getIcon = (icon, fontSize = 36) => {
  const theme = useTheme();
  switch (icon) {
    case "new":
      return (
        <FiberManualRecordIcon
          sx={{ color: theme.palette.info.main, fontSize: fontSize }}
        />
      );
    case "in_progress":
      return (
        <InProgressIcon
          sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
        />
      );
    case "complete":
      return (
        <DoneIcon
          sx={{ color: theme.palette.success.main, fontSize: fontSize }}
        />
      );
    case "rejected":
      return (
        <RejectedIcon
          sx={{ color: theme.palette.error.main, fontSize: fontSize }}
        />
      );
    // case "cancelled":
    //   return (
    //     <CancelIcon
    //       sx={{ color: theme.palette.error.main, fontSize: fontSize }}
    //     />
    //   );
    case "not_applicable":
      return (
        <BypassIcon
          sx={{ color: theme.palette.text.disabled, fontSize: fontSize }}
        />
      );
    case "unknown":
      return (
        <UnknownIcon
          sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
        />
      );
    default:
      return null;
  }
};
