import { useState, useEffect } from "react";
import { fetchUser, fetchAllUsers, pauseAccountAPI } from "../api/auth";
import { useNavigate } from "react-router-dom";

const dummyContacts = {
  atn: {
    "john.doe@example.com": "John Doe",
    "jane.smith@example.com": "Jane Smith",
    "alice.jones@example.com": "Alice Jones",
    "teshnizi@stanford.edu": "Ali Teshnizi",
  },
  nta: {
    "John Doe": "john.doe@example.com",
    "Jane Smith": "jane.smith@example.com",
    "Alice Jones": "alice.jones@example.com",
    "Ali Teshnizi": "teshnizi@stanford.edu",
  },
};

const useDashboard = () => {
  const [user, setUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch user information.
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetchUser();
        setUser(response);
      } catch (err) {
        setError(err);
      }
    };
    getUser();
  }, [navigate]);

  // Fetch all assignees.
  useEffect(() => {
    const getAssignees = async () => {
      try {
        const response = await fetchAllUsers();
        setAllUsers(response);
      } catch (err) {
        setError(err);
      }
    };
    getAssignees();
  }, [navigate]);

  useEffect(() => {
    if (!user) return;

    // Check if user doesn't have access
    const hasAccess = user.roles?.some((role) =>
      role.permissions?.includes("DASHBOARD_ACCESS")
    );
    if (!hasAccess) {
      navigate("/helpdesk");
    }
  }, [user, navigate]);

  return {
    user,
    allUsers,
    dummyContacts,
    error,
  };
};

export default useDashboard;
