import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Switch,
  FormControlLabel,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import "react-resizable/css/styles.css";

import { updateNotes, updateAgendaItem } from "../../../../api/request";
import { ListView } from "./ListView";
import { FlowView } from "./FlowView";

// OverviewHeader component renders the top section with the view toggle.
export const OverviewHeader = ({ isListView, handleListViewToggle }) => (
  <Paper
    elevation={0}
    sx={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "background.default",
      py: 1,
    }}
  >
    <Typography variant="h6" sx={{ fontWeight: "bold", ml: 0 }}>
      {isListView ? "Table View" : "Flow View"}
    </Typography>
    <FormControlLabel
      sx={{ mx: 0, ml: 1 }}
      control={
        <Tooltip title="Toggle View" placement="bottom">
          <Switch
            checked={isListView}
            onChange={handleListViewToggle}
            name="listViewSwitch"
            color="primary"
            size="small"
          />
        </Tooltip>
      }
    />
  </Paper>
);

const Overview = ({
  user,
  steps,
  request,
  allUsers,
  getRequestDetails,
  sendMessage,
  chatbotLoading,
  updateCanvas,
  canvas,
  handleNodeClick,
  selectedNode,
}) => {
  const theme = useTheme();

  const [notes, setNotes] = useState(request.notes || "");
  const [notesSavedSuccess, setNotesSavedSuccess] = useState(false);

  // Retrieve the saved list view state from localStorage or default to false.
  const initialIsListView =
    JSON.parse(localStorage.getItem(`isListView-${request.id}`)) || false;
  const [isListView, setIsListView] = useState(initialIsListView);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  // Mapping of step IDs to note text.
  const [stepsNotesMap, setStepsNotesMap] = useState({});

  useEffect(() => {
    const initialMap = {};
    request.steps.forEach((stepGroup) => {
      stepGroup
        .filter((step) => step.tags?.includes("approval"))
        .forEach((step) => {
          initialMap[step.id] = step.notes || "";
        });
    });
    setStepsNotesMap(initialMap);
  }, [request]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const handleListViewToggle = () => {
    const newIsListView = !isListView;
    setIsListView(newIsListView);
    localStorage.setItem(
      `isListView-${request.id}`,
      JSON.stringify(newIsListView)
    );
  };

  const handleNotesBlur = async () => {
    try {
      await updateNotes({
        requestId: request.id,
        newNotes: notes,
      });
      setNotesSavedSuccess(true);
      getRequestDetails();
      setTimeout(() => setNotesSavedSuccess(false), 2000);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.error || "An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Error updating notes:", error);
    }
  };

  const handleStatusChange = async (agendaItemId, newStatus) => {
    if (!newStatus) return;
    try {
      await updateAgendaItem({
        requestId: request.id,
        agendaItemId,
        newStatus,
      });
      getRequestDetails();
    } catch (error) {
      console.error("Error updating status:", error);
      setSnackbarMessage(error.response?.data?.error || "An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDateChange = async (agendaItemId, oldDate, event) => {
    let newDate = event.target.value;
    const year = new Date(newDate).getFullYear();
    if (year < 2010) {
      newDate = new Date(newDate).setFullYear(2010);
      newDate = new Date(newDate).toISOString().split("T")[0];
    }
    try {
      await updateAgendaItem({
        requestId: request.id,
        agendaItemId,
        newDate: newDate,
      });
      getRequestDetails();
    } catch (error) {
      console.error("Error updating date:", error);
      setSnackbarMessage(error.response?.data?.error || "An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleApproverChange = async (agendaItemId, newValue) => {
    const newApproversEmails = newValue.map((u) => u.email || u);
    try {
      await updateAgendaItem({
        requestId: request.id,
        agendaItemId,
        newAssignedUsersEmails: newApproversEmails,
      });
      getRequestDetails();
    } catch (error) {
      console.error("Error updating approvers:", error);
      setSnackbarMessage(error.response?.data?.error || "An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleStepNotesChange = async (agendaItemId, newValue) => {
    try {
      await updateAgendaItem({
        requestId: request.id,
        agendaItemId,
        newNotes: newValue,
      });
      getRequestDetails();
      return { success: true };
    } catch (error) {
      console.error("Error updating notes:", error);
      setSnackbarMessage(error.response?.data?.error || "An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const getRoleValue = async (role) => {
    let itemWithRole = null;

    for (const stepGroup of steps) {
      for (const item of stepGroup) {
        console.log("Item", item.id, role);
        if (item.role === role) {
          itemWithRole = item;
          break;
        }
      }
      if (itemWithRole) break;
    }
    console.log("Item with role", itemWithRole.approvers);
    console.log("For role", role);
  };

  const handleRoleChange = async (role, newValue) => {
    try {
      // Loop through each step group to locate the item with the matching role
      let itemWithRole = null;
      for (const stepGroup of steps) {
        for (const item of stepGroup) {
          console.log("Item", item.id, role);
          if (item.role === role) {
            itemWithRole = item;
            break;
          }
        }
        if (itemWithRole) break;
      }

      // If the item with the specified role was not found, display an error message
      if (!itemWithRole) {
        console.error("No item found for the role:", role);
        setSnackbarMessage(`No item found for the role: ${role}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return { success: false };
      }

      console.log("Found item with role", role, itemWithRole);

      // Map newValue to an array of email addresses (handles both user objects and email strings)
      const newAssignedUsersEmails = newValue.map((u) => u.email || u);

      // Update the agenda item with the new assigned emails
      await updateAgendaItem({
        requestId: request.id,
        agendaItemId: itemWithRole.id,
        newAssignedUsersEmails,
      });

      setSnackbarMessage("Role updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Refresh the request details after the update
      getRequestDetails();

      return { success: true };
    } catch (error) {
      console.error("Error updating role:", error);
      setSnackbarMessage(error.response?.data?.error || "An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return { success: false };
    }
  };

  return (
    <>
      <OverviewHeader
        isListView={isListView}
        handleListViewToggle={handleListViewToggle}
      />
      {isListView ? (
        <ListView
          request={request}
          allUsers={allUsers}
          stepsNotesMap={stepsNotesMap}
          setStepsNotesMap={setStepsNotesMap}
          handleStatusChange={handleStatusChange}
          handleStepNotesChange={handleStepNotesChange}
          handleApproverChange={handleApproverChange}
          handleDateChange={handleDateChange}
          notes={notes}
          setNotes={setNotes}
          notesSavedSuccess={notesSavedSuccess}
          handleNotesBlur={handleNotesBlur}
        />
      ) : (
        <FlowView
          request={request}
          user={user}
          allUsers={allUsers}
          steps={steps}
          theme={theme}
          handleNodeClick={handleNodeClick}
          selectedNode={selectedNode}
          getRequestDetails={getRequestDetails}
          sendMessage={sendMessage}
          handleStatusChange={handleStatusChange}
          handleDateChange={handleDateChange}
          handleApproverChange={handleApproverChange}
          handleRoleChange={handleRoleChange}
          getRoleValue={getRoleValue}
          handleStepNotesChange={handleStepNotesChange}
          notes={notes}
          setNotes={setNotes}
          notesSavedSuccess={notesSavedSuccess}
          handleNotesBlur={handleNotesBlur}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Overview;
