import { useState, useEffect, useRef } from "react";
import { getChat } from "../api/chat";
import { initializeChat, streamChat } from "../api/chat";
import { useNavigate } from "react-router-dom";
import { sendMessageToChatbot, getCanvas } from "../api/chat";
import { translateOptions } from "../utils/formatting";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import QuizIcon from "@mui/icons-material/Quiz";

const useChat = ({
  initParams,
  handleNewMessages,
  setLatestUpdateId,
  isHelpdesk = false,
}) => {
  const [messages, setMessages] = useState([]);
  const [activePolicy, setActivePolicy] = useState(null);
  const [conversationOver, setConversationOver] = useState(false);
  const [options, setOptions] = useState([]);
  const [chatbotLoading, setChatbotLoading] = useState(false);
  const [chatbotFiles, setChatbotFiles] = useState([]);
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const inputRef = useRef(null); // Reference to the input field
  const [canvas, setCanvas] = useState({});

  const [initialOptions, setInitialOptions] = useState([]);
  const [context, setContext] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const initializeAgent = async (resetChat = null) => {
    try {
      const { policy, messages } = await getChat(initParams, resetChat);

      console.log("Initilizing agent: ", initParams);
      console.log("POLICY: ", policy);
      console.log("MESSAGES: ", messages);
      setActivePolicy(policy);
      setMessages(messages);

      console.log("Initial Policy: ", policy);
      console.log("Initial Messages: ", messages);
    } catch (error) {
      console.error("Error fetching initial policy:", error);
    }
  };

  const initializeHelpDeskAgent = async () => {
    try {
      const { initialPolicy, initialOptions, initialContext } =
        await initializeChat();

      console.log("Initial Options: ", initialOptions);
      const optionsWithIcons = initialOptions.map((option) => {
        let iconComponent;
        switch (option.icon) {
          case "ShoppingCartIcon":
            iconComponent = <ShoppingCartIcon color="error" />;
            break;
          case "QuizIcon":
            iconComponent = <QuizIcon color="warning" />;
            break;
          case "StoreIcon":
            iconComponent = <StoreIcon color="success" />;
            break;
          default:
            iconComponent = null;
        }
        return { ...option, icon: iconComponent };
      });

      setActivePolicy(initialPolicy);
      setInitialOptions(optionsWithIcons);
      setContext(initialContext);
      console.log("Active Policy: ", initialPolicy);
    } catch (error) {
      console.error("Error fetching initial policy:", error);
    }
  };

  useEffect(() => {
    if (isHelpdesk) {
      initializeHelpDeskAgent();
    } else {
      initializeAgent();
    }
  }, []);

  const updateCanvas = async (requestId, stepId) => {
    const { options, canvas } = await getCanvas(requestId, stepId);
    setOptions(translateOptions(options));

    setCanvas(canvas);
    return canvas;
  };

  const _sendMessage = async (message, files) => {
    setChatbotLoading(true);

    let newMessages = [
      {
        role: "user",
        content: message,
        files: files.map((file) => ({ name: file.name, type: file.type })),
      },
    ];
    console.log("messages: ", messages);
    let allNewMessages = [...messages, ...newMessages];

    setMessages(allNewMessages);

    const formData = new FormData();
    formData.append("newMessages", JSON.stringify(newMessages));

    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
    }

    formData.append("activePolicy", activePolicy);
    if (context) {
      formData.append("context", JSON.stringify(context));
    }

    if (initParams.request_id) {
      formData.append(
        "requestInformation",
        JSON.stringify({
          request_id: initParams.request_id,
          active_approval_id: initParams.active_approval_id,
        })
      );
    }

    let isConversationOver = conversationOver; // Initialize local variable

    try {
      let continueSending = true;
      let currentActivePolicy = activePolicy;

      while (continueSending) {
        continueSending = false;

        const { newMessages, isDone, newActivePolicy, newContext } =
          await sendMessageToChatbot(formData);

        console.log("NEW ACTIVE AGENT: ", newActivePolicy);
        console.log("isDone: ", isDone);

        if (isDone) {
          setConversationOver(true);
          isConversationOver = true; // Update local variable
        }

        if (newActivePolicy && newActivePolicy !== currentActivePolicy) {
          setActivePolicy(newActivePolicy);
          currentActivePolicy = newActivePolicy;
          formData.set("activePolicy", newActivePolicy);
          console.log("Current active agent: ", currentActivePolicy);
          continueSending = true;
        }

        setContext(newContext);

        allNewMessages = [...allNewMessages, ...newMessages];
        setMessages(allNewMessages);
        console.log("ALL NEW MESSAGES: ", allNewMessages);

        formData.set("newMessages", JSON.stringify([]));
        if (context) {
          formData.set("context", JSON.stringify(newContext));
        }

        const lastMessage = newMessages[newMessages.length - 1];

        if (lastMessage && lastMessage.role == "tool") {
          continueSending = true;
        }
      }
      setChatbotLoading(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }

      handleNewMessages({ newMessages: allNewMessages });
    } catch (error) {
      console.error("Error sending message:", error);
      setChatbotLoading(false);
      setSelectedFiles([]);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const sendMessage = async (message, files) => {
    setChatbotLoading(true);

    // Append the user message
    const userMessage = {
      role: "user",
      content: message,
      files: files.map((file) => ({ name: file.name, type: file.type })),
    };
    let newMessages = [userMessage];
    let allNewMessages = [...messages, ...newMessages];
    setMessages(allNewMessages);

    // Prepare formData
    const formData = new FormData();
    formData.append("newMessages", JSON.stringify(newMessages));

    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
    }

    formData.append("activePolicy", activePolicy);
    if (context) {
      formData.append("context", JSON.stringify(context));
    }
    if (initParams.request_id) {
      formData.append(
        "requestInformation",
        JSON.stringify({
          request_id: initParams.request_id,
          active_approval_id: initParams.active_approval_id,
        })
      );
    }

    // Add an assistant message placeholder to display the streamed response
    const assistantMessagePlaceholder = {
      role: "assistant",
      content: "",
      tool_calls: [],
    };
    setMessages((prevMessages) => [
      ...prevMessages,
      assistantMessagePlaceholder,
    ]);

    // Use streamChat to get the streaming response and update the messages
    try {
      await streamChat(formData, (chunk) => {
        if (!chunk.hasOwnProperty("role")) {
          if (chunk.newContext) {
            setContext(chunk.newContext);
          }
          if (chunk.newActivePolicy) {
            setActivePolicy(chunk.newActivePolicy);
          }
          if (chunk.isDone) {
            setConversationOver(true);
          }
        } else {
          // Then update the assistant message content.
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const lastIndex = updatedMessages.length - 1;
            const lastMessage = updatedMessages[lastIndex];

            if (lastMessage && lastMessage.role === chunk.role) {
              updatedMessages[lastIndex] = {
                ...lastMessage,
                content: lastMessage.content + chunk.content,
                tool_calls:
                  lastMessage.tool_calls?.concat(chunk.tool_calls) || [],
              };
            } else {
              updatedMessages.push(chunk);
            }
            console.log("UPDATED MESSAGES: ", updatedMessages);
            handleNewMessages({ newMessages: updatedMessages });
            return updatedMessages;
          });
        }
      });
    } catch (error) {
      console.error("Error streaming message:", error);
    } finally {
      setChatbotLoading(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const resetChat = async () => {
    console.log("resetChat");
    await initializeAgent(true);
    window.location.reload();
    console.log("messages: ", messages);
  };

  return {
    messages,
    setMessages,
    activePolicy,
    setActivePolicy,
    conversationOver,
    setConversationOver,
    options,
    setOptions,
    chatbotLoading,
    setChatbotLoading,
    chatbotFiles,
    setChatbotFiles,
    sendMessage,
    selectedFiles,
    setSelectedFiles,
    inputRef,
    updateCanvas,
    canvas,
    resetChat,
    initialOptions,
    context,
    setContext,
    drawerOpen,
    setDrawerOpen,
  };
};

export default useChat;
