import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogTitle,
  Alert,
  AlertTitle,
  List,
  Paper,
  ListItem,
  ListItemText,
  DialogActions,
  IconButton,
  Tooltip,
  DialogContent,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { submitForm } from "../../api/chat";
import FileAttachments from "../../Components/Chatbot/Files/FileAttachments";
import { useTheme } from "@mui/material/styles";
import FormDisplay from "./FormDisplay";
import { getErrors } from "../../utils/formUtils";

const IntakeForm = ({
  context,
  setContext,
  conversationOver,
  setConversationOver,
  allFiles,
  setAllFiles,
  chatbotLoading,
}) => {
  const [localFormConfig, setLocalFormConfig] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const theme = useTheme();

  // New states for file upload error handling via modal
  const [fileErrorDialogOpen, setFileErrorDialogOpen] = useState(false);
  const [fileErrorMessages, setFileErrorMessages] = useState([]);

  // New state and ref for drag & drop functionality in the intake form
  const [dragging, setDragging] = useState(false);
  const intakeFormRef = useRef(null);
  const dragCounter = useRef(0);

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB in bytes

  console.log("Intake form rendered", context);

  useEffect(() => {
    if (context.helpdesk_intake) {
      setLocalFormConfig(context.helpdesk_intake.form);
      setFadeIn(true);
    }
  }, [context.helpdesk_intake]);

  const handleFormUpdate = useCallback(
    (updatedFormConfig) => {
      setContext((prevContext) => ({
        ...prevContext,
        helpdesk_intake: {
          ...prevContext.helpdesk_intake,
          form: updatedFormConfig,
        },
      }));
    },
    [setContext]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      let { newErrors, newErrorFields } = getErrors(localFormConfig);

      setErrors(newErrors);
      setErrorFields(newErrorFields);

      if (Object.keys(newErrors).length > 0) {
        setDialogMessage("Please fill up the following fields");
        setOpenDialog(true);
        setLoading(false);
        return;
      }

      try {
        const response = await submitForm(localFormConfig, allFiles);
        if (response.success) {
          setDialogMessage(
            "Request submitted successfully. You can check the progress of the order by asking the AI assistant."
          );
          setOpenDialog(true);
          setConversationOver(true);

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          setDialogMessage(response.message);
          setOpenDialog(true);
        }
      } catch (error) {
        setDialogMessage("Error submitting the form. Please try again.");
        setOpenDialog(true);
      } finally {
        setLoading(false);
      }
    },
    [localFormConfig, allFiles, setConversationOver]
  );

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setErrorFields([]);
    setDialogMessage("");
  }, []);

  // --- Updated Drag & Drop Event Handlers for the IntakeForm ---
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    setDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current = 0;
    setDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    // Filter files by size (10MB limit)
    const validFiles = droppedFiles.filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    const invalidFiles = droppedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );

    if (invalidFiles.length > 0) {
      setFileErrorMessages(
        invalidFiles.map(
          (file) => `${file.name} exceeds the 10MB limit and was not attached.`
        )
      );
      setFileErrorDialogOpen(true);
    }

    const existingFileNames = allFiles.map((file) => file.name);
    const uniqueFiles = validFiles.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    // Add the new unique files to the allFiles state
    setAllFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
  };

  // --- Updated File Upload Handler for the Upload Button ---
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    // Filter valid files by size and separate invalid ones
    const validFiles = selectedFiles.filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    const invalidFiles = selectedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );

    if (invalidFiles.length > 0) {
      setFileErrorMessages(
        invalidFiles.map(
          (file) => `${file.name} exceeds the 10MB limit and was not attached.`
        )
      );
      setFileErrorDialogOpen(true);
    }

    const existingFileNames = allFiles.map((file) => file.name);
    const uniqueFiles = validFiles.filter(
      (file) => !existingFileNames.includes(file.name)
    );
    setAllFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
  };

  return (
    <Paper
      ref={intakeFormRef}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        color: "text.main",
        overflow: "hidden",
        pt: 2,
        px: 4,
        position: "relative", // For proper overlay positioning
      }}
    >
      <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
        {(() => {
          try {
            return (
              <FormDisplay
                formConfig={localFormConfig}
                setFormConfig={setLocalFormConfig}
                handleFormUpdate={handleFormUpdate}
                chatbotLoading={chatbotLoading}
                setIsModified={() => {}}
                title="Intake Form"
              />
            );
          } catch (error) {
            console.error("Error rendering FormDisplay:", error);
            return null;
          }
        })()}
      </Box>

      {/* File Attachments Display */}
      <Box
        sx={{
          width: "90%",
          mx: "auto",
          mb: 2,
        }}
      >
        <FileAttachments
          selectedFiles={allFiles}
          setSelectedFiles={setAllFiles}
          theme={theme}
        />
      </Box>

      {/* Row with Attach File Button and Submit Button */}
      <Box
        sx={{
          width: "90%",
          mx: "auto",
          mb: 2,
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <label htmlFor="intake-file-upload">
          <input
            style={{ display: "none" }}
            id="intake-file-upload"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <Tooltip title="Attach Files to Form">
            <IconButton component="span">
              <AttachFileIcon />
            </IconButton>
          </Tooltip>
        </label>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={loading || conversationOver}
          sx={{ flexGrow: 1 }}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>

      {/* Modal for File Size Errors */}
      {fileErrorDialogOpen && (
        <Dialog
          open={fileErrorDialogOpen}
          onClose={() => setFileErrorDialogOpen(false)}
        >
          <DialogTitle>File Upload Error</DialogTitle>
          <DialogContent>
            {fileErrorMessages.map((msg, index) => (
              <Typography key={index} variant="body2">
                {msg}
              </Typography>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setFileErrorDialogOpen(false)}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openDialog && (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ p: 10 }}>
          <Alert
            sx={{ pt: 2 }}
            severity={errorFields.length > 0 ? "error" : "success"}
          >
            <AlertTitle>{dialogMessage}</AlertTitle>
            {errorFields.length > 0 ? (
              <List dense sx={{ maxHeight: 500, overflow: "auto" }}>
                {errorFields.map((field, index) => (
                  <ListItem key={index} sx={{ my: 0 }}>
                    <ListItemText
                      primary={
                        <Typography variant="small">- {field}</Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              ></Box>
            )}
            {errorFields.length === 0 && (
              <DialogActions>
                <Button
                  variant="text"
                  color="success"
                  size="small"
                  onClick={() => window.location.reload()}
                  sx={{ width: "25%", mt: 2 }}
                >
                  Go to Home
                </Button>
              </DialogActions>
            )}
          </Alert>
        </Dialog>
      )}

      {/* Translucent Overlay for Dragging Files */}
      {dragging && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0, 0, 0, 0.3)",
            zIndex: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        >
          <Box sx={{ textAlign: "center", pointerEvents: "none" }}>
            <Typography
              variant="h5"
              component="h2"
              sx={{ mb: 2, fontWeight: "bold", color: "white" }}
            >
              Drop a file here to attach to the form
            </Typography>
            <Typography sx={{ color: "white" }}>
              Any file type is allowed.
            </Typography>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default IntakeForm;
