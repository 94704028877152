import { axiosInstance } from "./general";

export const fetchFormStructure = async () => {
  try {
    const response = await axiosInstance.get(`/settings/form`, {
      withCredentials: true,
    });
    return response.data.formStructure;
  } catch (error) {
    console.error("Error fetching form structure:", error);
    throw error;
  }
};

// New API method to save the form structure on the backend:
export const saveFormStructure = async (formStructure) => {
  try {
    const response = await axiosInstance.post(
      `/settings/form`,
      { formStructure },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving form structure:", error);
    throw error;
  }
};

export const getNotifications = async () => {
  try {
    const response = await axiosInstance.get(`/settings/get_notifications`, {
      withCredentials: true,
    });

    return response.data.notifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

// New API method to update notification setting on the backend
export const updateNotificationSetting = async (key, active) => {
  try {
    const response = await axiosInstance.post(
      `/settings/update_notification`,
      { key, active },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating notification setting:", error);
    throw error;
  }
};

export const updateFieldOptions = async (fieldId, options) => {
  try {
    const response = await axiosInstance.post(
      `/settings/update_field_options`,
      { fieldId, options },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating field options:", error);
    throw error;
  }
};

export const getFieldOptions = async (fieldId) => {
  try {
    const response = await axiosInstance.get(`/settings/get_field_options`, {
      params: { fieldId },
      withCredentials: true,
    });
    return response.data.options;
  } catch (error) {
    console.error("Error fetching field options:", error);
    throw error;
  }
};

export const getUserNotifications = async () => {
  try {
    const response = await axiosInstance.get(`/notifications`, {
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching notification settings:", error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await axiosInstance.post(
      `/notifications/${notificationId}/read`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error;
  }
};

export const fetchOrgApprovals = async () => {
  try {
    const response = await axiosInstance.get(`/settings/approvals`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching approvals:", error);
    throw error;
  }
};

export const updateApproval = async (approvals, approvers) => {
  try {
    const response = await axiosInstance.post(`/settings/approvals`, {
      approvals,
      approvers,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating approval:", error);
    throw error;
  }
};

export const getApprovers = async () => {
  try {
    const response = await axiosInstance.get(`/settings/approvers`, {
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching approvers:", error);
    throw error;
  }
};
