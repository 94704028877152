import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Switch,
  Divider,
  Paper,
} from "@mui/material";
import {
  getNotifications,
  updateNotificationSetting,
} from "../../api/settings";

const Notifications = () => {
  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const notificationsData = await getNotifications();
        setNotifications(notificationsData);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          setError("You are not authorized to view this page.");
        } else if (error.response.status === 404) {
          setError("Notifications are not available for your organization.");
        } else {
          setError(error.message);
        }
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleToggleNotification = async (key, isActive) => {
    // Optimistically update local state.
    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      [key]: {
        ...prevNotifications[key],
        active: isActive,
      },
    }));

    try {
      await updateNotificationSetting(key, isActive);
    } catch (apiError) {
      // If there is an error, revert the change
      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        [key]: {
          ...prevNotifications[key],
          active: !isActive,
        },
      }));
      console.error("Failed to update notification setting:", apiError);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{}}>
      {error && <Typography color="error">{error}</Typography>}
      {notifications && (
        <Paper variant="outlined" sx={{ mb: 0, pb: 0 }}>
          <List sx={{ m: 0, p: 0 }}>
            {Object.entries(notifications).map(([key, notification]) => (
              <React.Fragment key={key}>
                <ListItem>
                  <ListItemText
                    primary={notification.name}
                    // secondary={notification.subject}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mr: 1 }}
                  >
                    {notification.active ? "On" : "Off"}
                  </Typography>
                  <Switch
                    checked={notification.active}
                    onChange={(e) =>
                      handleToggleNotification(key, e.target.checked)
                    }
                    inputProps={{
                      "aria-label": `toggle ${notification.name}`,
                    }}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default Notifications;
