import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getIcon } from "../../../../Components/Diagram/Utils";
import { toCamelCase } from "../../../../utils/formatting";
import { parse, format, isValid } from "date-fns";
import CustomDatePicker from "../../../../Components/CustomDatePicker";

// ListView component renders the DataGrid view with inline editing.
export const ListView = ({
  request,
  allUsers,
  stepsNotesMap,
  setStepsNotesMap,
  handleStatusChange,
  handleStepNotesChange,
  handleApproverChange,
  handleDateChange,
  notes,
  setNotes,
  notesSavedSuccess,
  handleNotesBlur,
}) => {
  // We'll maintain a local copy of the rows for an optimistic UI.
  const [localRows, setLocalRows] = useState([]);

  // Build the rows from the request and stepsNotesMap. This effect runs whenever
  // the request (or notes map) changes so that if a refreshed version of the data
  // comes in, our grid will be updated.
  useEffect(() => {
    const approvalSteps = request.steps.flatMap((stepGroup, groupIndex) =>
      stepGroup
        .filter((step) => step.tags?.includes("approval"))
        .map((step, stepIndex) => ({
          ...step,
          groupIndex,
          stepIndex,
        }))
    );
    const newRows = approvalSteps.map((step) => ({
      id: step.id,
      stepName: step.name,
      status: step.status || "new",
      // Use the local map value for notes if available.
      notes: stepsNotesMap[step.id] ?? "",
      approver_info: {
        approvers: step.approvers || [],
        tags: step.tags || [],
        approverOptions: step.approver_options || [],
      },
      approvedDate: step.date || "",
    }));
    setLocalRows(newRows);
  }, [request, stepsNotesMap]);

  // Options to be used for the status field. Note that we provide both the value and the label.
  const statusOptions = [
    { value: "new", label: "Not Started" },
    { value: "in_progress", label: "In Progress" },
    { value: "complete", label: "Complete" },
    { value: "rejected", label: "Rejected" },
    { value: "not_applicable", label: "Not Applicable" },
  ];

  // Define the columns for the DataGrid.
  const columns = [
    {
      field: "stepName",
      headerName: "Step Name",
      flex: 1,
      // minWidth: 100,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      editable: true,
      // In display mode, show the icon and formatted label.
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          {getIcon(params.value, 20)}
          <Typography variant="body2" sx={{ ml: 1 }}>
            {params.value === "new" ? "Not Started" : toCamelCase(params.value)}
          </Typography>
        </Box>
      ),
      // Custom edit cell: a Select with MenuItem options containing icons.
      renderEditCell: (params) => {
        const { id, value, field, api } = params;
        const handleChange = (event) => {
          api.setEditCellValue({ id, field, value: event.target.value }, event);
        };

        return (
          <Select value={value} onChange={handleChange} fullWidth>
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {getIcon(option.value, 20)}
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {option.label}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: "notes",
      headerName: "Details",
      flex: 2,
      // minWidth: 200,
      editable: true,
    },
    {
      field: "approver_info",
      headerName: "Approvers",
      flex: 2,
      // minWidth: 250,
      editable: true,
      // This is what is displayed when not editing: we show chips.
      renderCell: (params) => {
        const approvers = params.value.approvers || [];
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              alignItems: "center",
              height: "100%",
            }}
          >
            {approvers.map((approver) => (
              <Chip key={approver.email} label={approver.email} />
            ))}
          </Box>
        );
      },
      // When entering edit mode, render an Autocomplete instead of a plain text field.
      renderEditCell: (params) => {
        const { id, value, field, api } = params;
        const approvers = value.approvers || [];
        const tags = value.tags || [];
        const approverOptions = value.approverOptions || [];

        const options =
          !approverOptions || approverOptions.length === 0
            ? []
            : approverOptions
            ? allUsers.filter((user) =>
                user.roles?.some((role) => approverOptions.includes(role.name))
              )
            : allUsers;
        // Ensure value is an array.
        const currentApprovers = Array.isArray(approvers) ? approvers : [];

        const handleChange = (event, newValue, reason) => {
          // Normalize the value: for freeSolo entries (strings), convert to an object.
          const normalized = newValue.map((item) => {
            if (typeof item === "string") {
              const found = allUsers.find((user) => user.email === item);
              return found || { email: item, name: item };
            }
            return item;
          });
          api.setEditCellValue({
            id,
            field,
            value: {
              approvers: normalized,
              tags,
              approverOptions,
            },
          });
        };

        return (
          <>
            {/* {JSON.stringify(value)} */}
            <Autocomplete
              multiple
              freeSolo
              options={options}
              getOptionLabel={(option) =>
                option?.name ? `${option.name}` : option
              }
              value={currentApprovers}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  size="small"
                  placeholder="Add approvers"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                />
              )}
              renderOption={(optionProps, option, { selected }) => (
                <MenuItem {...optionProps} key={option.email}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.name} ({option.email})
                </MenuItem>
              )}
              disableCloseOnSelect
              sx={{ width: "100%" }}
            />
          </>
        );
      },
    },
    {
      field: "approvedDate",
      headerName: "Approved Date",
      flex: 1,
      // minWidth: 150,
      editable: true,
      // Use CustomDatePicker for date editing.
      renderEditCell: (params) => {
        const { id, value, field, api } = params;
        return (
          <CustomDatePicker
            value={value}
            autoFocus
            onChange={(formattedDate) =>
              api.setEditCellValue({ id, field, value: formattedDate })
            }
          />
        );
      },
    },
  ];

  // Instead of using onCellEditCommit, we use processRowUpdate for optimistic UI updates.
  // This function determines which field changed, calls the appropriate backend handler,
  // and then updates the local state.
  const processRowUpdate = async (newRow, oldRow) => {
    console.log("newRow", newRow);
    console.log("oldRow", oldRow);
    const updatedField = Object.keys(newRow).find(
      (key) => newRow[key] !== oldRow[key]
    );
    console.log("-- updatedField", updatedField);
    if (updatedField) {
      if (updatedField === "status") {
        await handleStatusChange(newRow.id, newRow.status);
      } else if (updatedField === "notes") {
        await handleStepNotesChange(newRow.id, newRow.notes);
        // Immediately update the local notes map (so the lower notes TextField also shows the change).
        setStepsNotesMap((prev) => ({
          ...prev,
          [newRow.id]: newRow.notes,
        }));
      } else if (updatedField === "approver_info") {
        // Normalize the value in case freeSolo returns strings.
        const normalizedApprovers = newRow.approver_info.approvers.map((item) =>
          typeof item === "string"
            ? allUsers.find((u) => u.email === item) || {
                email: item,
                name: item,
              }
            : item
        );

        console.log("-- normalizedApprovers", normalizedApprovers);
        await handleApproverChange(newRow.id, normalizedApprovers);
        newRow.approvers = normalizedApprovers;
      } else if (updatedField === "approvedDate") {
        // Simulate an event for the date change.
        const fakeEvent = { target: { value: newRow.approvedDate } };
        await handleDateChange(newRow.id, oldRow.approvedDate, fakeEvent);
      }
    }
    // Update our optimistic state.
    setLocalRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            backgroundColor: "background.default",
            overflowY: "auto",

            border: "none",
          }}
        >
          <DataGrid
            autoHeight
            rows={localRows}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            getRowHeight={() => "auto"}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => console.error(error)}
            experimentalFeatures={{ newEditingApi: true }}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "background.dark",

                color: "primary.main",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-cell": {
                display: "flex",

                alignItems: "center",
                minHeight: "3rem !important",
              },
            }}
          />
        </Paper>

        <Paper
          elevation={0}
          sx={{
            height: "auto",
            width: "100%",
            backgroundColor: "background.default",
            overflowY: "hidden",
            pt: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextField
            label={notesSavedSuccess ? "Saved!" : "Notes"}
            placeholder="Type your notes ..."
            multiline
            fullWidth
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            onBlur={handleNotesBlur}
            rows={10}
            maxRows={10}
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: notesSavedSuccess ? "#e8f5e9" : "inherit",
                transition: "background-color 0.3s",
              },
              "& .MuiInputLabel-root": {
                color: notesSavedSuccess ? "#2e7d32" : "inherit",
              },
            }}
          />
        </Paper>
      </Box>
    </LocalizationProvider>
  );
};
