import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Chip,
  CircularProgress,
  Paper,
  TextField,
  Button,
  Popover,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import FilterListIcon from "@mui/icons-material/FilterList";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

import { format, isValid, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { mapColor } from "../../utils/formatting";
import {
  DataGrid,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { formatCurrency } from "../../utils/formUtils";
import CustomDatePicker from "../../Components/CustomDatePicker";
import useRequestsListPage from "../../hooks/useRequestsListPage";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
// Import debounce from lodash
import debounce from "lodash/debounce";
import theme from "../../theme";
import { getUserViews, deleteUserView, createUserView } from "../../api/auth";

function ViewsComponent() {
  if (!window.location.pathname.includes("all_requests")) return null;
  const navigate = useNavigate();

  const [views, setViews] = useState([]);
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [newViewName, setNewViewName] = useState("");

  useEffect(() => {
    const fetchViews = async () => {
      const response = await getUserViews();
      setViews(response?.all_requests || []);
    };
    fetchViews();
  }, []);

  const handleViewClick = (view) => {
    window.location.href = window.location.origin + view.path;
  };

  const handleDeleteView = async (viewId) => {
    // TODO: Implement delete view
    try {
      const response = await deleteUserView(viewId, "all_requests");
      setViews(views.filter((view) => view.id !== viewId));
    } catch (error) {
      console.error("Error deleting view:", error);
    }
  };

  const handleOpenNameDialog = () => {
    setOpenNameDialog(true);
  };

  const handleCloseNameDialog = () => {
    setOpenNameDialog(false);
    setNewViewName("");
  };

  const handleCreateView = async () => {
    if (!newViewName) {
      return;
    }

    try {
      const newView = await createUserView({
        name: newViewName,
        path: window.location.href.split(window.location.origin)[1],
        category: "all_requests",
      });
      setViews([...views, newView]);
      handleCloseNameDialog();
    } catch (error) {
      console.error("Error creating view:", error);
    }
  };

  const defaultParams = {
    sortField: "submitted_at",
    sortOrder: "desc",
  };

  const showCreateViewButton = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    console.log(views);
    return (
      views.length < 3 &&
      Array.from(searchParams.entries()).some(
        ([param, value]) =>
          !(param in defaultParams) || defaultParams[param] !== value
      )
    );
  }, [window.location.search, views]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 1,
        }}
      >
        {views.map((view) => (
          <Chip
            key={view.id}
            label={view.name}
            color="primary"
            onClick={() => handleViewClick(view)}
            onDelete={() => handleDeleteView(view.id)}
            variant="outlined"
            deleteIcon={<DeleteIcon />}
            size="small"
            sx={{ ml: 1 }}
          />
        ))}
      </Box>
      {showCreateViewButton && (
        <Button
          variant="contained"
          color="success"
          onClick={handleOpenNameDialog}
          startIcon={<AddIcon />}
          size="small"
        >
          Save View
        </Button>
      )}

      <Dialog open={openNameDialog} onClose={handleCloseNameDialog}>
        <DialogTitle>Save View</DialogTitle>
        <DialogContent sx={{ width: "24rem" }}>
          <TextField
            autoFocus={true}
            margin="dense"
            label="View Name"
            type="text"
            fullWidth
            variant="standard"
            value={newViewName}
            onChange={(e) => setNewViewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNameDialog}>Cancel</Button>
          <Button onClick={handleCreateView}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
// --------------------------------------------------------------------
// New separate actions component (search, filtering, column selection)
// --------------------------------------------------------------------
function RequestsListActions({
  localSearch,
  handleSearchInputChange,
  filterFrom,
  setFilterFrom,
  filterTo,
  setFilterTo,
  handleDateFilter,
  columns,
  apiRef,
  allUsers,
  handleAssignedToFilter,
  assignedToFilter,
}) {
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [columnsAnchor, setColumnsAnchor] = useState(null);

  // Local state for the applied date filter (to display a chip)
  const [appliedFilter, setAppliedFilter] = useState(null);

  // NEW: Local state to hold changes for the assigned-to filter while in the popover.
  const [localAssignedToFilter, setLocalAssignedToFilter] = useState(
    assignedToFilter || []
  );
  // This state saves the applied assigned-to filter for showing a chip.
  const [appliedAssignedTo, setAppliedAssignedTo] = useState(
    assignedToFilter || []
  );

  // Force a re-render when the grid state (e.g. column visibility) changes.
  const [, forceUpdate] = useState(0);
  useEffect(() => {
    const unsubscribe = apiRef.current.subscribeEvent("stateChange", () => {
      forceUpdate((n) => n + 1);
    });
    return () => unsubscribe();
  }, [apiRef]);

  useEffect(() => {
    setLocalAssignedToFilter(assignedToFilter);
    setAppliedAssignedTo(assignedToFilter);
  }, [assignedToFilter]);

  useEffect(() => {
    if (filterFrom || filterTo) {
      setAppliedFilter({ from: filterFrom, to: filterTo });
    } else {
      setAppliedFilter(null);
    }
  }, [filterFrom, filterTo]);

  // ----------------------------
  // Filter popover logic
  // ----------------------------
  const handleFilterToggleClick = (event) => {
    setFilterAnchor(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchor(null);
  };
  const openFilterPopover = Boolean(filterAnchor);

  const formatDate = (date) => {
    return date ? format(new Date(date), "yyyy-MM-dd") : "";
  };

  const handleApplyFilter = () => {
    const formattedFrom = formatDate(filterFrom);
    const formattedTo = formatDate(filterTo);

    if (handleDateFilter) {
      handleDateFilter(formattedFrom, formattedTo);
    }
    // Update the date filter chip if any date is provided
    if (formattedFrom || formattedTo) {
      setAppliedFilter({ from: formattedFrom, to: formattedTo });
    } else {
      setAppliedFilter(null);
    }

    // Now update the assigned-to filter only when "Apply Filter" is clicked.
    if (handleAssignedToFilter) {
      handleAssignedToFilter(localAssignedToFilter);
    }
    if (localAssignedToFilter && localAssignedToFilter.length > 0) {
      setAppliedAssignedTo(localAssignedToFilter);
    } else {
      setAppliedAssignedTo([]);
    }

    handleFilterClose();
  };

  // Handler to remove the applied date filter chip.
  const handleFilterChipDelete = () => {
    setAppliedFilter(null);
    setFilterFrom(null);
    setFilterTo(null);
    if (handleDateFilter) {
      handleDateFilter("", "");
    }
  };

  // ----------------------------
  // Column selector popover logic
  // ----------------------------
  const handleColumnsToggleClick = (event) => {
    setColumnsAnchor(event.currentTarget);
  };
  const handleColumnsClose = () => {
    setColumnsAnchor(null);
  };
  const openColumnsPopover = Boolean(columnsAnchor);

  // Helper: determine if a column is visible (default is true).
  const isColumnVisible = (field) => {
    if (!apiRef.current) return true;
    const model =
      (apiRef.current.state &&
        apiRef.current.state.columns &&
        apiRef.current.state.columns.columnVisibilityModel) ||
      {};
    return model[field] !== false;
  };

  // Helper: toggle the column visibility via the grid API.
  const handleColumnToggle = (field, newValue) => {
    if (apiRef.current) {
      apiRef.current.setColumnVisibility(field, newValue);
    }
  };

  // Build the chip label for the date filter.
  const chipLabel =
    appliedFilter && appliedFilter.from && appliedFilter.to
      ? `Submitted between ${appliedFilter.from} and ${appliedFilter.to}`
      : appliedFilter && appliedFilter.from
      ? `Submitted after ${appliedFilter.from}`
      : appliedFilter && appliedFilter.to
      ? `Submitted before ${appliedFilter.to}`
      : "";

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 2,
      }}
    >
      <TextField
        placeholder="Search requests"
        variant="standard"
        size="small"
        value={localSearch}
        onChange={handleSearchInputChange}
        sx={{ width: "20rem", mr: 2 }}
      />
      <Button
        variant="text"
        size="small"
        onClick={handleFilterToggleClick}
        startIcon={<FilterListIcon />}
        sx={{ mr: 2 }}
      >
        Filter
      </Button>
      <Button
        variant="text"
        size="small"
        onClick={(event) => setColumnsAnchor(event.currentTarget)}
        startIcon={<ViewColumnIcon />}
        sx={{ mr: 2 }}
      >
        Columns
      </Button>

      <ViewsComponent />
      {/* Spacer to push the chips to the right */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Date filter chip */}
      {appliedFilter && (
        <Chip
          label={chipLabel}
          onDelete={handleFilterChipDelete}
          color="primary"
          sx={{ mr: 2 }}
        />
      )}

      {/* Assigned-to filter chip */}
      {appliedAssignedTo && appliedAssignedTo.length > 0 && (
        <Chip
          label={(() => {
            const firstNames = appliedAssignedTo.map(
              (u) => u.name.split(" ")[0]
            );
            if (firstNames.length > 4) {
              const visibleNames = firstNames.slice(0, 4).join(", ");
              const extraCount = firstNames.length - 4;
              return `Assigned to: ${visibleNames} and ${extraCount} more`;
            }
            return `Assigned to: ${firstNames.join(", ")}`;
          })()}
          onDelete={() => {
            setAppliedAssignedTo([]);
            setLocalAssignedToFilter([]);
            if (handleAssignedToFilter) {
              handleAssignedToFilter([]);
            }
          }}
          color="primary"
          sx={{ mr: 2 }}
        />
      )}

      {/* Filter Popover */}
      <Popover
        open={openFilterPopover}
        anchorEl={filterAnchor}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              width: "40rem",
            },
          },
        }}
      >
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <CustomDatePicker
              label="Submitted After"
              size="small"
              value={filterFrom}
              onChange={(newValue) => setFilterFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <CustomDatePicker
              label="Submitted Before"
              size="small"
              value={filterTo}
              onChange={(newValue) => setFilterTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <Button variant="contained" onClick={handleApplyFilter}>
              Apply
            </Button>
          </Box>
          <Autocomplete
            multiple
            options={allUsers}
            getOptionLabel={(option) => option.name}
            value={localAssignedToFilter}
            onChange={(event, newValue) => {
              setLocalAssignedToFilter(newValue);
              setAppliedAssignedTo(newValue);
              if (handleAssignedToFilter) {
                handleAssignedToFilter(newValue);
              }
            }}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Assigned To" />
            )}
            sx={{ width: "100%", mt: 2 }}
          />
        </Paper>
      </Popover>

      {/* Column Selector Popover */}
      <Popover
        open={Boolean(columnsAnchor)}
        anchorEl={columnsAnchor}
        onClose={() => setColumnsAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {columns.map((column) => (
              <FormControlLabel
                key={column.field}
                control={
                  <Checkbox
                    size="small"
                    checked={isColumnVisible(column.field)}
                    onChange={(e) =>
                      handleColumnToggle(column.field, e.target.checked)
                    }
                  />
                }
                label={column.headerName}
              />
            ))}
          </Box>
        </Paper>
      </Popover>
    </Paper>
  );
}

// --------------------------------------------------------------------
// Main component: RequestsListPage
// --------------------------------------------------------------------
const RequestsListPage = ({
  showContractInfo = true,
  showPurchaseOrderInfo = false,
  storageKey = "allRequestsListGridState",
  searchAndFilterEnabled = true,
  assignedOnly = false,
  allUsers = [],
}) => {
  const {
    requests,
    loading,
    error,
    currentPage,
    perPage,
    totalPages,
    totalRequests,
    sortField,
    sortOrder,
    searchQuery,
    changeSortModel,
    handleSearch,
    handleDateFilter,
    goToNextPage,
    goToPrevPage,
    setCurrentPage,
    setPerPage,
    assignedToFilter,
    handleAssignedToFilter,
    submittedFrom,
    submittedTo,
  } = useRequestsListPage({}, assignedOnly, allUsers);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  // Local state to manage the search field value.
  const [localSearch, setLocalSearch] = useState(searchQuery);

  useEffect(() => {
    setLocalSearch(searchQuery);
  }, [searchQuery]);

  // Use a debounced search function.
  const debouncedSearch = useRef(
    debounce((value) => {
      handleSearch(value);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchInputChange = (e) => {
    const newValue = e.target.value;
    setLocalSearch(newValue);
    debouncedSearch(newValue);
  };

  const formattedRequests = Array.isArray(requests)
    ? requests
    : Object.entries(requests).map(([id, request]) => ({
        id,
        ...request,
      }));

  const handleSelectRequest = (request) => {
    navigate(`/dashboard/request/${request.id}`);
  };

  const [filterFrom, setFilterFrom] = useState(null);
  const [filterTo, setFilterTo] = useState(null);

  useEffect(() => {
    setFilterFrom(submittedFrom);
    setFilterTo(submittedTo);
  }, [submittedFrom, submittedTo]);

  // The tooltip enter delay (in milliseconds)
  const TOOLTIP_ENTER_DELAY = 500;

  // NOTE: All columns now use a renderCell that wraps content in a Tooltip.
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <Tooltip
            enterDelay={TOOLTIP_ENTER_DELAY}
            title={String(params.value)}
          >
            <span>{params.value}</span>
          </Tooltip>
        ) : (
          <Box sx={{ color: "text.disabled" }}>NA</Box>
        ),
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 2,
      renderCell: (params) =>
        params.value ? (
          <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={params.value}>
            <span>{params.value}</span>
          </Tooltip>
        ) : (
          <Box sx={{ color: "text.disabled" }}>NA</Box>
        ),
    },
    {
      field: "requester_name",
      headerName: "Requester Name",
      flex: 1.5,
      renderCell: (params) =>
        params.value ? (
          <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={params.value}>
            <span>{params.value}</span>
          </Tooltip>
        ) : (
          <Box sx={{ color: "text.disabled" }}>NA</Box>
        ),
    },
    ...(showContractInfo
      ? [
          {
            field: "contract",
            headerName: "Contract ID",
            flex: 1,
            renderCell: (params) =>
              params.value ? (
                <Tooltip
                  enterDelay={TOOLTIP_ENTER_DELAY}
                  title={params.value.display_id}
                >
                  <span>{params.value.display_id}</span>
                </Tooltip>
              ) : (
                <Box sx={{ color: "text.disabled" }}>None</Box>
              ),
          },
          {
            field: "contract_name",
            headerName: "Contract Name",
            flex: 2,
            renderCell: (params) =>
              params.row.contract && params.row.contract.name ? (
                <Tooltip
                  enterDelay={TOOLTIP_ENTER_DELAY}
                  title={params.row.contract.name}
                >
                  <span>{params.row.contract.name}</span>
                </Tooltip>
              ) : (
                <Box sx={{ color: "text.disabled" }}>NA</Box>
              ),
          },
          {
            field: "contract_identifier",
            headerName: "Contract Identifier",
            flex: 2,
            renderCell: (params) =>
              params.row.contract && params.row.contract.identifier ? (
                <Tooltip
                  enterDelay={TOOLTIP_ENTER_DELAY}
                  title={params.row.contract.identifier}
                >
                  <span>{params.row.contract.identifier}</span>
                </Tooltip>
              ) : (
                <Box sx={{ color: "text.disabled" }}>NA</Box>
              ),
          },
          {
            field: "contract_value",
            headerName: "Contract Value",
            flex: 1,
            renderCell: (params) =>
              params.row.contract && params.row.contract.max_value !== null ? (
                <Tooltip
                  enterDelay={TOOLTIP_ENTER_DELAY}
                  title={formatCurrency(params.row.contract.max_value)}
                >
                  <span>{formatCurrency(params.row.contract.max_value)}</span>
                </Tooltip>
              ) : (
                <Box sx={{ color: "text.disabled" }}>NA</Box>
              ),
          },
          {
            field: "contract_start_date",
            headerName: "Contract Start Date",
            flex: 1,
            renderCell: (params) => {
              const date = params.row.contract
                ? params.row.contract.start_date
                : null;
              if (!date) {
                return <Box sx={{ color: "text.disabled" }}>NA</Box>;
              }
              const parsed = parseISO(date);
              const formatted = isValid(parsed)
                ? format(parsed, "MMM d, yyyy")
                : "NA";
              return (
                <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={formatted}>
                  <span>{formatted}</span>
                </Tooltip>
              );
            },
          },
          {
            field: "contract_ppoc",
            headerName: "Contract Primary POC",
            flex: 1.5,
            renderCell: (params) =>
              params.row.contract && params.row.contract.ppoc_name ? (
                <Tooltip
                  enterDelay={TOOLTIP_ENTER_DELAY}
                  title={params.row.contract.ppoc_name}
                >
                  <span>{params.row.contract.ppoc_name}</span>
                </Tooltip>
              ) : (
                <Box sx={{ color: "text.disabled" }}>NA</Box>
              ),
          },
        ]
      : []),
    ...(showPurchaseOrderInfo
      ? [
          {
            field: "purchaseOrderId",
            headerName: "Purchase Order ID",
            flex: 1,
            renderCell: () => (
              <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title="NA">
                <span>NA</span>
              </Tooltip>
            ),
            renderCell: () => (
              <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title="NA">
                <span>NA</span>
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      field: "assigned_users",
      headerName: "Assigned To",
      flex: 1,
      renderCell: (params) => {
        const users = params.value;
        if (!users || users.length === 0) {
          return <Box sx={{ color: "error.main" }}>None</Box>;
        }
        if (users.length === 1) {
          return (
            <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={users[0].name}>
              <span>{users[0].name}</span>
            </Tooltip>
          );
        }
        const firstNames = users.map((u) => u.name.split(" ")[0]);
        const fullNames = users.map((u) => u.name).join(", ");
        return (
          <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={fullNames}>
            <span>{firstNames.join(", ")}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "business_unit",
      headerName: "Business Unit",
      flex: 2,
      renderCell: (params) =>
        params.value ? (
          <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={params.value}>
            <span>{params.value}</span>
          </Tooltip>
        ) : (
          <Box sx={{ color: "text.disabled" }}>NA</Box>
        ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
      renderCell: (params) =>
        params.value ? (
          <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={params.value}>
            <span>{params.value}</span>
          </Tooltip>
        ) : (
          <Box sx={{ color: "text.disabled" }}>NA</Box>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.5,
      renderCell: (params) => {
        console.log("ROW", params.row);
        const label =
          params.row.display_status ||
          params.row.current_approval?.display_name ||
          params.row.current_approval?.name ||
          "";

        const bgColor =
          params.row.display_status == "Complete"
            ? theme.palette.success.main
            : params.row.display_status == "Rejected"
            ? theme.palette.error.main
            : mapColor(params.row.current_approval?.display_color);

        return (
          <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={label}>
            <span>
              <Chip
                label={label}
                sx={{
                  backgroundColor: bgColor,
                  color: theme.palette.getContrastText(bgColor),
                }}
              />
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "submitted_at",
      headerName: "Submitted At",
      flex: 1.5,
      renderCell: (params) => {
        const date = params.value;
        const parsed = parseISO(date);
        const formatted = isValid(parsed)
          ? format(parsed, "MMM d, yyyy")
          : "N/A";
        return (
          <Tooltip enterDelay={TOOLTIP_ENTER_DELAY} title={formatted}>
            <span>{formatted}</span>
          </Tooltip>
        );
      },
    },
  ];

  // ------------------------------------------------------------------
  // GRID STATE PERSISTENCE (save/restore columns and density settings)
  // ------------------------------------------------------------------
  const initialGridState = useMemo(() => {
    const savedState = localStorage.getItem(storageKey);
    return savedState ? JSON.parse(savedState) : {};
  }, []);

  const apiRef = useGridApiRef();

  useEffect(() => {
    const unsubscribe = apiRef.current.subscribeEvent("stateChange", () => {
      const state = apiRef.current.state;
      const gridStateToSave = {
        columns: {
          columnVisibilityModel: state.columns.columnVisibilityModel,
        },
        density: state.density,
      };
      localStorage.setItem(storageKey, JSON.stringify(gridStateToSave));
    });
    return () => {
      unsubscribe();
    };
  }, [apiRef, storageKey]);

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        px: 4,
        pt: 2,
      }}
    >
      {/* Render the separate actions component above the grid */}
      {searchAndFilterEnabled && (
        <RequestsListActions
          localSearch={localSearch}
          handleSearchInputChange={handleSearchInputChange}
          filterFrom={filterFrom}
          setFilterFrom={setFilterFrom}
          filterTo={filterTo}
          setFilterTo={setFilterTo}
          handleDateFilter={handleDateFilter}
          columns={columns}
          apiRef={apiRef}
          allUsers={allUsers}
          assignedToFilter={assignedToFilter}
          handleAssignedToFilter={handleAssignedToFilter}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
        }}
        variant="outlined"
        elevation={0}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <DataGrid
            disableColumnFilter
            apiRef={apiRef}
            initialState={initialGridState}
            rows={formattedRequests}
            columns={columns}
            loading={loading}
            pagination
            paginationMode="server"
            rowCount={totalRequests}
            paginationModel={{ page: currentPage - 1, pageSize: perPage }}
            onPaginationModelChange={(newModel) => {
              setCurrentPage(newModel.page + 1);
              setPerPage(newModel.pageSize);
            }}
            sortingMode="server"
            sortModel={[{ field: sortField, sort: sortOrder }]}
            onSortModelChange={(newModel) => {
              changeSortModel(newModel);
            }}
            disableSelectionOnClick
            onRowClick={(params) => handleSelectRequest(params.row)}
            slots={{
              noRowsOverlay: () => (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 2,
                  }}
                >
                  <Typography variant="h6">No requests found.</Typography>
                </Box>
              ),
            }}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                // overflow: "auto",
                // textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2, // limit to 2 lines
                WebkitBoxOrient: "vertical",
                lineHeight: "1.4em",
              },
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default RequestsListPage;
