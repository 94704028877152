import React from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  Chip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { sendComment } from "../../../api/request";
import MessageInput from "../../../Components/Chatbot/MessageInput";
// Removed icon imports and iconMap since we are dropping icons

// Utility to pick a color from a set based on the user's name
const colors = [
  "info.dark",
  "warning.dark",
  "error.dark",
  "success.dark",
  "secondary.dark",
  "primary.dark",
];

function getStatusColor(status) {
  if (status === "new") {
    return "info";
  }
  return "primary";
}

function hashString(str = "") {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

function getColorForName(name = "") {
  const index = hashString(name) % colors.length;
  return colors[index];
}

const formatDate = ({ isoString, includeTime = true }) => {
  // Check if the isoString includes a time component
  const isDateOnly = !isoString.includes("T");

  // Create the date object accordingly
  const date = isDateOnly
    ? new Date(`${isoString}T00:00:00`) // Treat as local date without timezone conversion
    : new Date(isoString); // Use the full ISO string for datetime

  // console.log(
  //   "Date: ",
  //   date,
  //   "isDateOnly: ",
  //   isDateOnly,
  //   "isoString: ",
  //   isoString
  // );

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const timeString = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  let dateString;
  if (date.toDateString() === today.toDateString()) {
    dateString = "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    dateString = "Yesterday";
  } else {
    const options = { month: "short", day: "numeric", year: "numeric" };
    dateString = date.toLocaleDateString(undefined, options);
  }

  return (
    <Typography variant="caption">
      {dateString}
      {includeTime && `, ${timeString}`}
    </Typography>
  );
};

function getColorForStatus(status) {
  return status === "in_progress"
    ? "warning"
    : status === "complete"
    ? "success"
    : status === "new"
    ? "info"
    : status === "rejected" || status === "cancelled"
    ? "error"
    : "info";
}

const CustomizedList = ({ logs, users }) => {
  const formatMentions = (text) => {
    const mentionMatches = text.match(/@\d+/g);
    if (mentionMatches) {
      for (const mention of mentionMatches) {
        const user = users.find((user) => user.id == mention.replace("@", ""));
        if (user) {
          const htmlMention = `<span style="color: #1976d2">@${user.name}</span>`;
          text = text.replace(mention, htmlMention);
        }
      }
    }
    return text;
  };

  return (
    <Box sx={{ mx: 0, px: 1 }}>
      {logs.map((log, index) => {
        const initials = log.user?.name
          ?.split(" ")
          .map((n) => n[0])
          .join("");
        const avatarColor = getColorForName(log.user?.name);

        // Check if log.type is not a comment
        if (
          log.type === "assigned_users_update" ||
          log.type === "status_update" ||
          log.type === "date_update" ||
          log.type === "notes_update"
        ) {
          return (
            <Box
              key={index}
              sx={{
                my: 2,
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography variant="caption">
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mx: 0.5 }}
                >
                  {formatDate({ isoString: log.timestamp, includeTime: true })}
                </Typography>
                {log.type === "status_update" && (
                  <>
                    <strong>{log.user?.name}</strong> changed the status of{" "}
                    <strong>{log.agenda_item}</strong> from
                    <Chip
                      variant="outlined"
                      size="small"
                      label={log.old_status}
                      sx={{ mx: 0.5 }}
                      color={getColorForStatus(log.old_status)}
                    />{" "}
                    to
                    <Chip
                      variant="outlined"
                      size="small"
                      label={log.new_status}
                      sx={{ mx: 0.5 }}
                      color={getColorForStatus(log.new_status)}
                    />
                  </>
                )}
                {log.type === "assigned_users_update" && (
                  <>
                    <strong>{log.user?.name}</strong> updated the assignees of{" "}
                    <strong>{log.agenda_item}</strong> to{" "}
                    {log.new_assigned_users.length > 0 ? (
                      <Box
                        component="span"
                        sx={{ display: "inline", color: "info.main" }}
                      >
                        <strong>{log.new_assigned_users.join(", ")}</strong>
                      </Box>
                    ) : (
                      <Box
                        component="span"
                        sx={{ display: "inline", color: "text.disabled" }}
                      >
                        <>NONE</>
                      </Box>
                    )}
                  </>
                )}
                {log.type === "date_update" && (
                  <>
                    <strong>{log.user?.name}</strong> changed the date of{" "}
                    <strong>{log.agenda_item}</strong> from
                    <Chip
                      size="small"
                      variant="outlined"
                      label={
                        log.old_date
                          ? formatDate({
                              isoString: log.old_date,
                              includeTime: false,
                            })
                          : "N/A"
                      }
                      sx={{ mx: 0.5 }}
                    />
                    to{" "}
                    <Chip
                      size="small"
                      variant="outlined"
                      label={
                        log.new_date
                          ? formatDate({
                              isoString: log.new_date,
                              includeTime: false,
                            })
                          : "N/A"
                      }
                      sx={{ mx: 0.5 }}
                    />
                  </>
                )}
                {log.type === "notes_update" && (
                  <>
                    <strong>{log.user?.name}</strong> updated the notes of{" "}
                    <strong>{log.agenda_item}</strong> from
                    <Chip
                      size="small"
                      variant="filled"
                      label={log.old_notes ? log.old_notes : "NONE"}
                      sx={{ mx: 0.5 }}
                    />
                    to
                    <Chip
                      size="small"
                      variant="filled"
                      label={log.new_notes ? log.new_notes : "NONE"}
                      sx={{ mx: 0.5 }}
                    />
                  </>
                )}
              </Typography>
            </Box>
          );
        }

        return (
          <Box key={index} sx={{ my: 2, px: 2 }}>
            {/* Avatar and Description box */}
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: (theme) =>
                    theme.palette?.[avatarColor.split(".")[0]]?.[
                      avatarColor.split(".")[1]
                    ] || "grey.300",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  mr: 1,
                }}
              >
                {initials}
              </Avatar>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    flex: 1,
                    backgroundColor: "background.dark",
                    p: 1,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: formatMentions(log.description),
                    }}
                  />
                </Paper>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 0.5,
                    pl: 0.5,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {formatDate({
                      isoString: log.timestamp,
                      includeTime: true,
                    })}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const AuditLog = ({ requestId, logs, users }) => {
  const theme = useTheme();
  const [comment, setComment] = React.useState("");
  const [newLogs, setNewLogs] = React.useState(logs);
  // Add state for mentions
  const [mentions, setMentions] = React.useState([]);
  const [mentionableUsers, setMentionableUsers] = React.useState([]);
  const inputRef = React.useRef(null);

  // Ref for the scrollable timeline container
  const timelineContainerRef = React.useRef(null);

  // Whenever newLogs changes, scroll to bottom
  React.useEffect(() => {
    if (timelineContainerRef.current) {
      timelineContainerRef.current.scrollTop =
        timelineContainerRef.current.scrollHeight;
    }
  }, [newLogs]);

  const handleAddComment = async (input) => {
    if (input.trim()) {
      // Convert display names back to IDs before sending
      let processedInput = input;
      const matches = processedInput.match(/`@(.*?)`/g);
      if (matches) {
        matches.forEach((match) => {
          const user = users.find(
            (user) => user.name === match.replaceAll("`", "").replace("@", "")
          );
          if (user) {
            processedInput = processedInput.replace(match, `@${user.id}`);
          }
        });
      }

      const response = await sendComment(requestId, processedInput, mentions);
      setNewLogs(response.logs);
      setComment("");
      setMentions([]);
    }
  };

  const handleSetComment = (comment) => {
    // detect mentions in the comment
    const mentionMatches = comment.match(/(?<!`)@[^\s]+/g);
    if (mentionMatches) {
      const lastMentionMatch = mentionMatches[
        mentionMatches.length - 1
      ].replace("@", "");

      const mentionableUsers = users.filter(
        (user) =>
          user.name.toLowerCase().includes(lastMentionMatch.toLowerCase()) ||
          lastMentionMatch == ""
      );
      setMentionableUsers(mentionableUsers);
    } else {
      setMentionableUsers([]);
    }

    // if there is an open ` without a closing, remove the opening one
    // meaning is we have an odd number of ` then remove the end of the string
    const matches = comment.match(/`/g);
    if (matches && matches.length % 2 === 1) {
      comment = comment.replace(/`[^`]*$/, "");
      setMentions(mentions.slice(0, -1));
    }
    setComment(comment);
  };

  const handleMentionSelect = (selectedUser) => {
    const mentionMatches = comment.match(/@[^\s]+/g);
    if (mentionMatches) {
      const lastMentionMatch = mentionMatches[mentionMatches.length - 1];
      const displayText = `\`@${selectedUser.name}\` `;

      // Replace the mention text with the display version
      setComment(comment.replace(`${lastMentionMatch}`, displayText));

      setMentions([...mentions, selectedUser.id]);

      inputRef.current.focus();
    } else {
      setComment(comment);
    }
    setMentionableUsers([]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%", // Make sure the parent has a defined height
      }}
    >
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        elevation={0}
      >
        {/* Timeline container (85%) */}
        <Paper
          elevation={0}
          // variant="outlined"
          ref={timelineContainerRef}
          sx={{
            height: "80vh",
            overflowY: "auto",
          }}
        >
          <CustomizedList logs={newLogs} users={users} />
        </Paper>

        {/* Comment box container (15%) */}
        <Box
          sx={{
            height: "10%",
            display: "flex",
            width: "100%",
            alignItems: "flex-end",
          }}
        >
          <MessageInput
            input={comment}
            setInput={handleSetComment}
            sendMessage={handleAddComment}
            chatbotLoading={false}
            theme={theme}
            selectedFiles={[]}
            setSelectedFiles={() => {}}
            allFiles={[]}
            setAllFiles={() => {}}
            inputRef={inputRef}
            noMessage={false}
            initialOptions={[]}
            placeholder={"Message your team"}
            mentionableUsers={mentionableUsers}
            handleMentionSelect={handleMentionSelect}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default AuditLog;

// <TextField
//   variant="outlined"
//   fullWidth
//   placeholder="Message your team..."
//   value={comment}
//   onChange={handleCommentChange}
//   onKeyDown={handleKeyDown}
//   sx={{ mr: 2 }}
//   multiline
//   maxRows={2}
// />
// <Button
//   variant="contained"
//   color="primary"
//   onClick={handleAddComment}
// >
//   Send
// </Button>
