import React, { useEffect, useState, useCallback } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
} from "@xyflow/react";

import { Box } from "@mui/material";
import "@xyflow/react/dist/style.css";
import StepNode from "./StepNode";
import { getStepNodeData } from "./Utils";
import GroupPanel from "./GroupPanel";
import { mapColor } from "../../utils/formatting";

const nodeWidth = 16 * 16;
const nodeHeight = 10 * 16;
const baseGroupSpacing = nodeWidth + 70; // spacing between groups
const columnSpacing = nodeWidth + 20; // extra offset for additional columns in a group
const rowSpacing = nodeHeight + 20; // vertical spacing for rows
const stackSize = 4;

// --- Lay out nodes in columns/rows ---
// Updated to stack nodes > 5 per group in columns of 5,
// and to shift the next group according to the number of stacks
const getLayoutedElements = (nodes, edges) => {
  // Group nodes by their group index
  const groups = {};
  nodes.forEach((node) => {
    // node.id is in the form "step-groupIndex-stepIndex"
    const parts = node.id.split("-");
    const groupIdx = parts[1];
    if (!groups[groupIdx]) {
      groups[groupIdx] = [];
    }
    groups[groupIdx].push(node);
  });

  let accumulatedOffset = 0; // Offset accumulated from previous groups
  // Process each group in order (by group index)
  Object.keys(groups)
    .sort((a, b) => +a - +b)
    .forEach((groupIdx) => {
      const groupNodes = groups[groupIdx];

      // Sort nodes by their step index to ensure the proper order
      groupNodes.sort((a, b) => {
        const aStep = parseInt(a.id.split("-")[2], 10);
        const bStep = parseInt(b.id.split("-")[2], 10);
        return aStep - bStep;
      });

      const totalNodes = groupNodes.length;
      // Determine the number of columns needed (each column holds up to 5 nodes)
      const columns = Math.ceil(totalNodes / stackSize);

      groupNodes.forEach((node) => {
        const parts = node.id.split("-");
        const stepIndexNum = parseInt(parts[2], 10);

        // Compute which column and row this node should appear in.
        const column = Math.floor(stepIndexNum / stackSize);
        const row = stepIndexNum % stackSize;

        // For vertical centering, adjust when a column has fewer than 5 nodes.
        const nodesInThisColumn =
          column === columns - 1 && totalNodes % stackSize !== 0
            ? totalNodes % stackSize
            : stackSize;
        const columnHeight = (nodesInThisColumn - 1) * rowSpacing;

        node.position = {
          x: accumulatedOffset + column * columnSpacing,
          y: row * rowSpacing - columnHeight / 2,
        };
        node.targetPosition = "left";
        node.sourcePosition = "right";
      });

      // Update the accumulatedOffset for the next group.
      // It increases by baseGroupSpacing plus extra space for additional columns.
      accumulatedOffset += baseGroupSpacing + (columns - 1) * columnSpacing;
    });

  return { nodes, edges };
};

const nodeTypes = {
  stepNode: StepNode,
  groupPanel: GroupPanel,
};

function FLD({
  user,
  allUsers,
  steps,
  theme,
  selectedNode,
  handleNodeClick,
  request,
  sendMessage,
  getRequestDetails,
}) {
  const { setViewport, getViewport, fitView } = useReactFlow();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [numRenders, setNumRenders] = useState(0);

  // 5) The main effect that builds out nodes/edges.
  React.useEffect(() => {
    console.log("FLOW DIAGRAM MOUNTED. RENDER COUNT: ", numRenders);
    setNumRenders((prev) => prev + 1);
    if (!steps?.length) {
      setNodes([]);
      setEdges([]);
      return;
    }

    // 0) Filter out step items that are not required
    let filteredSteps = steps
      .map((stepGroup) =>
        stepGroup.filter(
          (step) => step.required || step.status != "not_applicable"
        )
      )
      .filter((stepGroup) => stepGroup.length > 0);

    console.log("filteredSteps", filteredSteps);

    // 1) Generate base nodes & edges from `steps`
    let generatedNodes = [];
    let generatedEdges = [];

    filteredSteps.forEach((stepGroup, groupIndex) => {
      stepGroup.forEach((step, stepIndex) => {
        const nodeId = `step-${groupIndex}-${stepIndex}`;
        generatedNodes.push({
          id: nodeId,
          // Provide the step data for StepNode
          data: {
            ...getStepNodeData(step, nodeWidth, nodeHeight),
            getRequestDetails,
            requestId: request.id,
            allUsers: allUsers,
            sendMessage: sendMessage,
            active: step.id === selectedNode?.id,
          },
          type: "stepNode",
          draggable: false,
        });

        // Connect edges from previous group to this group
        if (groupIndex > 0) {
          const prevGroup = filteredSteps[groupIndex - 1];
          prevGroup.forEach((_, prevIndex) => {
            const sourceId = `step-${groupIndex - 1}-${prevIndex}`;
            const targetId = nodeId;
            generatedEdges.push({
              id: `${sourceId}-${targetId}`,
              source: sourceId,
              target: targetId,
              type: "step",
              animated: true,

              style: {
                stroke: theme.palette.primary.main,
                strokeWidth: 2,
              },
            });
          });
        }
      });
    });

    // 2) Layout the diagram
    let { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      generatedNodes,
      generatedEdges
    );

    // Optionally update step node active state
    layoutedNodes = layoutedNodes.map((node) => ({
      ...node,
      data: { ...node.data, active: node.data.id === selectedNode?.id },
    }));

    // 3) --- Add Background Panel Nodes for Each Step Group ---
    // Here we group the step nodes (with ids like "step-X-Y") to calculate a bounding box for each group.
    const paddingX = 20;
    const paddingY = 20; // Increase this value to cover the bottom of the nodes
    const groupsInfo = {};

    console.log("layoutedNodes", layoutedNodes);
    console.log("layoutedEdges", layoutedEdges);

    layoutedNodes.forEach((node) => {
      if (node.id.startsWith("step-")) {
        const parts = node.id.split("-");
        const groupIdx = parts[1];
        const { x, y } = node.position;
        const nodeRight = x + nodeWidth;
        const nodeBottom = y + nodeHeight; // Ensure this includes the full height

        if (!groupsInfo[groupIdx]) {
          groupsInfo[groupIdx] = {
            minX: x,
            minY: y,
            maxX: nodeRight,
            maxY: nodeBottom,
          };
        } else {
          groupsInfo[groupIdx].minX = Math.min(groupsInfo[groupIdx].minX, x);
          groupsInfo[groupIdx].minY = Math.min(groupsInfo[groupIdx].minY, y);
          groupsInfo[groupIdx].maxX = Math.max(
            groupsInfo[groupIdx].maxX,
            nodeRight
          );
          groupsInfo[groupIdx].maxY = Math.max(
            groupsInfo[groupIdx].maxY,
            nodeBottom
          );
        }
      }
    });

    console.log("groupsInfo", groupsInfo);

    // Create a background node for each group with more than one node.
    const backgroundNodes = Object.keys(groupsInfo)
      .filter((groupIdx) => {
        // Only include groups with more than one node
        const groupNodes = layoutedNodes.filter((node) =>
          node.id.startsWith(`step-${groupIdx}-`)
        );
        return groupNodes.length > 1;
      })
      .map((groupIdx) => {
        const { minX, minY, maxX, maxY } = groupsInfo[groupIdx];
        return {
          id: `group-bg-${groupIdx}`,
          type: "groupPanel",
          position: {
            x: minX - paddingX,
            y: minY - paddingY,
          },
          data: {
            width: maxX - minX + 2 * paddingX,
            height: maxY - minY + 2 * paddingY,
            backgroundStyle: {
              backgroundColor: "background.dark",
              border: `1px solid background.dark`,
              pointerEvents: "none",
            },
          },
          draggable: false,
          selectable: false,
        };
      });

    // Merge background nodes before the step nodes so they lie underneath.
    layoutedNodes = [...backgroundNodes, ...layoutedNodes];

    console.log("layoutedNodes Final", layoutedNodes);
    console.log("layoutedEdges Final", layoutedEdges);

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [selectedNode, request, allUsers]);

  // Callback to fit view on resize
  const handleResize = useCallback(() => {
    fitView({ duration: 400 }); // Adjust duration as needed
  }, [fitView]);

  // Attach a custom resize observer
  useEffect(() => {
    const container = document.querySelector(".react-flow");
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [handleResize]);

  // --- Add Custom onWheel Handler for Horizontal Scrolling ---
  const onWheel = useCallback(
    (event) => {
      // event.preventDefault();
      const flow = getViewport();
      const container = document.querySelector(".react-flow");
      const diagramWidth = container.clientWidth;
      if (container) {
        const deltaY = event.deltaY;
        const scrollSpeed = 0.7; // Adjust this value for desired smoothness
        const current_zoom = getViewport().zoom;

        // Calculate the minimum and maximum x values based on nodes
        const nodeXPositions = nodes.map((node) => node.position.x);

        const MIN_X = Math.min(...nodeXPositions) * current_zoom - 100; // Add some padding if needed
        const MAX_X = Math.max(...nodeXPositions) * current_zoom - 100; // Add some padding if needed

        // Calculate the new x position
        let newX = -(flow.x - deltaY * scrollSpeed);

        // Clamp the newX within the boundaries
        // newX = Math.max(MIN_X, Math.min(MAX_X, newX));
        newX = Math.max(MIN_X, newX);
        newX = Math.min(MAX_X, newX);

        newX = -newX;

        setViewport({
          ...flow,
          x: newX,
          y: flow.y,
        });
      }
    },
    [getViewport, setViewport, nodes, nodeWidth] // Include nodes in the dependency array
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        border: `1px solid ${theme.palette.background.dark}`,
        backgroundColor: theme.palette.background.default,
        borderRadius: 1,
        overflow: "hidden", // Prevents scrollbars from appearing
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        // onConnect={onConnect}
        nodeTypes={nodeTypes}
        minZoom={0.5}
        maxZoom={1}
        // nodeExtent defines the bounding box [topLeft, bottomRight]
        fitView
        selectionOnDrag={false}
        // panOnDrag={false}
        panOnScroll={false} // Disable default pan on scroll
        zoomOnScroll={false}
        zoomOnPinch={true}
        zoomOnDoubleClick={false}
        attributionPosition="top-right"
        onNodeClick={(event, node) => {
          if (node.type === "stepNode") {
            console.log("NODE CLICKED ", node);
            handleNodeClick(node);
          }
        }}
        // onNodeDragStop={onNodeDragStop}
        // onMoveEnd={onMoveEnd}
        onPaneClick={(event, node) => {
          if (!node) {
            console.log("PANE CLICKED ", node);
            // handleNodeClick(null);
          }
        }}
        onWheel={onWheel} // Add the custom onWheel handler
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls
          style={{ flexDirection: "row" }}
          showZoom={true}
          showInteractive={false}
        />
      </ReactFlow>
    </Box>
  );
}

export default function FlowDiagram({
  user,
  allUsers,
  steps,
  request,
  theme,
  handleNodeClick,
  onStatusChange,
  onApproverChange,
  getRequestDetails,
  sendMessage,
  selectedNode,
}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  }, []);
  return (
    <ReactFlowProvider>
      {isLoading ? (
        <div></div>
      ) : (
        <FLD
          user={user}
          allUsers={allUsers}
          steps={steps}
          request={request}
          theme={theme}
          handleNodeClick={handleNodeClick}
          onStatusChange={onStatusChange}
          onApproverChange={onApproverChange}
          getRequestDetails={getRequestDetails}
          sendMessage={sendMessage}
          selectedNode={selectedNode}
        />
      )}
    </ReactFlowProvider>
  );
}
