// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Auth/Login";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "./theme";
import { Navigate } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Onboard from "./Auth/Onboard";
import OrgNotRegistered from "./Auth/OrgNotRegistered";
import IntakeDashboard from "./Intake/Dashboard";
import { CssBaseline } from "@mui/material";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/onboarding" element={<Onboard />} />
            <Route path="/org_not_registered" element={<OrgNotRegistered />} />

            <Route path="/helpdesk" element={<IntakeDashboard />} />
            {/* <Route path="/" element={<LandingPage />} /> */}
            {/* <Route path="/terms" element={<TermsOfService />} /> */}
            {/* <Route path="/privacy" element={<PrivacyStatement />} /> */}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
