import { useState } from "react";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { Box, Paper, TextField } from "@mui/material";
import FlowDiagram from "../../../../Components/Diagram/FlowDiagram";
import NodeDetails from "./NodeDetails";

// FlowView component renders the resizable diagram along with node details and a notes editor.
export const FlowView = ({
  request,
  user,
  allUsers,
  steps,
  theme,
  handleNodeClick,
  selectedNode,
  getRequestDetails,
  sendMessage,
  handleStatusChange,
  handleDateChange,
  handleApproverChange,
  handleRoleChange,
  getRoleValue,
  handleStepNotesChange,
  notes,
  setNotes,
  notesSavedSuccess,
  handleNotesBlur,
}) => {
  const minDiagramHeight = window.innerHeight * 0.2;
  const maxDiagramHeight = window.innerHeight * 0.8;
  // Retrieve the saved diagram height or use a default based on the window height.
  const initialDiagramHeight =
    parseFloat(localStorage.getItem(`diagramHeight-${request.id}`)) ||
    window.innerHeight * 0.4;

  const [diagramHeight, setDiagramHeight] = useState(
    initialDiagramHeight > maxDiagramHeight
      ? maxDiagramHeight
      : initialDiagramHeight < minDiagramHeight
      ? minDiagramHeight
      : initialDiagramHeight
  );

  const [isHandleVisible, setIsHandleVisible] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <ResizableBox
        width={Infinity}
        height={diagramHeight}
        axis="y"
        minConstraints={[Infinity, minDiagramHeight]}
        maxConstraints={[Infinity, maxDiagramHeight]}
        onResize={(e, data) => setDiagramHeight(data.size.height)}
        onResizeStop={(e, data) => {
          console.log("data", data.size.height);
          setDiagramHeight(data.size.height);
          localStorage.setItem(`diagramHeight-${request.id}`, data.size.height);
        }}
        handle={
          <Box
            sx={{
              position: "absolute",
              bottom: -25,
              right: 0,
              width: "100%",
              height: "20px",
              cursor: "ns-resize",
              zIndex: 1,
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseEnter={() => setIsHandleVisible(true)}
            onMouseLeave={() => setIsHandleVisible(false)}
          >
            <Box
              sx={{
                height: "2px",
                width: "100%",
                borderRadius: "2px",
                transition: "background-color 0.3s",
                backgroundColor: isHandleVisible
                  ? "primary.main"
                  : "transparent",
              }}
            />
          </Box>
        }
      >
        <Paper
          elevation={0}
          sx={{
            // px: 1,
            backgroundColor: "background.default",
            height: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            width: "100%",
          }}
        >
          <FlowDiagram
            user={user}
            allUsers={allUsers}
            steps={steps}
            request={request}
            theme={theme}
            handleNodeClick={handleNodeClick}
            selectedNode={selectedNode}
            getRequestDetails={getRequestDetails}
            sendMessage={sendMessage}
          />
        </Paper>
      </ResizableBox>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 2, mt: 2 }}>
        <Paper
          elevation={0}
          // variant="outlined"
          sx={{
            height: "auto",
            width: "60%",
            backgroundColor: "background.default",
            overflowY: "hidden",
            // padding: 1,
            // mt: 1,
          }}
        >
          {selectedNode ? (
            <NodeDetails
              nodeId={selectedNode.id}
              allUsers={allUsers}
              getRequestDetails={getRequestDetails}
              sendMessage={sendMessage}
              request={request}
              handleStatusChange={handleStatusChange}
              handleDateChange={handleDateChange}
              handleApproverChange={handleApproverChange}
              handleRoleChange={handleRoleChange}
              handleStepNotesChange={handleStepNotesChange}
              getRoleValue={getRoleValue}
            />
          ) : null}
        </Paper>
        <Paper
          elevation={0}
          sx={{
            height: "auto",
            width: "40%",
            backgroundColor: "background.default",
            overflowY: "hidden",
            pt: 1,
            // pr: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextField
            label={notesSavedSuccess ? "Saved!" : "Notes"}
            placeholder="Type your notes ..."
            multiline
            fullWidth
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            onBlur={handleNotesBlur}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              flex: 1,
              "& .MuiInputBase-root": {
                backgroundColor: notesSavedSuccess ? "#e8f5e9" : "inherit",
                transition: "background-color 0.3s",
                flex: 1,
                display: "flex",
                alignItems: "stretch",
              },
              "& .MuiInputBase-inputMultiline": {
                height: "100%",
                resize: "none",
                boxSizing: "border-box",
              },
            }}
          />
        </Paper>
      </Box>
    </Box>
  );
};
