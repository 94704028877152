import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemButton,
  IconButton,
  CircularProgress,
  Typography,
  Modal,
  Paper,
  Tooltip,
  Divider,
  Button,
  Dialog,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  downloadRequestFile,
  viewRequestFile,
  deleteRequestFile,
  uploadRequestFiles,
} from "../../../api/request";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";
import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const DocumentExplorer = ({
  files,
  requestId,
  getRequestDetails,
  contractId,
}) => {
  const [open, setOpen] = useState(false);
  const [currentFileUrl, setCurrentFileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const handleDownload = async (filename) => {
    try {
      const downloadUrl = await downloadRequestFile(requestId, filename);
      // Open the download URL in a new window
      window.open(downloadUrl, "_blank");
    } catch (error) {
      alert("Failed to download file.");
    }
  };

  const requestDeleteConfirmation = (filename) => {
    setFileToDelete(filename);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmDialogOpen(false);
    try {
      await deleteRequestFile(requestId, fileToDelete);
      getRequestDetails();
    } catch (error) {
      alert(error.response?.data?.error || "Failed to delete file.");
    }
    setFileToDelete(null);
  };

  const handleView = async (filename, external_url) => {
    if (external_url) {
      window.open(external_url, "_blank");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const viewUrl = await viewRequestFile(requestId, filename);
      setCurrentFileUrl(viewUrl);
      setOpen(true);
    } catch (error) {
      setError("Failed to load file for viewing.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentFileUrl("");
    setError("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
  };

  const isViewable = (filename) => {
    const viewableExtensions = ["pdf", "jpg", "jpeg", "png", "gif", "json"];
    const fileExtension = filename.split(".").pop().toLowerCase();
    return viewableExtensions.includes(fileExtension);
  };

  const getFileIcon = (filename) => {
    const fileExtension = filename.split(".").pop().toLowerCase();
    if (fileExtension === "pdf") {
      return <PictureAsPdfIcon />;
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <ImageIcon />;
    } else {
      return <InsertDriveFileRoundedIcon />;
    }
  };

  const handleFileChange = async (e, type) => {
    setUploading(type);
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

    const uploadedFiles = Array.from(e.target.files);

    // Separate files into valid and invalid arrays
    const validFiles = uploadedFiles.filter(
      (file) => file.size <= MAX_FILE_SIZE
    );

    const invalidFiles = uploadedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );

    // If any invalid files are found, show the invalid file dialog.
    if (invalidFiles.length > 0) {
      alert("Invalid file size. Please upload a file less than 10MB.");
    }

    try {
      await uploadRequestFiles(requestId, validFiles, type);
      await getRequestDetails();
    } catch (error) {
      alert(error.response.data.error || "Failed to upload file.");
    }
    setUploading(null);
  };

  const legalFiles = files.filter((file) => file?.type === "legal");
  const attachmentFiles = files.filter((file) => file?.type === "attachment");
  const defaultFiles = files.filter(
    (file) => file?.type === "default" || !file?.type
  );

  const fileTypes = [
    {
      type: "attachment",
      label: "Contract Attachments",
      files: attachmentFiles,
      uploadDisabled: contractId ? false : true,
      downloadDisabled: true,
      deleteDisabled: false,
    },
    {
      type: "legal",
      label: "Legal Document",
      files: legalFiles,
      uploadDisabled: contractId ? false : true,
      downloadDisabled: false,
      deleteDisabled: true,
    },
    {
      type: "default",
      label: "Optimized Documents",
      files: defaultFiles,
      uploadDisabled: false,
      downloadDisabled: false,
      deleteDisabled: false,
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginBottom: 2,
      }}
    >
      {fileTypes.map((fileType) => (
        <Paper
          elevation={0}
          sx={{
            padding: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {fileType.label}
          </Typography>
          <List>
            {fileType.files.length === 0 && (
              <ListItem>
                <ListItemText
                  sx={{
                    color: "text.secondary",
                    display: "flex",
                    justifyContent: "center",
                    // backgroundColor: "background.dark",
                  }}
                  primary={`No ${fileType.label} available for this request`}
                />
              </ListItem>
            )}
            {fileType.files.map((file, index) => (
              <Paper elevation={0} sx={{ backgroundColor: "background.dark" }}>
                <ListItem key={index} sx={{}}>
                  <ListItemAvatar>
                    <Avatar>{getFileIcon(file.name)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.name}
                    secondary={file.description || null}
                  />
                  {fileType.type === "attachment" ? (
                    <Tooltip title="Open Externally">
                      <span>
                        <IconButton
                          edge="end"
                          aria-label="open in coupa"
                          onClick={() =>
                            window.open(file?.external_url, "_blank")
                          }
                          sx={{ mr: 2 }}
                        >
                          <LaunchIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          !isViewable(file.name)
                            ? "Can't display file type in browser"
                            : "View in Optimized"
                        }
                      >
                        <span>
                          <IconButton
                            edge="end"
                            aria-label="view"
                            onClick={() =>
                              handleView(file.name, file?.external_url)
                            }
                            disabled={!isViewable(file.name)}
                            sx={{ mr: 1 }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Download">
                        <span>
                          <IconButton
                            edge="end"
                            aria-label="download"
                            onClick={() => handleDownload(file.name)}
                            sx={{ mr: 2 }}
                            disabled={fileType.downloadDisabled}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Delete">
                    <span>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => requestDeleteConfirmation(file.name)}
                        sx={{ mr: 2 }}
                        disabled={fileType.deleteDisabled}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </ListItem>
              </Paper>
            ))}
            <input
              type="file"
              id={`${fileType.type}-file-upload`}
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, fileType.type)}
              multiple={true}
              // accept=".pdf"
            />
            <label htmlFor={`${fileType.type}-file-upload`}>
              <Button
                variant="contained"
                color="success"
                component="span"
                size="small"
                sx={{
                  mt: 1,
                  float: "right",
                  position: "relative",
                  width: "25%",
                }}
                disabled={
                  uploading === fileType.type || fileType.uploadDisabled
                }
              >
                {uploading === fileType.type ? (
                  <CircularProgress color="inherit" size={24} />
                ) : ["attachment", "default"].includes(fileType.type) ? (
                  <>
                    <AddIcon />
                    Upload new file
                  </>
                ) : fileType.files?.length > 0 ? (
                  <>
                    <AutorenewIcon />
                    Replace legal file
                  </>
                ) : (
                  <>
                    <AddIcon />
                    Upload legal file
                  </>
                )}
              </Button>
            </label>
          </List>
        </Paper>
      ))}

      {/* Preview Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="preview-modal-title"
      >
        <Box sx={style}>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100%"
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <iframe
              src={currentFileUrl}
              title="File Preview"
              width="100%"
              height="100%"
              style={{ border: "none", borderRadius: 12 }}
            ></iframe>
          )}
        </Box>
      </Modal>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <Alert severity="error" sx={{ pr: 4, py: 2 }}>
          <AlertTitle>Confirm Deletion</AlertTitle>
          Are you sure you want to delete <strong>{fileToDelete}</strong>?
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setConfirmDialogOpen(false)} color="inherit">
              No
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Yes
            </Button>
          </Box>
        </Alert>
      </Dialog>

      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default DocumentExplorer;
