import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  getOrganizationUsers,
  getAllRoles,
  setUserRoles,
} from "../../api/general";
import { toCamelCase } from "../../utils/formatting";

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]); // All available roles
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getOrganizationUsers();
      console.log("response", response);
      setUsers(
        response.users
          .map((user) => ({
            ...user,
            roles: user.roles.map((role) => role.name), // Set user.roles to a list of role names
          }))
          .sort((a, b) => a?.name?.localeCompare(b?.name)) // Sort users by name
      );
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch users.");
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await getAllRoles();
      setRoles(response.roles);
    } catch (err) {
      setError("Failed to fetch roles.");
    }
  };

  const handleRoleChange = (userId, roleName) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id !== userId) return user;
        const hasRole = user.roles.includes(roleName);
        return {
          ...user,
          roles: hasRole
            ? user.roles.filter((role) => role !== roleName)
            : [...user.roles, roleName],
        };
      })
    );
  };

  const handleSave = async () => {
    setSaving(true);
    setError("");
    try {
      for (const user of users) {
        await setUserRoles(user.id, user.roles);
      }
      setSaving(false);
      setSuccess("Roles updated successfully.");
    } catch (err) {
      setError("Failed to update roles.");
      setSaving(false);
    }
  };

  // While loading, show progress
  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Define the columns for the DataGrid:
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Box sx={{ pl: 2, width: "100%" }}>{params.value}</Box>
      ),
    },
    // Map each role to a column with a custom render for the checkbox and text:
    ...roles.map((role) => ({
      field: role.name, // use role name as identifier
      headerName: toCamelCase(role.name),
      flex: 1,
      align: "left",
      headerAlign: "left",
      sortable: true,
      filterable: false,

      valueGetter: (params, value) => {
        return value.roles.includes(role.name);
      },

      sortComparator: (v1, v2, params) => {
        return v1 === v2 ? 0 : v1 ? -1 : 1;
      },

      renderCell: (params) => {
        const isChecked = params.row.roles.includes(role.name);
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={isChecked}
              onChange={() => handleRoleChange(params.row.id, role.name)}
              color="primary"
              inputProps={{
                "aria-label": `${params.row.name} ${role.name}`,
              }}
            />
            <Typography
              sx={{ ml: 1 }}
              color={isChecked ? "primary" : "text.disabled"}
            >
              {isChecked ? "Yes" : "No"}
            </Typography>
          </Box>
        );
      },
    })),
  ];

  // Custom toolbar that renders only the Quick Filter on the left:
  const CustomToolbar = () => (
    <Box sx={{ p: 1, display: "flex", justifyContent: "flex-start" }}>
      <GridToolbarQuickFilter placeholder="Quick search..." />
    </Box>
  );

  return (
    <Box sx={{ height: "100%" }}>
      {error && (
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
      )}

      {/* DataGrid container with fixed height for scroll */}
      <Box
        sx={{
          width: "100%",
          margin: "0 auto",
          height: "90%", // Fixed height to allow scrolling within the DataGrid
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          disableSelectionOnClick
          pageSize={10}
          rowsPerPageOptions={[10]}
          rowCount={users.length}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            "& .MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            // Left align name and email columns:
            "& .MuiDataGrid-cell:nth-of-type(1), & .MuiDataGrid-cell:nth-of-type(2)":
              {
                justifyContent: "flex-start",
              },
          }}
        />
      </Box>

      <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? <CircularProgress size={24} /> : "Save Changes"}
        </Button>
      </Box>
      <Box sx={{ mt: 1, mb: 4, display: "flex", justifyContent: "center" }}>
        {success && (
          <Typography color="success.main" gutterBottom>
            {success}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AdminPanel;
