import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Chip,
  Link,
  Paper,
} from "@mui/material";
import { motion } from "framer-motion"; // Import Framer Motion
import Overview from "./Tabs/OverView/Overview";
import IntakeForm from "./Tabs/IntakeForm";
import AuditLog from "./Tabs/AuditLog";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Chatbot from "../../Components/Chatbot/Chatbot";
import useRequest from "../../hooks/useRequest";
import DocumentExplorer from "./Tabs/DocumentExplorer";
import Contract from "./Tabs/Contract";
import Settings from "./Tabs/Settings";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useChat from "../../hooks/useChat";
import { ResizableBox } from "react-resizable";
import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";
import PurchaseOrder from "./Tabs/PurchaseOrder";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { formatCurrency } from "../../utils/formUtils";
import _ from "lodash";

const pageVariants = {
  initial: {
    opacity: 0,
    x: 10,
    transition: {
      duration: 0.2,
    },
  },
  in: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
    },
  },
  out: {
    opacity: 0,
    x: -10,
    transition: {
      duration: 0.2,
    },
  },
};

const RequestPage = ({ user, contacts, allUsers }) => {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    activeTab,
    setActiveTab,
    handleTabChange,
    requestSteps,
    requestForms,
    request,
    getRequestDetails,
    selectedNode,
    handleNodeClick,
    contract,
    purchaseOrder,
  } = useRequest(requestId);

  const {
    messages,
    setMessages,
    activePolicy,
    setActivePolicy,
    conversationOver,
    setConversationOver,
    options,
    setOptions,
    resetChat,
    chatbotLoading,
    setChatbotLoading,
    chatbotFiles,
    setChatbotFiles,
    sendMessage,
    selectedFiles,
    setSelectedFiles,
    inputRef,
    canvas,
    setCanvas,
    updateCanvas,
  } = useChat({
    initParams: {
      agent_name: "dashboard_intake_request",
      request_id: requestId,
      active_approval_id: selectedNode?.id || null,
    },
    handleNewMessages: useCallback(
      _.debounce(() => {
        console.log("NEW MESSAGES: ", messages);
        getRequestDetails();
      }, 1000),
      [getRequestDetails]
    ),
  });

  const initialChatbotWidth =
    parseFloat(localStorage.getItem(`chatbotWidth-general`)) > 100
      ? parseFloat(localStorage.getItem(`chatbotWidth-general`))
      : window.innerWidth * 0.35;
  const [chatbotWidth, setChatbotWidth] = useState(initialChatbotWidth);

  const [isHandleVisible, setIsHandleVisible] = useState(false);

  useEffect(() => {
    if (!request) {
      // Handle case where request is not found or is still loading.
    }
  }, [request]);

  useEffect(() => {
    // Parse the URL query parameter "tab"
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");

    if (tabParam) {
      // Mapping from friendly tab names or numeric strings to the tab index
      const tabMapping = {
        overview: 0,
        "intake-form": 1,
        documents: 2,
        chat: 3,
        contract: 4,
        "purchase-order": 5,
        settings: 6,
      };

      // Try parsing tabParam as a number; if that fails, use the mapping
      let newActiveTab = parseInt(tabParam, 10);
      if (isNaN(newActiveTab)) {
        newActiveTab = tabMapping[tabParam.toLowerCase()];
      }

      // If a valid index is found, update the active tab
      if (newActiveTab !== undefined) {
        setActiveTab(newActiveTab);
      }
    }
  }, [location.search, setActiveTab]);

  if (!request) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        backgroundColor: "background.dark",
      }}
    >
      {/* Chat Panel (Left) with its own header */}
      <ResizableBox
        width={chatbotWidth}
        height={Infinity}
        axis="x"
        minConstraints={[300, Infinity]}
        maxConstraints={[window.innerWidth - 300, Infinity]}
        onResize={(e, data) => setChatbotWidth(data.size.width)}
        onResizeStop={(e, data) => {
          setChatbotWidth(data.size.width);
          localStorage.setItem(`chatbotWidth-general`, data.size.width);
        }}
        handle={
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: -18,
              width: "20px",
              height: "100%",
              cursor: "ew-resize",
              zIndex: 1,
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseEnter={() => setIsHandleVisible(true)}
            onMouseLeave={() => setIsHandleVisible(false)}
          >
            <Box
              sx={{
                width: isHandleVisible ? "2px" : "1px",
                height: "100%",
                borderRadius: "2px",
                transition: "background-color 0.3s",
                backgroundColor: isHandleVisible
                  ? "primary.main"
                  : "transparent",
              }}
            />
          </Box>
        }
      >
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            elevation: 0,
            px: 4,
            // px: 1,
          }}
        >
          {/* Chat Header */}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "background.paper",
              display: "flex",
              flexDirection: "column",
              pt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                maxWidth: "100%",
              }}
            >
              <IconButton
                onClick={() => {
                  if (window.history.length > 1) {
                    navigate(-1);
                  } else {
                    navigate("/dashboard");
                  }
                }}
                disabled={chatbotLoading}
                sx={{
                  mr: 1,
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" sx={{ fontWeight: "bold" }} noWrap>
                #{request.id}: {request.title}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DescriptionIcon
                    sx={{
                      mr: 0.5,
                      color: "text.disabled",
                      fontSize: "small",
                    }}
                  />
                  {contract ? (
                    <Link
                      href={contract.internal_link}
                      variant="caption"
                      color="text.disabled"
                      underline="hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contract #: {contract.display_id}
                    </Link>
                  ) : (
                    <Typography variant="caption" color="text.disabled">
                      No Contract
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", ml: 1.5 }}>
                  <CalendarTodayIcon
                    sx={{
                      mr: 0.5,
                      color: "text.disabled",
                      fontSize: "small",
                    }}
                  />
                  <Typography variant="caption" color="text.disabled">
                    Submitted{" "}
                    {new Date(request.submitted_at).toLocaleDateString()}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", ml: 1.5 }}>
                  <AttachMoneyIcon
                    sx={{
                      mr: 0.5,
                      color: "text.disabled",
                      fontSize: "small",
                    }}
                  />
                  <Typography variant="caption" color="text.disabled">
                    Total: {formatCurrency(request.total_cost)}
                  </Typography>
                </Box>
              </Box>

              <Tooltip title="Reset Chat">
                <IconButton
                  onClick={resetChat}
                  color="error"
                  disabled={chatbotLoading}
                >
                  <RotateLeftRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {/* Chatbot Content */}
          <Box sx={{ flex: 1, overflow: "auto" }}>
            <Chatbot
              user={user}
              messages={messages}
              setMessages={setMessages}
              activePolicy={activePolicy}
              setActivePolicy={setActivePolicy}
              conversationOver={conversationOver}
              setConversationOver={setConversationOver}
              options={options}
              setOptions={setOptions}
              chatbotLoading={chatbotLoading}
              setChatbotLoading={setChatbotLoading}
              chatbotFiles={chatbotFiles}
              setChatbotFiles={setChatbotFiles}
              sendMessage={sendMessage}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              inputRef={inputRef}
              resetChat={resetChat}
            />
          </Box>
        </Paper>
      </ResizableBox>

      {/* Panel (Right) with its own header (Tabs) */}
      <Paper
        elevation={0}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
          ml: 2,
        }}
      >
        {/* Panel Header with Tabs */}
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Request Tabs"
          >
            <Tab key={0} label="Overview" value={0} disableRipple />
            <Tab key={1} label="Intake Form" value={1} disableRipple />
            <Tab
              key={2}
              label={`Documents (${request.files.length})`}
              value={2}
              disableRipple
            />
            <Tab key={3} label="Chat" value={3} disableRipple />
            {contract && (
              <Tab key={4} label="Contract" value={4} disableRipple />
            )}

            {purchaseOrder && (
              <Tab key={5} label="Purchase Order" value={5} disableRipple />
            )}
            <Tab key={6} label="Settings" value={6} disableRipple />
          </Tabs>
        </Box>
        {/* Panel Content */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            backgroundColor: "background.default",
            px: 4,
          }}
        >
          <Box sx={{ py: 2, mb: "0px", pb: "0px", height: "100%" }}>
            {activeTab === 0 && (
              <Overview
                user={user}
                steps={requestSteps}
                request={request}
                allUsers={allUsers}
                getRequestDetails={getRequestDetails}
                sendMessage={sendMessage}
                chatbotLoading={chatbotLoading}
                canvas={canvas}
                setCanvas={setCanvas}
                updateCanvas={updateCanvas}
                selectedNode={selectedNode}
                handleNodeClick={handleNodeClick}
              />
            )}
            {activeTab === 1 && <IntakeForm forms={requestForms} />}
            {activeTab === 2 && (
              <DocumentExplorer
                files={request.files}
                requestId={request.id}
                getRequestDetails={getRequestDetails}
                contractId={contract?.internal_id}
              />
            )}
            {activeTab === 3 && (
              <AuditLog
                requestId={request.id}
                logs={request.audit_log}
                users={allUsers}
              />
            )}
            {activeTab === 4 && (
              <Contract request={request} contract={contract} />
            )}
            {activeTab === 5 && (
              <PurchaseOrder request={request} purchaseOrder={purchaseOrder} />
            )}
            {activeTab === 6 && (
              <Settings request={request} contract={contract} />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default RequestPage;
