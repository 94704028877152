import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Box,
  Avatar,
  Divider,
  Switch,
  Button,
  Modal,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import RequestsListPage from "./Requests/RequestsListPage";
import Home from "./Home/Home";
import RequestPage from "./Requests/RequestPage";
import Settings from "./Settings/Settings"; // Import the Settings component
import { logout } from "../api/auth";
import useDashboard from "../hooks/useDashboard";
// import DrawerComponent from "./DrawerComponent";
import routeConfig from "./routeConfig"; // Import route configurations
import MiniDrawer from "./DrawerComponent";

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { user, allUsers, dummyContacts, error } = useDashboard();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      console.log("Logged out successfully");
      window.location.href = "/";
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handlePauseToggle = async (event) => {
    console.log("Account paused:", event.target.checked);
    await pauseAccount(event.target.checked);
  };

  const toggleDrawer = (newOpen) => () => {
    console.log("Toggling drawer:", newOpen);
    setDrawerOpen(newOpen);
  };

  // Determine if the current route should display the Drawer based on routeConfig
  const showHeader = Object.keys(routeConfig).some((route) =>
    location.pathname.startsWith(route)
  );

  // Get the current route's title from routeConfig

  const headerTitle =
    routeConfig[
      Object.keys(routeConfig).find((route) =>
        location.pathname.startsWith(route)
      )
    ] || null;

  if (error) {
    return (
      <Typography color="text">
        Error loading requests: {error.message}
      </Typography>
    );
  }

  return (
    <MiniDrawer
      user={user}
      handleLogout={handleLogout}
      headerTitle={headerTitle}
    >
      <Routes>
        <Route path="/" element={<Navigate to="home" />} />
        <Route
          path="home"
          element={
            <Home
              user={user}
              showContractInfo={true}
              showPurchaseOrderInfo={false}
              allUsers={allUsers}
            />
          }
        />
        <Route
          path="all_requests"
          element={
            <RequestsListPage
              user={user}
              storageKey="allRequestsListGridState"
              showContractInfo={true}
              showPurchaseOrderInfo={false}
              assignedOnly={false}
              allUsers={allUsers}
            />
          }
        />
        {/* Uncomment if you have an InboxPage */}
        {/* <Route path="inbox" element={<InboxPage />} /> */}
        <Route
          path="request/:requestId"
          element={
            <RequestPage
              user={user}
              contacts={dummyContacts}
              allUsers={allUsers}
            />
          }
        />
        <Route
          path="settings"
          element={<Settings user={user} allUsers={allUsers} />}
        />
        {/* New Settings Route */}
        {/* Fallback Route */}
        <Route path="*" element={<Typography>Page Not Found</Typography>} />
      </Routes>
    </MiniDrawer>
  );
};

export default Dashboard;
