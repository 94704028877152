import React from "react";
import { Typography, Paper, Box, Divider, Button } from "@mui/material";
import { marked } from "marked";
import { convert } from "html-to-text";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";

const EmailArtifact = ({ artifact }) => {
  const [copied, setCopied] = React.useState(false);
  const { to, cc, bcc, subject, body } = artifact.content;

  const formatEmailList = (emails) => emails.join(", ");

  const handleExportEmail = () => {
    const plainTextBody = convert(body, {
      wordwrap: 130,
    });
    const encodedBody = encodeURIComponent(plainTextBody).replace(/%20/g, "+");
    const mailtoLink = `mailto:${to}?cc=${encodeURIComponent(
      formatEmailList(cc)
    )}&bcc=${encodeURIComponent(
      formatEmailList(bcc)
    )}&subject=${encodeURIComponent(subject)}&body=${encodedBody}`;
    window.location.href = mailtoLink;
  };

  const handleCopyToClipboard = async () => {
    if (navigator.clipboard && window.ClipboardItem) {
      try {
        const blob = new Blob([body], { type: "text/html" });
        await navigator.clipboard.write([
          new ClipboardItem({ "text/html": blob }),
        ]);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } catch (err) {
        console.error("Failed to copy HTML: ", err);
      }
    } else {
      // Fallback: Use plain text if the Clipboard API with HTML isn't available
      try {
        await navigator.clipboard.writeText(body);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    }
  };

  return (
    <Box
      sx={{ display: "flex", width: "100%", flexDirection: "column", my: 2 }}
    >
      <Paper sx={{ p: 1 }} variant="outlined">
        {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="body2">
            <strong>To:</strong> {formatEmailList(to)}
          </Typography>
          <Typography variant="body2">
            <strong>Cc:</strong> {formatEmailList(cc)}
          </Typography>
          <Typography variant="body2">
            <strong>Bcc:</strong> {formatEmailList(bcc)}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mb: 1 }}>
          <strong>Subject:</strong> {subject}
        </Typography> */}
        <Box sx={{ height: "500px" }}>
          <iframe
            srcDoc={body}
            title="Email Preview"
            style={{
              width: "100%",
              height: "100%",
            }}
            sandbox=""
          />
        </Box>
      </Paper>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={handleCopyToClipboard}
          color={copied ? "success" : "info"}
          size="small"
          sx={{ mt: 1, ml: 1, minWidth: "10rem" }}
          startIcon={copied ? <CheckIcon /> : <ContentCopyIcon />}
        >
          {copied ? "Copied!" : "Copy to Clipboard"}
        </Button>
      </Box>
    </Box>
  );
};

export default EmailArtifact;
