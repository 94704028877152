import React, { useState } from "react";
import { Box, Typography, Tabs, Tab, Paper } from "@mui/material";
import Approvals from "./Approvals";
import FormBuilder from "./FormBuilder";
import AdminPanel from "./AdminPanel";
import Notifications from "./Notifications";
import FlowBuilder from "./FlowBluilder/FlowBuilder";
import useSettings from "../../hooks/useSettings";

const Settings = ({ user, allUsers }) => {
  const [activeTab, setActiveTab] = useState(0);

  const {
    formStructure,
    setFormStructure,
    orgRoles,
    approvals,
    setApprovals,
    saveApproval,
    approvers,
    setApprovers,
    handleExportApproval,
    handleImportApproval,
  } = useSettings();
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabs = [
    // { label: "Approvals", component: <Approvals /> },
    {
      label: "Admin Panel",
      component: <AdminPanel />,
      disabled: !(
        user &&
        user.roles &&
        user.roles.some((r) => r.name === "admin")
      ),
    },
    {
      label: "Form Structure",
      component: (
        <FormBuilder
          formStructure={formStructure}
          setFormStructure={setFormStructure}
        />
      ),
      disabled: !(
        user &&
        user.roles &&
        user.roles.some((r) => r.name === "admin")
      ),
    },
    {
      label: "Notifications",
      component: <Notifications />,
      disabled: !(
        user &&
        user.roles &&
        user.roles.some((r) => r.name === "admin")
      ),
    },
    // {
    //   label: "Flow Builder",
    //   component: (
    //     <FlowBuilder
    //       formStructure={formStructure}
    //       orgRoles={orgRoles}
    //       approvals={approvals}
    //       setApprovals={setApprovals}
    //       saveApproval={saveApproval}
    //       approvers={approvers}
    //       setApprovers={setApprovers}
    //       user={user}
    //       allUsers={allUsers}
    //       handleExportApproval={handleExportApproval}
    //       handleImportApproval={handleImportApproval}
    //     />
    //   ),
    //   disabled: !(
    //     user &&
    //     user.roles &&
    //     user.roles.some((r) => r.name === "admin")
    //   ),
    // },
    // Add more tabs here as needed
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        px: 4,
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Settings Tabs"
        sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
        textColor="primary"
        indicatorColor="primary"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            disabled={tab.disabled}
            disableRipple
          />
        ))}
      </Tabs>

      <Box sx={{ flex: 1, overflow: "scroll" }}>
        {tabs[activeTab]?.component || (
          <Typography>No settings available</Typography>
        )}
      </Box>
    </Paper>
  );
};

export default Settings;
