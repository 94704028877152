import React, { useState, useEffect, memo } from "react";
import {
  Handle,
  Position,
  NodeToolbar,
  // if you are using @reactflow/node-toolbar, then import from that package
} from "@xyflow/react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { translateOptions, mapColor } from "../../utils/formatting";
import { getIcon } from "./Utils";
function StepNode({ id, data }) {
  const theme = useTheme();

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [artifactContent, setArtifactContent] = useState(null);

  const [fixedOptions, setFixedOptions] = useState([]);

  // const [artifacts, setArtifacts] = useState([]);
  // const [options, setOptions] = useState([]);

  // useEffect(() => {
  //   const fetchArtifacts = async () => {
  //     if (data.active) {
  //       const { artifacts, options } = await getItemArtifacts(
  //         data.requestId,
  //         data.id
  //       );
  //       console.log("artifacts", artifacts);
  //       console.log("options", options);
  //       setArtifacts(artifacts);
  //       setOptions(translateOptions(options));
  //     }
  //   };
  //   fetchArtifacts();
  // }, [data.requestId, data.id]);

  useEffect(() => {
    setFixedOptions(
      translateOptions(
        translateOptions([
          {
            description: "Approve",
            // icon: "CheckCircleIcon",
            color: "success",
            variant: "contained",
            message: `Mark ${data.title} as completed`,
          },
          {
            description: "Reject",
            // icon: "CancelIcon",
            color: "error",
            variant: "contained",
            message: `Mark ${data.title} as rejected`,
          },
        ])
      )
    );
  }, [data.title]);
  // Introduce local state for approvers
  const [approvers, setApprovers] = useState(
    data.approvers ? data.approvers.map((a) => a.email) : []
  );

  // Update local state when data.approvers changes
  useEffect(() => {
    setApprovers(data.approvers ? data.approvers.map((a) => a.email) : []);
  }, [data.approvers]);

  // Optional: display extra content inside the node if needed
  const renderContent = () => {
    if (!data.content) return null;
    return data.content;
  };

  return (
    <Box className="nopan">
      <Box
        sx={{
          // backgroundColor: data.active
          //   ? theme.palette.background.default
          //   : theme.palette.background.dark,
          backgroundColor: theme.palette.background.default,
          // backgroundColor:,
          border: data.active
            ? `4px solid ${theme.palette.info.main}`
            : `1px solid ${theme.palette.primary.main}`,
          // border: data.active ? `3px solid` : `2px solid`,
          // borderColor: theme.palette[mapColor(data.display_color)].main,
          color: theme.palette.primary.main,
          width: data.nodeWidth,
          minHeight: data.nodeHeight,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(2),
          cursor: "pointer",
        }}
      >
        {/* Input and output handles */}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              mb: 2,
              minHeight: "2.5rem",
            }}
          >
            {getIcon(data.icon, 28)}
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                marginLeft: theme.spacing(1),
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                textOverflow: "ellipsis",
              }}
              title={data.title}
            >
              {data.title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>{renderContent()}</Box>
      </Box>
    </Box>
  );
}

export default memo(StepNode);
